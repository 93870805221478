import {useCallback, useState} from 'react';
import axios from "axios";
import {urls} from "../../../Constants/urls";
import {toast} from "react-toastify";
import {useErrorHandler} from "../../useErrorHandler";

export const useUpdateProductFile = () => {
  const errorHandler = useErrorHandler();
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const updateProductFile = useCallback((productDetail, formData) => {
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          token: JSON.parse(localStorage.getItem("token"))
        }
      };

      const url = `${urls.adminProductDetail}/${productDetail.id}/upload-to-s3`;
      const res = await axios.patch(url, formData, config);
      setResponse(prevState => ({...prevState, data: res.data.data, isLoading: false}));
      toast.success(res.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
    };

    asyncRequest().catch(error => {
      console.log(error);
      errorHandler(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, updateProductFile];
};
