import React, {useEffect} from 'react';
import Carousel from "react-elastic-carousel";
import './categorycarousel.css';
import {useFetchCategoriesCarousel} from "../../Hooks/useFetchCategoryCarousel";



const CategoryCarousel = (props) => {
   const { clickAction } = props;
  const [response, fetchCategories] = useFetchCategoriesCarousel();
  let categories = response?.data?.categories ?? [];
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 5 },
    { width: 1200, itemsToShow: 7 },
  ];
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);
  return (
    <>
      <div className="category-main-body">
        <Carousel breakPoints={breakPoints} pagination = {false} enableAutoPlay={false}  >
          <div className="category-rectangle"  onClick={() => clickAction(0)}>
            <div className="category-carousel-image" >
              <img  src={"/images/default-category-logo.png"} alt=""/>
            </div>
            <div className="category-carousel-name">সব দেখুন</div>
          </div>
          {categories?.map(category =>(
            <div className="category-rectangle"  onClick={() => clickAction(1, category.category_id)}>
            <div className="category-carousel-image">
              <img  src={category.category_image ?? "/images/default-category-logo.png"} alt=""/>
            </div>
            <div className="category-carousel-name">{category.category_name_bangla}</div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default CategoryCarousel;