import { useCallback, useState } from 'react';
import  axios  from "axios";
import {urls} from "../Constants/urls";

export const useFetchBookDetail = () => {
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const fetchBookDetail = useCallback((book_id)=>{
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = `${urls.productDetail}/${book_id}` ;
      const res = await axios.get(url);
      setResponse(prevState => ({...prevState, data: res.data, isLoading: false}));
    };

    asyncRequest().catch(error => {
      console.log(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, fetchBookDetail];
};
