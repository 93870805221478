import React, {useEffect, useState} from "react";
import "./categorybookdetails.css";
import BookPreview from "../../DataDisplay/BookPreview";
import {useParams} from "react-router-dom";
import {useFetchCategoryBookDetails} from "../../../Hooks/useFetchCategoryBookDetails";
import {IconButton, TextField} from "@material-ui/core";
import {SearchOutlined} from "@material-ui/icons";
import PageLoader from "../../Loader";


 const CategoryBookDetails = (props) => {
   let {categoryId} = useParams();

   const [response, fetchCategoryBookDetails] = useFetchCategoryBookDetails();
   let categories = response?.data ?? [];
   const isLoading = response?.isLoading;
   const [categoryBookDetailSearchText, setCategoryBookDetailSearchText] = useState('');

   useEffect(() => {
     fetchCategoryBookDetails(categoryId);
   }, [fetchCategoryBookDetails]);

   return (
    <>
      <PageLoader
        isLoading={isLoading}
        width={180}
        height={180}
        position={"fixed"}
        top={"50%"}
        left={"50%"}
        padding={"100%"}
      />
      <div className="container-fluid category-books-details-banner">
        <div className="category-banner-title ">{categories.category_name_bangla} </div>
      </div>
      {categories?.products?.length > 0 ? (<>
        <div className="container-fluid">
          <div className="row">
            <div className="category-book-detail-header-and-search-sec">
              <div className="category-book-detail-title-header">
                সকল {categories.category_name_bangla} বই
              </div>
              <div className="category-book-detail-search-input">
                <TextField
                  className="category-book-detail-search-input-field"
                  id="standard-bare"
                  variant="outlined"
                  placeholder={"খুজুন"}
                  value = {categoryBookDetailSearchText}
                  onChange={(event) => {
                    setCategoryBookDetailSearchText(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchOutlined style={{margin: 0}} />
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="all-category-books">
              {categories?.products?.map(product =>(
                <BookPreview
                  key={product.product_id}
                  id = {product.product_id}
                  name={product.product_name_bangla}
                  author={product.authors.map(author =>(author.author_name_bangla))}
                  price={product.discounted_price}
                  image_url={product.product_image}
                  margin={50}
                />
              ))}
            </div>
          </div>
        </div>
      </>
      ) : (
        <>
          <div style={{minHeight: "14vh"}}>
            <h2 className= "text-center ml-5 " >
              No books available in this category
            </h2>
          </div>
        </>
      )}
    </>
  );
};

export default CategoryBookDetails;
