import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useFetchUserDetail} from "../../../../../Hooks/Admin/Users/useFetchUserDetail";
import './userDetails.css';
import UserNewForm from "./user-new-form";

const UserNewPage = () => {

  const [user, setUser] = useState({});

  useEffect(() => {
    setUser({});
  }, []);

  return (
    <>
      <div className={'user-info-form'}>
        <Link to={"/admin/users"}>
          <i className="fas fa-arrow-left "  style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <UserNewForm user={user} setUser={setUser}/>
      </div>
    </>

  );
};

export default UserNewPage;
