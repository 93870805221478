import {useCallback, useState} from 'react';
import axios from "axios";
import {urls} from "../../../Constants/urls";
import {toast} from "react-toastify";
import {useErrorHandler} from "../../useErrorHandler";

export const useConfirmProductReview = () => {
  const errorHandler = useErrorHandler();
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const confirmProductReviewDetail = useCallback((id) => {
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = urls.acceptProductReview;
      const res = await axios.patch(url, {review_id: id, is_accepted: true}, {params: {token: JSON.parse(localStorage.getItem("token"))}});
      setResponse(prevState => ({...prevState, data: res.data.data, isLoading: false}));
      if(res.data.status === 200){
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
        window.location.reload();
      }else{
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }
    };

    asyncRequest().catch(error => {
      console.log(error);
      errorHandler(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, confirmProductReviewDetail];
};
