import React, {useEffect, useState} from 'react';
import './productDetails.css';
import ProductNewForm from "./product-new-form";
import {Link} from "react-router-dom";


const ProductNewPage = () => {
  const [product, setProduct] = useState({});
  useEffect(() => {
    setProduct({});
  }, []);

  return (
    <>
      <div className={'product-info-form'}>
        <Link to={"/admin/products"}>
          <i className="fas fa-arrow-left "  style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <ProductNewForm product={product} setProduct={setProduct}/>
      </div>
    </>

  );
};

export default ProductNewPage;
