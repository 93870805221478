import React, { useState} from 'react';
import {Container, Grid, TextField, Button} from "@material-ui/core";

import {useGetOTP} from "../../../Hooks/useGetOTP";
import {usePostOTP} from "../../../Hooks/usePostOTP";

const OTPForm = (props) => {
  const {
    phoneNo,
    onOTPVerificationSuccess,
    onOTPVerificationFailure
  } = props;
  const mainBody = {height: "auto", width: "auto", margin: "70px auto", padding: "20px"};
  const textField = {height: "1em", marginBottom: "40px",};
  const sendOTP = {
    margin: '0px',
    width: "auto",
    height: "50px",
    borderRadius: "30px",
    background: "linear-gradient(to right, #0067b1, #1c85df 96%)"
  };
  const button = {
    marginTop: '25px',
    height: "50px",
    borderRadius: "30px",
    background: "linear-gradient(to right, #0067b1, #1c85df 96%)"
  };

  const [otpNo, setOtpNumber] = useState('');

  const [, fetchGetOTP] = useGetOTP();
  const [, fetchPostOTP] = usePostOTP();

  const handleGetOTP = () => {
    fetchGetOTP(phoneNo);
  };
  const handleSubmitOTP = () => {
    fetchPostOTP(phoneNo, otpNo, onOTPVerificationSuccess, onOTPVerificationFailure);
  };
  return (
    <>
      <Container style={mainBody} component="main" maxWidth="sm">
        <Grid align="center" style={{paddingBottom: 20}}>
          <h5>ওটিপি প্রদান করুণ</h5>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <TextField
              style={textField}
              defaultValue={phoneNo}
              inputProps={
                {readOnly: true}
              }
              autoComplete="phoneNo"
              name="phoneNo"
              variant="outlined"
              required
              fullWidth
              id="phoneNo"
              label="Phone No"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button onClick={handleGetOTP} style={sendOTP} variant="contained" color="secondary" fullWidth>
              <span style={{color: "#ffffff", fontWeight: "bold", fontSize: "12px"}}>ওটিপি পাঠান</span>
            </Button>
          </Grid>
          < Grid item xs={12}>
            <TextField
              style={textField}
              onChange={e => setOtpNumber(e.target.value)}
              autoComplete="OTP"
              name="OTP"
              variant="outlined"
              required
              fullWidth
              id="OTP"
              label="OTP"
              placeholder="ওটিপি দিন"
            />
          </Grid>
        </Grid>
        <Button onClick={handleSubmitOTP} style={button} variant="contained" color="secondary" fullWidth>
          <span style={{color: "#ffffff", fontWeight: "bold"}}>প্রফাইল নিশ্চত করুন</span>
        </Button>
      </Container>
    </>
  );
};

export default OTPForm;
