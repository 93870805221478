import React, {useEffect, useState} from 'react';
import './orderdetails.css';
import {useFetchOrderDetail} from "../../../../../Hooks/Admin/Orders/useFetchOrderDetail";
import {Button, TextField} from "@material-ui/core";


const OrderDetail = (props) => {
  const {orderId} = props;
  const [response, fetchCategoryDetail] = useFetchOrderDetail();
  const orderInfo = response?.data?.order;
  useEffect(() => {
    fetchCategoryDetail(orderId);
  }, []);
  return (
    <>
      <div className="order-details">

        <div className="order-title mt-3 mb-3">
          <h3 className="text-center">Order Processing #{orderInfo?.id}</h3>
          <h5>Order Details:</h5>
        </div>
        <div className="row order-details-body ">
          <div className="col-6">
            <div className="h6">Buyer's Information</div>
            <div className="m-1 d-inline text-secondary">Name: {orderInfo?.user_name} </div>
            <br/>
            <div className="m-1 d-inline text-secondary">Phone No: {orderInfo?.phone_no} </div>
            <br/>
          </div>
          <div className="col-6">
            <div className="h6">Charges</div>
            <div className="row">
              <div className="col-4 charges-text-label">
                <div className="form-label mt-1">Net Charge</div>
                <div className="form-label mt-1">Discount</div>
                <div className="form-label mt-1">Payable Amount</div>
                <div className="form-label mt-1">Paid Amount</div>
              </div>
              <div className="col-6">
                <TextField
                  name={'total_amount'}
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  type={"number"}
                  value={orderInfo?.total_amount}
                  readOnly
                />
                <TextField
                  className={"mt-2"}
                  name={'discount_amount'}
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  type={"number"}
                  defaultValue={0}
                  value={orderInfo?.discounted_price}
                />
                <TextField
                  className={"mt-2"}
                  name={'payable_amount'}
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  type={"number"}
                  value={orderInfo?.payable_amount}
                />
                <TextField
                  className={"mt-2"}
                  name={'paid_amount'}
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  type={"number"}
                  value={orderInfo?.paid_amount}
                />
                <Button className={'mt-3 d-block'} fullWidth variant="contained" color="primary">Update Charges</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 mb-3">
          <h5>Ordered Items:</h5>
          <div className="row mt-4">
            <div className="col-2 h6">
              <label>Book Name: </label>
            </div>
            <div className="col-2 h6">
              <label>Book Name Bangla: </label>
            </div>
            <div className="col-2 h6">
              <label>Regular Price: </label>
            </div>
            <div className="col-2 h6">
              <label>Discount Price: </label>
            </div>
            <div className="col-2 h6">
              <label>Purchased Price: </label>
            </div>
          </div>
          <div className="order-products-list">
            {orderInfo?.ordered_products.map(ordered_product => (
              <div className="row">
                <div className="col-2 h6">
                  <TextField
                    name={'name'}
                    variant="outlined"
                    size={'small'}
                    type={"text"}
                    value={ordered_product?.product_name}
                  />
                </div>
                <div className="col-2 h6">
                  <TextField
                    name={'name_bangla'}
                    variant="outlined"
                    size={'small'}
                    type={"text"}
                    value={ordered_product?.product_name_bangla}
                  />
                </div>
                <div className="col-2 h6">
                  <TextField
                    name={'regular_price'}
                    variant="outlined"
                    size={'small'}
                    type={"number"}
                    value={ordered_product?.regular_price ?? 0}
                  />
                </div>
                <div className="col-2 h6">
                  <TextField
                    name={'discounted_price'}
                    variant="outlined"
                    size={'small'}
                    type={"number"}
                    value={ordered_product?.discounted_price ?? 0}
                  />
                </div>
                <div className="col-2 h6">
                  <TextField
                    name={'purchased_price'}
                    variant="outlined"
                    size={'small'}
                    type={"number"}
                    value={ordered_product?.purchased_price ?? 0}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>

  );
};

export default OrderDetail;
