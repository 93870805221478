import React from "react";
import "./terms-and-conditions.css";


const TermsAndConditionsPage = () => {

  return (
    <>
      <div className="container-fluid terms-and-conditions-banner">
        <div className="terms-and-conditions-banner-title ">Terms And Conditions</div>
      </div>
      <div className="container-fluid ml-5 mr-5">
        <div className="row ml-5 mr-5">
          <div className="col-12">
            <div className="terms-and-conditions-details">
              <p>
                We welcome you to <b>BOOKFLIX</b> and thank you for your support in making <b>BOOKFLIX</b> a leading story-telling platform.
                While it is our endeavor to make the usage of <b>BOOKFLIX</b> as user-friendly as possible, we depend on each user to
                help us keep the platform free from content that is infringing of otherss' rights, objectionable or unlawful in
                any manner and prevent any unauthorized use of the content legally owned by our users. We sincerely encourage
                you to go through the below Terms of Use to understand the do's and don'ts while using the platform. Do not hesitate
                to contact us for any queries.
              </p>
              <br />
              {/**************Terms of use***********************/}
              <h4>Terms Of Use</h4>
              <br />
              <p>
                These Terms of Use along with the Privacy Policy governs your access, use, registration, etc. of <b>BOOKFLIX</b> Ltd.'s.
                (“Company”), '<b>BOOKFLIX</b>' website (https://bookflix.com.bd/) (“Website”), and the <b>BOOKFLIX</b> application available on
                Android and iOS by any person (“Application”).
                Company facilitates a User to read and/or upload literary/audio works (such as books, poems, articles, comics etc.
                including cover images) and audio (“Published Work”), read/listen to Published Work and literary/audio works published
                by Company (“Company Content”), in various languages and upload comments, reviews on such literary works of others the
                same or and communicate with the Company and/or other Users through chats (“Inputs”), on the Website/Application
                (“Services”). Published Work and Company Content shall together be referred to as “Content”.
              </p>
              <p>
                BOOKFLIX is available on our website or hosted on the Google Play Store as Application. By browsing through the
                Website/Application and availing the Services, you agree to be bound by these Terms of Use read along with the Privacy
                Policy and represent that You are above 18 years of age and/or have the authority to enter into a binding contract
                with Company. If you are under 18 years of age, you must obtain consent from your parent(s) or guardian(s) or any
                relevant person having authority to make decisions on your behalf who will be responsible for your acceptance and
                compliance of these Terms of Use.  If You do not have consent from Your parent(s) or legal guardian(s), or any relevant
                person having authority to make decisions on your behalf, you must stop using/accessing the Website/Application.
              </p>
              <p>
                These Terms of Use along with the Privacy Policy shall be governed by and construed in accordance with the applicable
                laws of Bangladesh.
              </p>
              <br />
              {/***********************user registration***********************************/}
              <h4>User Registration</h4>
              <p>
                You may be required to register with the Site in order to access the Marketplace Offerings.
                You agree to keep your password confidential and will be responsible for all use of your account
                and password. We reserve the right to remove, reclaim, or change a user name you select if we determine,
                in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
              </p>
              <br />
              <h4>User Obligations</h4>

              <p>
                By using the Services, User agrees to abide by the below obligations:
              </p>
              <p>
                <ol type="1">
                  <li><b>Accuracy</b>: To provide complete and accurate information while registering on the Website/Application and to contact
                    Company if there is any change in such information. Further, User must not impersonate any other person.</li>
                  <li><b>Confidentiality</b>: To maintain confidentiality of the User's account details and be responsible for any use of the
                    Services through the User's account.</li>
                  <li><b>Ownership</b>: To ensure that the copyrights in the Published Works uploaded are fully owned by the User and the same
                  does not violate the patent, trademark, copyright or other proprietary rights of any third party.</li>
                  <li><b>Content Guidelines</b>: To ensure the Published Works/Inputs do not violate the conditions mentioned in 'Content
                  Guidelines' below.</li>
                  <li><b>Reproduction</b>: To not reproduce any Published Works of another User Content from the Website/Application and publish
                  it in any other platform without authority, whether for commercial gain or otherwise.</li>
                  <li><b>License</b>: To grant Company
                  <ol type="i">
                    <li>a license to publicly display their name/username to attribute the Published Works uploaded on the Website/Application;</li>
                    <li>a worldwide, royalty-free, non-exclusive right and license for the Company to adapt, publish, reproduce, process,
                    modify the Published Works to distribute, disseminate, transmit the Published Works and its derivatives on any medium
                    or through any distribution method; and</li>
                    <li>right to showcase any Published Works to a third party for the purposes of any potential collaboration without
                    prior intimation to User.</li>
                  </ol>
                  </li>
                  <li><b>Illegal Activities</b>: Not use the Services to perform any illegal activities nor solicit the performance of any
                  illegal or any activity which leads to violation of third party's rights.</li>
                  <li><b>Virus</b>: To not upload any material which contains software viruses, or any other computer code, files or programs
                  designed to interrupt, destroy or limit the functionality of any computer resource used to provide the Services.</li>
                  <li><b>Advertise</b>: To not advertise or solicit any products or services on the Website/Application.</li>
                  <li><b>Security</b>:
                    <ol type="a">
                    <li>Not probe, scan or test the vulnerability of the Website/Application</li>
                    <li>Not disrupt or breach the
                    security or authentication measures or circumvent the navigational structure, with respect to the Website/Application
                    or the network</li>
                    <li>Not use any manual or automated software, devices or other processes to "crawl" or "spider" any part
                    of the Website/Application</li>
                    <li>Not to use cheats, exploits, automation, software, bots, hacks or any unauthorized third
                    party software to modify or interfere with the Services or in any manner to gain undue advantage from the Services or
                    features</li>
                    <li>Not place an unreasonable burden on the Company's infrastructure.</li>
                    </ol>
                  </li>
                  <li><b>Unfair Conduct</b>: Do not engage in any unfair conduct such as using or misuse any of the programs offered on the
                  Website/Application or engage in any other fraudulent/wrongful activity on the Website/Application.</li>
                  <li><b>Access</b>: To access and use the Services solely for the User's personal and non-commercial purposes and to not
                  access the Website/Application or obtain the Published Works Content through any other means other than as permitted.</li>
                  <li><b>User Data</b>: Not trace any information pertaining to another User or exploit any such information, including storing
                  and collecting the same.</li>
                  <li><b>Trademark and Design</b>: To not use, misuse or misappropriate the trademark 'BOOKFLIX' and/or any logo 'BOOKFLIX'
                  or any design of the Website/Application, which are owned/used by the Company for any unauthorized purpose.</li>
                </ol>
              </p>
              <br />
              {/***********************Content Guidelines***********************/}
              <h4>Content Guidelines</h4>

              <p>
                Published Works/Inputs uploaded on the Website/Application must:
              </p>

              <p>
                <ol type="1">
                <li><b>Not be objectionable or unlawful</b>: Do not put-up Published Works/Inputs which are grossly harmful, infringing,
                harassing, blasphemous defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful,
                or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise
                unlawful in any manner whatsoever.</li>
                <li>
                <b>Not be against national interest</b>: Do not put-up Published Works/Inputs that can threaten the unity, integrity,
                defense, security or sovereignty of Bangladesh, friendly relations with foreign states, or public order or causes
                incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any
                other nation.
                </li>
                <li><b>Protect minors</b>: Do not put-up Published Works/Inputs which can harm minors in any way.</li>
                <li><b>Not be misleading/ offensive</b>: Do not put-up Published Works/Inputs which deceives or misleads a reader about
                the origin of the content or communicates any information which is grossly offensive or menacing in nature.</li>
                </ol>

              </p>
              <h4>Suspension/Termination</h4>
              <p>
                Subject to this Section, these Terms will remain in full force and effect while you use the Site. We may suspend
                or terminate your rights to use the Site at any time for any reason at our sole discretion, including for any use
                of the Site in violation of these Terms. Upon termination of your rights under these Terms, your Account and right
                to access and use the Site will terminate immediately. You understand that any termination of your Account may involve
                deletion of your User Content associated with your Account from our live databases. Company shall have the right to
                suspend or terminate partially or fully the usage and access rights of the User to the Website/Application if there
                is any non-compliance with these Terms of Use and/or the Privacy Policy. The company will not have any liability
                whatsoever to you for any termination of your rights under these Terms. Even after your rights under these Terms
                are terminated, the following provisions of these Terms will remain in effect.
              </p>
              <br />

              <h4>Users Are in Control Of Content</h4>
              <p>
                Company through its Website/Application receives, stores and transmits Published Works/Inputs solely on behalf of the Users.
                Users remain the sole authors and owners of their Published Works/Inputs. Further, Company does not control or
                restrict the publishing or reading of the Published Works/Inputs nor modifies the same prior to upload of the same on the
                Website/Application.
              </p>
              <br />

              <h4>Liability</h4>
              <p>
                <ol type="1">
                <li><b>No warranty of any kind</b>: All Services and Published Works Content offered on the Website/Application are offered
                on an "as is" basis without any warranty whatsoever, either express or implied. The Company/Website/Application does
                not implicitly or explicitly support or endorse any of Published Works, content or service Content on the
                Website/Application. The Company/Website/Application does not warrant that the functions and Services contained in
                the Website/Application will be uninterrupted or error-free, or that the Website/Application or its server will be
                free of viruses or other harmful components, and the User hereby expressly accepts any and all associated risks
                involved with the User's use of the Website/Application.</li>
                <li><b>User liable for breach</b>: You are solely responsible to the Company and to any third party for any breach of
                Your obligations under the Terms of Use and for the consequences (including any loss or damage which the Company
                or its affiliates or its Users may suffer for any such breach).</li>
                <li><b>Indemnity</b>: User expressly agrees to indemnify and hold harmless the Company/Website/Application, for its use
                of any Published Works/Inputs provided to it or published on the Website/Application by the User. The Company
                reserves its right to defend itself in any such legal disputes that may arise, and recover the costs incurred in
                such proceedings from the User.</li>
                <li><b>No Indirect Liability</b>: Company disclaims any and all special, incidental, indirect, consequential or punitive
                damages, losses costs towards any User or third party arising from the provision of Services or use of the
                Website/Application by others.</li>
                </ol>
              </p>
              <br />

              <h4>Grievance Officer</h4>
              <p>
                If any User is affected by any Published Works Content which violates these Term of Use, including the Content
                Guidelines, User can write their concerns to <b>info@bookflix.com.bd</b>. We Company will endeavor to resolve the concerns
                within thirty (30) days.
                If any person becomes aware of any Published Works Content which violate these Terms of Use, such person can
                contact the Grievance Officer at <b>info@bookflix.com.bd</b> with the following details:
                <ul>
                <li>The complainant’s name and contact details such as address, telephone number and valid email address</li>
                <li>Description of the Published Works Content which are violating the Terms of Use</li>
                <li>The nature of complaint against the Content Published Works</li>
                <li>The details of the URL where such Content Published Works are hosted</li>
                <li>Supporting documents/sources, if applicable, to substantiate the complaint</li>
                <li>Complaint document to be duly signed physically or by electronic signature</li>
                </ul>
              </p>
              <br />
              <h4>Miscellaneous</h4>
              <p>
                <ol type="1">
                <li><b>Modification</b>: The Company reserves the sole and exclusive right to unilaterally amend or modify these Terms
                of Use and such amendments or modifications shall come into effect immediately. The User has a duty to periodically
                check the terms and stay updated on its requirements. If the User continues to use the Website/Application following
                such a change, the User will be deemed to have consented to any and all amendments/modifications made to the Terms
                of Use.</li>
                <li><b>Disputes</b>: The Users expressly agree that the Terms of Use, Privacy Policy and any other agreements entered
                between the Company and User(s) are governed by the laws, rules and regulations of Bangladesh, and that the Courts
                at Bangladesh shall have exclusive jurisdiction over any disputes arising between the Parties.</li>
                <li><b>Conflict</b>: In case of any conflict arising in the interpretation of the Terms of Use in English and any other
                language as it may be made available in on the Website/Application, the terms of the English version shall prevail.</li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditionsPage;
