import React from "react";
import "./bookpreview.css";
import {Link} from 'react-router-dom';
import {useAddToCart} from "../../../Hooks/useAddToCart";
import {useAddToFav} from "../../../Hooks/useAddToFav";

const BookPreview = (props) => {
  const [AddToCart] = useAddToCart();
  const [favProducts,AddToFav, RemoveFromFav] = useAddToFav();
  let addRemove;
  let favIcon;
  if (favProducts.includes(props.id)) {
    addRemove = RemoveFromFav;
    favIcon = "/icons/like-red.svg";
  } else {
    addRemove = AddToFav;
    favIcon = "/icons/like-icon.svg";
  }

  let productPrice;
  if (props.price === 0 || props.price === null) {
    productPrice = "ফ্রি বই";
  } else {
    productPrice = props.price + "TK";
  }
  const margin = {
    marginRight: props.margin,
    marginBottom: props.margin
  };
  return (
    <>
      <div className="r-book-image" style={margin}>
        <div>
          <Link to={`/book/${props.id}`} target={props.target ?? ""}>
            <img className="book-image" src={props?.image_url ?? "/images/default-book-icon.png" } onError={(e)=>{e.target.onerror = null; e.target.src="/images/default-book-icon.png";}} width="200px" height="300px" alt=""/>
          </Link>

          <div className="icon-cart">
            <img onClick={() => {AddToCart(props.id);}} src="/icons/cart-icon-white.svg" alt=""/>
          </div>
          <div className="icon-like-book">
            <img  onClick={() => {addRemove(props.id);}} src={favIcon} alt=""/>
          </div>
          <div className="title">{props.name}</div>
          <Link to="">
            <div className="author-name">{props.author[0]}</div>
          </Link>
          <div className="price"> {productPrice} </div>
        </div>
      </div>
    </>
  );
};

export default BookPreview;
