import React from 'react';
import Header from "../Header";
import Home from '../Pages/Home';
import {BookDetailPage} from '../Pages/BookDetail';
import {CartPage} from '../Pages/Cart';
import {SearchPage} from '../Pages/Search';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import LoginPage from "../Pages/Login";
import Footer from "../Footer";
import CategoryBookDetails from "../Pages/CategoryBookDetails";
import {isMobile} from "react-device-detect";
import MobileHomePage from "../Pages/Mobile/Home";
import RegistrationPage from "../Pages/Registration";
import AuthorsPage from "../Pages/Authors";
import PublishersPage from "../Pages/Publishers";
import AuthorDetailPage from "../Pages/Authors/AuthorDetail";
import PublisherDetailPage from "../Pages/Publishers/PublisherDetails";
import FavouriteBooksPage from "../Pages/FavouriteBooks";
import CategoriesPage from "../Pages/Categories";
import ForgetPasswordPage from "../LoginRegistration/ForgetPassword/ForgetPasswordPage";
import ChangePasswordPage from "../LoginRegistration/ForgetPassword/ChangePassword";
import AboutUsPage from "../Pages/AboutUs";
import AppIconContainer from "../DataDisplay/AppIconContainer";
import TermsAndConditionsPage from "../Pages/TermsAndConditions";
import PrivacyPolicyPage from "../Pages/PrivacyPolicy";
import UserProfile from "../Pages/UserProfile";
import UserProfileEdit from "../Pages/UserProfile/UserProfileEdit";

import ProtectedRoute from "./protected_route";
import {useGetUserInformation} from "../../Hooks/useGetUserInformation";
import {default as AdminDashboard} from "../Pages/Admin/Dashboard";
import {default as AdminAuthorsPage} from "../Pages/Admin/Authors";
import {default as AdminAuthorDetailPage} from "../Pages/Admin/Authors/AuthorDetail/authorEditPage";
import {default as AdminAuthorNewPage} from "../Pages/Admin/Authors/AuthorDetail/authorNewPage";
import {default as AdminProductsPage} from "../Pages/Admin/Products";
import {default as AdminProductDetailPage} from "../Pages/Admin/Products/ProductDetail/productEditPage";
import {default as AdminProductNewPage} from "../Pages/Admin/Products/ProductDetail/productNewPage";
import {default as AdminPublishersPage} from "../Pages/Admin/Publishers";
import {default as AdminPublisherDetailPage} from "../Pages/Admin/Publishers/PublisherDetail/publisherEditPage";
import {default as AdminPublisherNewPage} from "../Pages/Admin/Publishers/PublisherDetail/publisherNewPage";
import {default as AdminCategoriesPage} from "../Pages/Admin/Categories";
import {default as AdminCategoryDetailPage} from "../Pages/Admin/Categories/CategoryDetail/categoryEditPage";
import {default as AdminCategoryNewPage} from "../Pages/Admin/Categories/CategoryDetail/categoryNewPage";
import {default as AdminUsersPage} from "../Pages/Admin/Users";
import {default as AdminUsersDetailPage} from "../Pages/Admin/Users/UserDetail/userEditPage";
import {default as AdminUsersNewPage} from "../Pages/Admin/Users/UserDetail/userNewPage";
import {default as AdminOrdersPage} from "../Pages/Admin/Orders";
import {default as AdminProductVerificationPage} from "../Pages/Admin/ProductVerification";
import {default as AdminProductVerificationDetail} from "../Pages/Admin/ProductVerification/ProductVerificationDetail";
import {default as AdminReviewProductsPendingListPage} from "../Pages/Admin/ProductReviewVerification";
import {default as AdminProductReviewsListPage} from "../Pages/Admin/ProductReview";
import {default as AdminProductReviewsPendingListPage} from "../Pages/Admin/ProductReviewVerification/ProductReviewsPendingList";




import PermanentDrawerLeft from "../AdminSideDrawer";
import PageNotFound from "../Pages/PageNotFound";

const BootLoader = () => {
    const user = useGetUserInformation();
    const pending = () => <div>Complete Me</div>;
    if (isMobile) {
      return (
        <>
          <MobileHomePage/>
        </>
      );
    } else {
      return (
        <>
          <BrowserRouter>
            <Switch>
              {/*Admin Routes*/}
              <Route path={'/admin'}>
                {user.user_type === "admin" || user.user_type === "moderator" ? (
                  <>
                    return (
                    <PermanentDrawerLeft user={user}>
                      <Switch>
                        <ProtectedRoute exact path={"/admin/dashboard"} user={user} component={AdminDashboard}/>
                        <ProtectedRoute exact path={"/admin/authors"} user={user} component={AdminAuthorsPage}/>
                        <ProtectedRoute exact path={"/admin/authors/:authorId"} user={user} component={AdminAuthorDetailPage}/>
                        <ProtectedRoute exact path={"/admin/author/new"} user={user} component={AdminAuthorNewPage}/>
                        <ProtectedRoute exact path={"/admin/categories"} user={user} component={AdminCategoriesPage}/>
                        <ProtectedRoute exact path={"/admin/categories/:categoryId"} user={user} component={AdminCategoryDetailPage}/>
                        <ProtectedRoute exact path={"/admin/category/new"} user={user} component={AdminCategoryNewPage}/>
                        <ProtectedRoute exact path={"/admin/orders"} user={user} component={AdminOrdersPage}/>
                        <ProtectedRoute exact path={"/admin/products"} user={user} component={AdminProductsPage}/>
                        <ProtectedRoute exact path={"/admin/products/:productId"} user={user} component={AdminProductDetailPage}/>
                        <ProtectedRoute exact path={"/admin/product/new"} user={user} component={AdminProductNewPage}/>
                        <ProtectedRoute exact path={"/admin/publishers"} user={user} component={AdminPublishersPage}/>
                        <ProtectedRoute exact path={"/admin/publishers/:publisherId"} user={user} component={AdminPublisherDetailPage}/>
                        <ProtectedRoute exact path={"/admin/publisher/new"} user={user} component={AdminPublisherNewPage}/>
                        <ProtectedRoute exact path={"/admin/users"} user={user} component={AdminUsersPage}/>
                        <ProtectedRoute exact path={"/admin/users/:userId"} user={user} component={AdminUsersDetailPage}/>
                        <ProtectedRoute exact path={"/admin/user/new"} user={user} component={AdminUsersNewPage}/>
                        <ProtectedRoute exact path={"/admin/products-to-confirm"} user={user} component={AdminProductVerificationPage}/>
                        <ProtectedRoute exact path={"/admin/products-to-confirm/:productId"} user={user} component={AdminProductVerificationDetail}/>
                        <ProtectedRoute exact path={"/admin/review-products-pending"} user={user} component={AdminReviewProductsPendingListPage}/>
                        <ProtectedRoute exact path={"/admin/product-reviews-list/:productId"} user={user} component={AdminProductReviewsListPage}/>
                        <ProtectedRoute exact path={"/admin/product-reviews-pending-list/:productId"} user={user} component={AdminProductReviewsPendingListPage}/>
                        <Route>
                          You are not authorized to access this content
                        </Route>
                      </Switch>
                    </PermanentDrawerLeft>
                    );
                  </>
                ) : (<>
                  <div className={'text-center mt-5 h1'}>You are not authorized for admin panel</div>
                </>)}
              </Route>

              {/*User Routes*/}
              <Route path={'/'}>
                <Header/>
                <Switch>
                  <Route exact path={'/'} component={Home}/>
                  <Route exact path={"/cart"} component={CartPage}/>
                  <Route exact path={"/search"} component={SearchPage}/>
                  <Route exact path={"/book/:bookId"} component={BookDetailPage}/>
                  <Route exact path={"/login"} component={LoginPage}/>
                  <Route exact path={"/registration"} component={RegistrationPage}/>
                  <Route exact path={"/categories"} component={CategoriesPage}/>
                  <Route exact path={"/category/:categoryId"} component={CategoryBookDetails}/>
                  <Route exact path={"/authors"} component={AuthorsPage}/>
                  <Route exact path={"/author/:authorId"} component={AuthorDetailPage}/>
                  <Route exact path={"/publishers"} component={PublishersPage}/>
                  <Route exact path={"/publisher/:publisherId"} component={PublisherDetailPage}/>
                  <Route exact path={"/favourite-books"} component={FavouriteBooksPage}/>
                  <Route exact path={"/forget-password"} component={ForgetPasswordPage}/>
                  <Route exact path={"/change-password"} component={ChangePasswordPage}/>
                  <Route exact path={"/about-us"} component={AboutUsPage}/>
                  <Route exact path={"/terms-and-conditions"} component={TermsAndConditionsPage}/>
                  <Route exact path={"/privacy-policy"} component={PrivacyPolicyPage}/>
                  <Route exact path={"/user-profile"} component={UserProfile}/>
                  <Route exact path={"/user-profile-edit"} component={UserProfileEdit}/>
                  <Route component={PageNotFound} />
                </Switch>
                <AppIconContainer position={"fixed"}
                                  top={160}
                                  right={-40}
                                  imageURL={"/icons/android-white.svg"}
                                  storeName={"Google Play"}
                                  appStoreUrl={"https://play.google.com/store/apps/details?id=com.belivit.bookflix&hl=en&gl=US"}
                />
                <Footer/>
              </Route>
            </Switch>
          </BrowserRouter>
        </>
      );
    }

  }
;
export default BootLoader;
