import React from "react";
import "./bookcategorypreview.css";
import {Link} from 'react-router-dom';

const BookCategoryPreview = (props) => {
  return (
    <>
    <Link to={`/category/${props.id}`} style={{textDecoration: "none"}}>
      <div className="r-category-image">
        <div className="category-name-logo">
            <img className="category-image" src={props.image_url ?? "/images/default-category-logo.png"} alt=""/>
            <div>{props.name_bangla}</div>
        </div>
      </div>
    </Link>
    </>
  );
};

export default BookCategoryPreview;
