import React, {useEffect, useState} from 'react';
import {Button, TextareaAutosize, TextField} from "@material-ui/core";
import {useUpdatePublisherDetail} from "../../../../../Hooks/Admin/Publishers/useUpdatePublisherDetail";
import {
  validateEmail,
  validateMaxLength, validateMinLength,
  validatePhoneNo,
  validateRequired,
  validates
} from "../../../../FormValidator/Validator";
import {
  EMAIL_MAX_LEN,
  PUBLISHER_NAME_BANGLA_MAX_LEN, PUBLISHER_NAME_BANGLA_MIN_LEN,
  PUBLISHER_NAME_MAX_LEN,
  PUBLISHER_NAME_MIN_LEN
} from "../../../../../Constants/general";

const PublisherEditForm = (props) => {
  const {publisher, setPublisher} = props;
  const [, updatePublisher] = useUpdatePublisherDetail();
  const [errors, setErrors] = useState();
  const handleChange = (event) => {
    setPublisher((prevValue) => ({...prevValue, [event.target.name]: event.target.value}));
  };

  const inputFieldLabel = {
    margin: "16px 0",
  };

  const savePublisherDetail = () => {
    updatePublisher(publisher);
  };

  //////// enable/disable submit button ////////
  let buttonClickable = false;
  if (!publisher.name?.length > 0 || !publisher.name_bangla?.length > 0 ||  errors.name?.length > 0 || errors.name_bangla?.length > 0 || errors.phone_no?.length > 0 || errors.email?.length > 0) {
    buttonClickable = true;
  }

  useEffect(() => {
    setErrors(
      validates(
        validateRequired('name'),
        validateRequired('name_bangla'),
        validateMaxLength('name', PUBLISHER_NAME_MAX_LEN),
        validateMinLength('name', PUBLISHER_NAME_MIN_LEN),
        validateMaxLength('name_bangla', PUBLISHER_NAME_BANGLA_MAX_LEN),
        validateMinLength('name_bangla', PUBLISHER_NAME_BANGLA_MIN_LEN),
        validatePhoneNo('phone_no'),
        validateEmail('email'),
        validateMaxLength('email', EMAIL_MAX_LEN),
      )(publisher, {}),
    );
  }, [publisher]);

  return (
    <>
      <h3>Update Publisher Information</h3>
      <div className={'publisher-form-main-div'}>
        <label style={inputFieldLabel}>Publisher Name: <span style={{color: "red"}}>*</span></label>
        <TextField
          name={'name'}
          id={'publisher-name'}
          value={publisher?.name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error = {Boolean(errors?.name)}
          helperText={(errors?.name)}
        />
        <label style={inputFieldLabel}>Publisher Name Bangla: <span style={{color: "red"}}>*</span></label>
        <TextField
          name={'name_bangla'}
          id={'publisher-name-bangla'}
          value={publisher?.name_bangla}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error = {Boolean(errors?.name_bangla)}
          helperText={(errors?.name_bangla)}
        />
        <label style={inputFieldLabel}>Publisher Description: </label>
        <TextareaAutosize
          style={{ height: 100 }}
          name={'description'}
          id={'publisher-description'}
          value={publisher?.description}
          className={'form-control'}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        <label style={inputFieldLabel}>Publisher Phone No: </label>
        <TextField
          name={'phone_no'}
          id={'publisher-phone-no'}
          value={publisher?.phone_no}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error = {Boolean(errors?.phone_no)}
          helperText={(errors?.phone_no)}
        />
        <label style={inputFieldLabel}>Publisher Email: </label>
        <TextField
          name={'email'}
          id={'publisher-email'}
          value={publisher?.email}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error = {Boolean(errors?.email)}
          helperText={(errors?.email)}
        />
        <label style={inputFieldLabel}>Publisher address: </label>
        <TextField
          name={'address'}
          id={'publisher-address'}
          value={publisher?.address}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        <label style={inputFieldLabel}>Publisher Image Url: </label>
        <TextField
          name={'image'}
          id={'publisher-image-url'}
          value={publisher?.image}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        {JSON.parse(localStorage.getItem("user_type")) === 100 ? (
          <>
            <label style={inputFieldLabel}>Is Disabled?: </label>
            <input
              type={'checkbox'}
              checked={publisher?.is_disabled}
              onChange={(event) => {
                setPublisher((prevValue) => ({...prevValue, is_disabled: event.target.checked}));
              }}/>
            <br/>
          </>
        ) : (
          <></>
        )}
        <Button className={'mt-3 d-block'} onClick={savePublisherDetail} variant="contained"
                disabled={buttonClickable}
                color="primary">Save</Button>
      </div>
    </>
  );
};

export default PublisherEditForm;
