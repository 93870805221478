import React from 'react';
import './multiselectsearchresults.css';
import {Link} from 'react-router-dom';

export const MultiSelectSearchResults = (props) => {
  const {multiSelectSearchData, isAlreadySelected, onClick} = props;
  const resultTexts = {
    display: "flex",
    justifyContent: "left",
    fontSize: "18px",
    color: "#666666",
    marginRight: "10px",
    borderBottom: "1px solid #dbdbdb",
  };

  return (
    <>
      <div className="multiselect-search-results">
        {isAlreadySelected ?
          (<div className='multiselect-search-results-disabled'>
            <Link style={{textDecoration: "none"}} to="#">
              <div onClick={onClick}>
                <p style={resultTexts}>{(multiSelectSearchData['name'])}</p>
              </div>
            </Link>
          </div>) :
          (<div className='multiselect-search-results-enabled'>
            <Link style={{textDecoration: "none"}} to="#">
              <div onClick={onClick}>
                <p style={resultTexts}>{(multiSelectSearchData['name'])}</p>
              </div>
            </Link>
          </div>)}

      </div>
    </>
  );
};
export default MultiSelectSearchResults;