import React from 'react';

const Dashboard = () => {
  return (
    <div className={"text-center h1"}>
      Welcome to Admin Panel
    </div>
  );
};

export default Dashboard;