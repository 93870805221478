let HOST = 'http://127.0.0.1:3001/api/v1';

if(process.env.NODE_ENV === 'development') {
  HOST = 'http://127.0.0.1:3001/api/v1';
}

if(process.env.NODE_ENV === 'production') {
  HOST = 'http://bookflix.com.bd/api/v1' && 'https://bookflix.com.bd/api/v1';
  // HOST = 'https://bookflix.com.bd/api/v1';
}

export const urls = {
  homePageUrl: `${HOST}`,
  cartProducts: `${HOST}/cart-products-details`,
  productDetail: `${HOST}/products/`,
  loginUrl: `${HOST}/sign-in`,
  registrationUrl: `${HOST}/users`,
  orderCartProduct: `${HOST}/create-order`,
  searchUrl: `${HOST}/search`,
  getOTP: `${HOST}/get-user-verification-otp`,
  postOTP: `${HOST}/verify-user-with-otp`,
  postForgetPassPhoneNo: `${HOST}`,
  PostChangePassword: `${HOST}`,
  categoriesList: (page = 1, per_page = 10, search = '') => `${HOST}/categories?page=${page}&per_page=${per_page}&search=${search}`,
  categoriesDetail: `${HOST}/categories`,
  authorsPage: (page = 1, per_page = 10, search = '') => `${HOST}/authors?page=${page}&per_page=${per_page}&search=${search}`,
  publishersList: (page = 1, per_page = 10, search = '') => `${HOST}/publishers?page=${page}&per_page=${per_page}&search=${search}`,
  authorDetail: `${HOST}/authors/`,
  publisherDetail: `${HOST}/publishers/`,
  favProducts: `${HOST}/fav-products`,
  getForgetPasswordOTP: `${HOST}/get-forget-password-verification-otp`,
  resetPasswordOTP: `${HOST}/reset-user-password-with-otp`,
  changePassword: `${HOST}/change-password`,
  productReview: `${HOST}/product_reviews`,
  userDetail: `${HOST}/user-data`,
  userDetailUpdate: `${HOST}/user-data-update`,



  adminAuthorsList: (page = 1, per_page = 10, search = '') => `${HOST}/admin/authors?page=${page}&per_page=${per_page}&search=${search}`,
  adminAuthorDetail: `${HOST}/admin/authors`,
  adminPublishersList: (page = 1, per_page, search = '') => `${HOST}/admin/publishers?page=${page}&per_page=${per_page}&search=${search}`,
  adminPublisherDetail: `${HOST}/admin/publishers`,
  adminCategoriesList: (page = 1, per_page = 10, search = '') => `${HOST}/admin/categories?page=${page}&per_page=${per_page}&search=${search}`,
  adminCategoryDetail: `${HOST}/admin/categories`,
  adminProductsList:  (page = 1, per_page = 10, search = '') => `${HOST}/admin/products?page=${page}&per_page=${per_page}&search=${search}`,
  adminProductsToConfirmList:  (page = 1, per_page = 10, search = '') => `${HOST}/admin/products-to-confirm?page=${page}&per_page=${per_page}&search=${search}`,
  adminReviewProductsPendingList:  (page = 1, per_page = 10, search = '') => `${HOST}/admin/review-products-pending?page=${page}&per_page=${per_page}&search=${search}`,
  adminProductReviewsList:  (page = 1, per_page = 10, search = '', product_id) => `${HOST}/admin/product-reviews-list?page=${page}&per_page=${per_page}&search=${search}&product_id=${product_id}`,
  adminProductReviewsPendingList:  (page = 1, per_page = 10, search = '', product_id) => `${HOST}/admin/product-reviews-pending-list?page=${page}&per_page=${per_page}&search=${search}&product_id=${product_id}`,
  adminUpdateAuthors: (product_id) => {return `${HOST}/admin/products/${product_id}/authors`;},
  adminUpdateCategories: (product_id) => {return `${HOST}/admin/products/${product_id}/categories`;},
  adminProductDetail: `${HOST}/admin/products`,
  adminUsersList: (page = 1, per_page = 10, search = '', direction) => `${HOST}/admin/users?page=${page}&per_page=${per_page}&search=${search}&direction=${direction}`,
  adminUserDetail: `${HOST}/admin/users`,
  adminUserRoles: `${HOST}/admin/user-roles`,
  adminOrdersList:  (page = 1, per_page = 10, search_order_no='', search_name='', search_phone_no='', from_date, to_date, direction) => `${HOST}/admin/orders?page=${page}&per_page=${per_page}&search_order_no=${search_order_no}&search_name=${search_name}&search_phone_no=${search_phone_no}&from_date=${from_date}&to_date=${to_date}&direction=${direction}`,
  adminOrderDetail: `${HOST}/admin/orders`,
  acceptProduct: `${HOST}/admin/accept-product`,
  acceptProductReview: `${HOST}/admin/accept-product-review`,
  productReviewDetail: `${HOST}/admin/update-review-detail`,
};
