import React, {useContext, useState} from "react";
import {useEffect} from "react";
import CategoryPreview from "../../DataDisplay/CategoryPreview";
import "./home.css";
import PageLoader from "../../Loader";
import {ThemeContext} from "../../../Contexts/ThemeContext";
import {useFetchHomeContent} from "../../../Hooks/useFetchHomeContent";
import ScrollMagic from "scrollmagic";
// import {ScrollScene, addIndicators} from 'scrollscene'
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useAddToCart} from "../../../Hooks/useAddToCart";
import {useAddToFav} from "../../../Hooks/useAddToFav";
import Carousel from "react-elastic-carousel";


const Home = () => {
  const [response, fetchCategories] = useFetchHomeContent();
  let categories = response?.data?.categories ?? [];
  const featured_books = response?.data.featured_books;
  const [AddToCart] = useAddToCart();
  let controller = new ScrollMagic.Controller();
  const [pagesVisibility, setPageVisibility] = useState([1, 0]);
  const [favProducts, AddToFav, RemoveFromFav] = useAddToFav();

  const readButton = {
    marginTop: '20px',
    width: "7vw",
    height: "5.5vh",
    borderRadius: "30px",
    background: "#FFFFFF",
    color: "rgb(0, 103, 177)",
    fontFamily: "SFPro",
  };
  const sendMsgButton = {
    width: "90%",
    height: "58px",
    fontSize: "16px",
    color: "#ffffff",
    borderRadius: "29px",
    backgroundImage: "linear-gradient(to right, #1670b9, #1b84de)"
  };
  let addRemove;
  let favIcon;
  if (favProducts.includes(featured_books?.id)) {
    addRemove = RemoveFromFav;
    favIcon = "/icons/like-red.svg";
  } else {
    addRemove = AddToFav;
    favIcon = "/icons/like-icon.svg";
  }
  useEffect(() => {


    new ScrollMagic.Scene({
      triggerElement: ".event1",
      offset: '10',
      duration: '200',
    })
    // .addIndicators()
      .on('start', (event) => {
        if (event.scrollDirection === 'FORWARD')
          setPageVisibility([0, 1, 0]);
        else if (event.scrollDirection === 'REVERSE')
          setPageVisibility([1, 0, 0]);
      })
      .on('end', (event) => {
        if (event.scrollDirection === 'FORWARD')
          setPageVisibility([0, 0, 1]);
        else if (event.scrollDirection === 'REVERSE')
          setPageVisibility([0, 1, 0]);
      })
      // .setPin('.body-contents')
      .addTo(controller);
    new ScrollMagic.Scene({
      triggerElement: ".event3",
      offset: '250',
      duration: '200',
    })
    // .addIndicators()
      .on('end', (event) => {
        if (event.scrollDirection === 'FORWARD')
          setPageVisibility([1, 0, 0]);
        else if (event.scrollDirection === 'REVERSE')
          setPageVisibility([0, 0, 1]);
      })
      // .setPin('.body-contents')
      .addTo(controller);
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);
  const isLoading = response?.isLoading;

  const materialButton = {
    margin: "0 20px",
    color: "#ffffff",
    cursor: "pointer",
  };
  const [, setTheme] = useContext(ThemeContext);

  // When home is mounted theme is set to light
  // when dismounted it is set to dark again
  useEffect(() => {
    setTheme({headerTheme: 'light'});
    return (() => {
      setTheme({headerTheme: 'dark'});
    });
  }, [setTheme]);

  return (
    <>
      <PageLoader
        isLoading={isLoading}
        width={180}
        height={180}
        position={"fixed"}
        top={"50%"}
        left={"50%"}
        padding={"100%"}
      />
      <div className="content-hw">
        {/*****************Header banner and carosel section*********************/}
        <div className="container-fluid image-slider">
          <div className="row carousel-row">
            <Carousel itemsToShow={1} enableMouseSwipe={false} pagination={false} enableAutoPlay={true}
                      autoPlaySpeed={7000} style={{width: 800, marginRight: "auto", marginLeft: "auto"}}>
              {featured_books?.map(featured_book => (
                <div className="carousel-body-contents">
                  <div className="content-image text-center">
                    <img src={featured_book?.product_image ?? "/images/default-book-icon.png"} alt="book-cover" />
                  </div>
                  <div className="content-details">
                    <div className="content-details-header">{featured_book?.product_name_bangla ?? ''}</div>
                    <div className="content-details-p">লেখক: {featured_book?.authors[0]?.author_name_bangla ?? ''}</div>
                    <div className="content-details-p">ক্যাটাগরি :{featured_book?.categories[0]?.category_name_bangla ?? ''}</div>
                    <div className="content-details-p">প্রকাশনী : {featured_book?.publisher_name_bangla ?? ''}</div>
                    <div className="content-details-p">মূল্য :৳ {featured_book?.discounted_price ?? '0'} </div>
                    <div>
                      <Link to={`/book/${featured_book?.id ?? ''}`}
                            style = {{textDecoration: "none"}}>
                        <Button style={readButton}>এখন পড়ুন</Button>
                      </Link>
                      <span><img src="/icons/cart-icon-white.svg" onClick={() => {
                        AddToCart(featured_book?.id);
                      }} style={materialButton} alt={"cart"}/></span>
                      <span><img src={favIcon} onClick={() => {
                        addRemove(featured_book?.id);
                      }} style={materialButton} alt=""/></span>
                      <span><img src="/icons/share.svg" style={materialButton} alt={"share"}/></span>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div className={"event event1"} style={{visibility: pagesVisibility[0] ? 'visible' : 'hidden'}}>
            <img className="flip-book" src="/images/b1.png" alt=""/>
          </div>
          <div className={"event event2"} style={{visibility: pagesVisibility[1] ? 'visible' : 'hidden'}}>
            <img className="flip-book" src="/images/b2.png" alt=""/>
          </div>
          <div className={"event event3"} style={{visibility: pagesVisibility[2] ? 'visible' : 'hidden'}}>
            <img className="flip-book" src="/images/b3.png" alt=""/>
          </div>


        </div>

        {/*****************Home page body section**********************/}
        <div className="body-contents">
          {/********************Bookflix facilites information section***********************/}
          <div className="info-div">
            <div className="row">
              <div className="col-4" style={{marginTop: "6px"}}>
                <div className="info-div-content text-left "
                     style={{marginLeft: "auto", marginRight: "40px", marginTop: "20px"}}>
                  <img src="/images/offline-read-logo-mid.png" className="info-div-logos" alt=""/>
                  <div>
                    <div className="info-div-content-h">অফ লাইনে পড়ার সুবিধা</div>
                    <div className="info-div-content-p">BOOKFLIX এর মাধ্যমে ই-বুক নিদিষ্ট সময়ের
                      জন্য সেইভ করে অফ লাইনে পড়ুন
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4"
                   style={{borderLeft: "2px solid #dbdbdb", borderRight: "2px solid #dbdbdb", marginTop: "25px"}}>
                <div className="info-div-content text-left " style={{marginLeft: "auto", marginRight: "auto"}}>
                  <img src="/images/safe-payment-logo-mid.png" className="info-div-logos" alt=""/>
                  <div>
                    <div className="info-div-content-h">সহজ ও নিরাপদ পেমেন্ট</div>
                    <div className="info-div-content-p">ওয়ালেট, মোবাইল ব্যংকিং ও ক্রেডিট কার্ডের
                      মাধ্যমে নিশ্চিন্তে বই কিনুন
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4" style={{marginTop: "6px"}}>
                <div className="info-div-content text-left "
                     style={{marginLeft: "40px", marginRight: "auto", marginTop: "20px"}}>
                  <img src="/images/large-bookshelves-mid.png" className="info-div-logos" alt=""/>
                  <div>
                    <div className="info-div-content-h">বিশাল বই ভান্ডার</div>
                    <div className="info-div-content-p">আপনার পছন্দের সকল ই-বই পেতে আমাদের
                      BOOKFLIX মোবাইল এপ্লিকেশন ডাউনলোড করুণ
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/**********************Mobile App download link section****************************/}
          <div className="container-fluid app-download-section">
            <div className="row">
              <div className="col-5">
                <div className="logo-link-section">
                  <div className="headline">লাইব্রেরী থাকুক আপনার পকেটে</div>
                  <div style={{
                    marginTop: "30px",
                    fontSize: "1vw",
                    fontWeight: "500",
                    fontFamily: "SFPro",
                    color: "#000000"
                  }}>ডাউনলোড
                  </div>
                  <div className="headline">BOOKFLIX মোবাইল এপ্লিকেশন</div>
                  <div className="app-logos">
                    <a href="https://play.google.com/store/apps/details?id=com.belivit.bookflix&hl=en&gl=US"
                       target="_blank">
                      <img src="/images/playstore-image.png" alt=""/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.belivit.bookflix&hl=en&gl=US"
                       target="_blank" style={{visibility: "hidden"}}>
                      <img src="/images/appstore-image.png" alt=""/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-7 text-center">
                <img className="phone-image" src="/GIFs/mobile.gif" alt=""/>
              </div>
            </div>
          </div>


          {/*****************book catergory carousel section****************/}
          <div className="container-fluid">
            <div className=" book-carousel">
              <div className="bg-white mt-5  categorydiv">
                {categories.map(category => <CategoryPreview key={category.id} categories={category}/>
                )}
              </div>
            </div>
          </div>


          {/***********************our client section*****************************/}
          <div className="container-fluid client-section">
            <div className="client-section-body">
              <div className=" text-center client-section-header">আমাদের সম্মানিত ক্লাইন্ট</div>
              <img className="client-body-image" src="/images/client-body-image.png" alt=""/>
              <div className=" text-center client-section-footer">আমার জানার ইচ্ছাটা একটু বেশি,বইটির নাম শুনেই কেনো জানি
                মনে হয়েছে হয়তো অনেক কিছু জানা জাবে বইটি পড়লে। জানার সেই আগ্রহ থেকেই BOOKfLIX এর মাধ্যমে বইটি পড়ে আমার
                বেশ ভালো লেগেছে।জানতে পেরেছি অনেক কিছু, যে টা আমি আমার জীবনে কাজে লাগাতে পারব। শুধু আমি না প্রতিটি তরুণ
                এই বইটি পড়া উচিৎ। যাদের শিক্ষার ইচ্ছা আছে তারা এই বই পড়ে অনেক আনন্দিত হবেন।লেখার মানও অনেক ভাল। এই
                মোবাইল এপ্লিকেশনটি আসলে অনেক উপকারী।
              </div>
            </div>
          </div>

          {/*****************************send us message section****************************/}
          <div className="container-fluid send-us-msg-section">
            <div className="">
              <div className=" send-us-msg-section-header text-center">বার্তা পাঠান</div>
              <p className="text-center" style={{marginTop: "15px"}}>আপনি আপনার যেকনো প্রশ্ন/জিজ্ঞাসা আমাদের জানাতে
                পারবেন</p>
              <div className="row">
                <div className="col-7">
                  <img className="send-msg-image" src="/images/home-send-msg-image.png" alt=""/>
                </div>
                <div className="col-4">
                  <div className="input-message-section">
                    <input className="send-message-inputs" type="text" placeholder="Name"/>
                    <input className="send-message-inputs" type="text" placeholder="Email/Mobile Number"/>
                    <textarea className="send-message-inputs" placeholder="Enter message here..." rows="4" cols="50"
                              name="comment" form="usrform"/>
                  </div>
                  <Button style={sendMsgButton}>বার্তা পাঠান</Button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
