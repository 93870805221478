import React, {useEffect, useRef, useState} from "react";
import "./multiselectcomponent.css";
import {Scrollbars} from "react-custom-scrollbars";
import MultiSelectAddedData from "./MultiselectAddedData";
import MultiSelectSearchResults from "./SearchResults";
import {Button} from "@material-ui/core";


const MultiSelectComponent = (props) => {
  const {
    contentName,
    useSearchHook,
    selectedData = [],
    selectedDataLabel,
    onSave,
  } = props;
  const [searchText, setSearchText] = useState('');
  const [searchResult, fetchSearchResult] = useSearchHook();
  const [updatedSelectedData, setUpdatedSelectedData] = useState(selectedData);
  const searchRef = useRef(null);

  useEffect(() => {
    setUpdatedSelectedData(selectedData);
  }, [selectedData]);

  const handleReset = () => {
    setUpdatedSelectedData(selectedData);
  };
  const removeData = (dataToBeRemoved) => {
    setUpdatedSelectedData(updatedSelectedData.filter(item => item.id !== dataToBeRemoved.id));
  };
  const createJSON = (key, values) => {
    let ret = {};
    ret[key] = values;
    return ret;
  };
  ////// hide javascript event listener /////////
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  /////// click event funtion //////
  const handleClickOutside = event => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchText('');
    }
    if (searchRef.current && searchRef.current.contains(event.target)) {
      setSearchText('');
    }

  };

  return (
    <>
      <h3 style={{textTransform: "capitalize"}}>Update Product's {contentName} Information</h3>
      <div className="main-multiselect-body">
        <div className="main-multiselect-div">
          <div className="multiselect-data-list m-4">
            {updatedSelectedData?.map(data => (
              <MultiSelectAddedData selectedData={data} selectedDataLabel={selectedDataLabel} removeData={removeData}/>
            ))}
          </div>
          <div className="multiselect-search-input-div text-center m-4">
            <input type="search"
                   className="multiselect-search-input "
                   value={searchText}
                   placeholder="Search"
                   aria-label="Search"
                   onChange={(e) => {
                     setSearchText(e.target.value);
                     fetchSearchResult(e.target.value);
                   }}/>
            {searchText && (
              <div className="multiselect-search-div" ref={searchRef}>
                <Scrollbars style={{height: "100%"}}>
                  {searchResult.data.map(searchData => (
                    <MultiSelectSearchResults multiSelectSearchData={searchData}
                                              onClick={() => {
                                                if (!updatedSelectedData.find(data => data.id === searchData.id)) {
                                                  setUpdatedSelectedData(prevState => ([...prevState, searchData]));
                                                }
                                              }}
                                              isAlreadySelected={!!updatedSelectedData.find(data => data.id === searchData.id)}/>
                  ))}
                </Scrollbars>
              </div>
            )}
          </div>
          <div className="save-reset-buttons ml-4 mr-4">
            <Button className={'mt-3 d-block'} onClick={handleReset} variant="contained" color="secondary">
              Reset {contentName}
            </Button>

            <Button className={'mt-3 d-block'}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      onSave(createJSON(contentName, updatedSelectedData.map(data => data.id)));
                    }}>
              Save {contentName}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiSelectComponent;
