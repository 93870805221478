import React from 'react';
import './mobilehome.css';

const MobileHomePage = () => {
  return (
    <>
      <div className="main-body-div">
        <div className="text-center">
          <img className="site-logo" src="/images/bookflix-small.png" alt=""/>
        </div>
        <div className="container-fluid app-link-logo-div">
          <div className="row">
            <div className="col-12">
              <div style={{
                fontSize: "5vw",
                fontWeight: "500",
                fontFamily: "SFPro",
                color: "#000000",
                marginBottom: "20px"
              }}>ডাউনলোড BOOKFLIX মোবাইল এপ্লিকেশন</div>
            </div>
            <div className="col-12 text-center">
              <a href="https://play.google.com/store/apps/details?id=com.belivit.bookflix&hl=en&gl=US"  target="_blank">
                <img className="playstore-image" src="/images/playstore-image.png" alt=""/>
              </a>
              {/*<a href="#">*/}
              {/*  <img className="appstore-image" src="/images/appstore-image.png" alt=""/>*/}
              {/*</a>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileHomePage;
