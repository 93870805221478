import React, {useEffect, useState} from 'react';
import {Button, TextareaAutosize, TextField} from "@material-ui/core";
import {useSaveAuthorDetail} from "../../../../../Hooks/Admin/Authors/useSaveAuthorDetail";
import {
  validateMaxLength, validateMinLength,
  validateRequired,
  validates,
} from "../../../../FormValidator/Validator";
import {NAME_MAX_LEN, NAME_BANGLA_MAX_LEN, NAME_MIN_LEN, NAME_BANGLA_MIN_LEN} from "../../../../../Constants/general";

const AuthorNewForm = (props) => {
  const {author, setAuthor} = props;
  const [, saveAuthor] = useSaveAuthorDetail();
  const [errors, setErrors] = useState();

  const handleChange = (event) => {
    setAuthor((prevValue) => ({...prevValue, [event.target.name]: event.target.value}));
  };

  const inputFieldLabel = {
    margin: "16px 0",
  };

  const saveProductDetail = () => {
    saveAuthor(author);
  };

  //////// enable/disable submit button ////////
  let buttonClickable = false;
  if (!author.name?.length > 0 || !author.name_bangla?.length > 0 ||  errors.name?.length > 0 || errors.name_bangla?.length > 0) {
    buttonClickable = true;
  }

  useEffect(() => {
    setErrors(
      validates(
        validateRequired('name'),
        validateRequired('name_bangla'),
        validateMaxLength('name', NAME_MAX_LEN),
        validateMinLength('name', NAME_MIN_LEN),
        validateMaxLength('name_bangla', NAME_BANGLA_MAX_LEN),
        validateMinLength('name_bangla', NAME_BANGLA_MIN_LEN),
      )(author, {}),
    );
  }, [author]);
  return (
    <>
      <h3>Create New Author</h3>
      <div className={'author-form-main-div'}>
        <br/>
        <label style={inputFieldLabel}>Author Name: <span style={{color: "red"}}>*</span></label>
        <TextField
          name={'name'}
          id={'author-name'}
          value={author?.name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.name)}
          helperText={(errors?.name)}
        />
        <label style={inputFieldLabel}>Author Name Bangla: <span style={{color: "red"}}>*</span></label>
        <TextField
          name={'name_bangla'}
          id={'author-name-bangla'}
          value={author?.name_bangla}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.name_bangla)}
          helperText={(errors?.name_bangla)}
        />
        <label style={inputFieldLabel}>Author Description: </label>
        <TextareaAutosize
          style={{ height: 100 }}
          name={'description'}
          id={'author-description'}
          value={author?.description}
          className={'form-control'}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        <label style={inputFieldLabel}>Author Born At: </label>
        <TextField
          type={'date'}
          name={'born_at'}
          id={'author-born-at'}
          value={author?.born_at}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        <label style={inputFieldLabel}>Author Died At: </label>
        <TextField
          type={'date'}
          name={'died_at'}
          id={'author-born-at'}
          value={author?.died_at}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        <label style={inputFieldLabel}>Author Image Url: </label>
        <TextField
          name={'image'}
          type={'text'}
          id={'author-image-url'}
          value={author?.image}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        {JSON.parse(localStorage.getItem("user_type")) === 100 ? (
          <>
            <label style={inputFieldLabel}>Is Disabled?: </label>
            <input
              type={'checkbox'}
              checked={author?.is_disabled}
              onChange={(event) => {
                setAuthor((prevValue) => ({...prevValue, is_disabled: event.target.checked}));
              }}/>
            <br/>
          </>
        ) : (
          <></>
        )}
        <Button className={'mt-3 d-block'} onClick={saveProductDetail} variant="contained"
                disabled={buttonClickable}
                color="primary">Save</Button>
      </div>
    </>
  );
};

export default AuthorNewForm;
