import { useCallback, useState } from 'react';
import  axios  from "axios";
import {urls} from "../../../Constants/urls";
import {toast} from "react-toastify";
import {useErrorHandler} from "../../useErrorHandler";
import { useHistory } from "react-router-dom";


export const useConfirmProduct = () => {
  let history = useHistory();
  const errorHandler = useErrorHandler();
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const confirmProduct = useCallback((productId)=>{
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = `${urls.acceptProduct}`;
      const res = await axios.post(url, {product_id: productId},{ params: {token: JSON.parse(localStorage.getItem("token"))}});
      setResponse(prevState => ({...prevState, data: res.data.data, isLoading: false}));
      if(res.data.status === 200){
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }else{
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }
      await history.push(`/admin/products`);
    };

    asyncRequest().catch(error => {
      console.log(error);
      errorHandler(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, confirmProduct];
};
