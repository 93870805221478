import { useCallback, useState } from 'react';
import  axios  from "axios";
import {urls} from "../../../Constants/urls";
import {toast} from "react-toastify";

export const useSaveUserDetail = () => {
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const fetchUserDetail = useCallback((userDetail)=>{
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = urls.adminUserDetail;

      const res = await axios.post(url,userDetail,{params: {token: JSON.parse(localStorage.getItem("token"))}});
      setResponse(prevState => ({...prevState, data: res.data.data, isLoading: false}));
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }else{
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }

    };

    asyncRequest().catch(error => {
      console.log(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, fetchUserDetail];
};
