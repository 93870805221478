import React, {useEffect, useState} from 'react';
import UserEditForm from "./user-edit-form";
import {Link, useParams} from "react-router-dom";
import {useFetchUserDetail} from "../../../../../Hooks/Admin/Users/useFetchUserDetail";
import './userDetails.css';

const UserDetailPage = () => {
  const {userId} = useParams();
  const [response, fetchUserDetail] = useFetchUserDetail();
  const [user, setUser] = useState({});

  useEffect(() => {
    fetchUserDetail(userId);
  }, [fetchUserDetail]);
  useEffect(() => {
    const userInfo = response?.data?.user;
    setUser({
      id: userInfo?.id,
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      phone_no: userInfo?.phone_no,
      alternate_phone_no: userInfo?.alternate_phone_no,
      email: userInfo?.email,
      password: userInfo?.password,
      is_verified: userInfo?.is_verified,
      token: userInfo?.token,
      logged_in_at: userInfo?.logged_in_at,
      verification_code: userInfo?.verification_code,
      last_otp_sent_at: userInfo?.last_otp_sent_at,
      user_role_id: userInfo?.user_role_id,
      forget_password_otp: userInfo?.forget_password_otp,
      forget_password_otp_expire_at: userInfo?.forget_password_otp_expire_at,
      created_at: userInfo?.created_at,
      updated_at: userInfo?.updated_at,
    });
  }, [response]);

  return (
    <>
      <div className={'user-info-form'}>
        <Link to={"/admin/users"}>
          <i className="fas fa-arrow-left "  style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <UserEditForm user={user} setUser={setUser}/>
      </div>
    </>

  );
};

export default UserDetailPage;
