import { useCallback, useState } from 'react';
import {urls} from "../Constants/urls";
import  axios  from "axios";

export const useFetchCartContent = () => {
    const [response, setResponse] = useState({
        data: [],
        isLoading: false,
        error: null,
    });
    const fetchCartContent = useCallback((cart_product_ids)=>{
        setResponse(prevState => ({...prevState, isLoading: true}));
        const asyncRequest = async () => {
            const url = urls.cartProducts;
            const res = await axios.post(url, {products: cart_product_ids});
            setResponse(prevState => ({...prevState, data: res.data, isLoading: false}));
        };

        asyncRequest().catch(error => {
            console.log(error);
            setResponse(prevState => ({...prevState, isLoading: false}));
        });
    }, [setResponse]);

    return [response, fetchCartContent];
};
