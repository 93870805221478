import React, {useState} from 'react';
import LoginForm from "../../LoginRegistration/LoginForm";
import OTPForm from "../../LoginRegistration/OTPForm";
import {useHistory} from "react-router-dom";

const LoginPage = () => {
  const history = useHistory();
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [user, setUser] = useState({});
  const onLoginSuccess = () => {
    history.push('/');
  };
  const onLoginFailure = (error) => {
    if(error.response.status === 403){
      //  Show OTP Verification Component
      setShowLoginForm(false);
      setShowOTPForm(true);
    }
    else{

    }
  };
  const onOTPVerificationSuccess = () => {
    history.push('/');
  };
  const onOTPVerificationFailure = (error) => {

  };

  return (
    <>
      <div style={{height: "50vh"}}>
        {showLoginForm && <LoginForm onLoginSuccess={onLoginSuccess}
                                     onLoginFailure={onLoginFailure}
                                     user={user}
                                     setUser={setUser}
        />}
        {showOTPForm && <OTPForm phoneNo={user.phone_no}
                                 onOTPVerificationSuccess={onOTPVerificationSuccess}
                                 onOTPVerificationFailure={onOTPVerificationFailure}/>}
      </div>

    </>);
};

export default LoginPage;
