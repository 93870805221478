import React from "react";
import {useState, useEffect} from "react";
import "./bookdetails.css";
import QRCode from "react-qr-code";
import axios from "axios";
import {useParams, Link} from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {urls} from "../../../Constants/urls";
import {Button, TextareaAutosize} from "@material-ui/core";
import {useAddToCart} from "../../../Hooks/useAddToCart";
import StarRatingPreview from "../../DataDisplay/StarRatingPreview";
import {useFetchBookDetail} from "../../../Hooks/useFetchBookDetail";
import PageLoader from "../../Loader";
import {Tab, Tabs} from "react-bootstrap";
import RelatedBookPreview from "../../DataDisplay/RelatedBookPreview";
import {useSaveProductReview} from "../../../Hooks/useSaveProductReview";

toast.configure();
export const BookDetailPage = (props) => {
  const [AddToCart] = useAddToCart();
  let {bookId} = useParams();
  const [response, fetchBookDetail] = useFetchBookDetail();
  const productDetail = response?.data?.product;
  const productError = response?.data?.status;
  const isLoading = response?.isLoading;
  const [showReviewPostPanel, toggleShowReviewPostPanel] = useState(false);
  const [,saveProductReview] = useSaveProductReview();
  const handleTogglePostPanel = () => {
    toggleShowReviewPostPanel(!showReviewPostPanel);
  };
  useEffect(() => {
    fetchBookDetail(bookId);
  }, []);

  const [, setCategories] = useState([]);
  const category_url = `${urls.homePageUrl}`;
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(category_url)
      .then(function (response) {
        // I need this data here ^^
        setCategories(response.data.categories);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [category_url]);


  let productPrice;
  if (productDetail?.discounted_price === 0 || productDetail?.discounted_price === null) {
    productPrice = "ফ্রি বই";
  } else {
    productPrice = productDetail?.discounted_price + "TK";
  }

  const product_id = productDetail?.id;

  // const [rating, setRating] = useState(Math.round(0));
  const [rating, setRating] = useState(5);
  const [reviewText, setReviewText] = useState();

  const handleChange = (value) => {
    setRating(value);
  };
  const submitReview = () => {
    saveProductReview(rating, reviewText, bookId);
  };
  return (
    <>
      <PageLoader
        isLoading={isLoading}
        width={180}
        height={180}
        position={"fixed"}
        top={"50%"}
        left={"50%"}
        padding={"100%"}
      />
      {productError === 200 ? <>
        {/* Upper div book details section  */}
        <div className="main-book-details-template">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12 d-flex justify-content-lg-start">
              <div className="book-image">
                <img
                  src={productDetail?.image}
                  className="float-right"
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/images/default-book-icon.png";
                  }}
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-3 col-sm-12 d-flex justify-content-start mobile-contents">
              <div className="details">
                <div className="title">{productDetail?.name_bangla} </div>
                {productDetail?.authors?.map(author => (
                  <Link to={`/author/${author?.id}`} style={{textDecoration: "none"}}>
                    <div className="book-details-author-name">{author?.author_name_bangla}</div>
                  </Link>
                ))}

                <div className="rating-price">
                <span className="rating-star">
                <StarRatingPreview
                  size={25}
                  value={productDetail?.average_rating}
                  activeStar={'/icons/rating-star-active.svg'}
                  inactiveStar={'/icons/rating-star-inactive.svg'}
                />
             </span>
                  <span>({productDetail?.review_count} রিভিউ)</span>
                  <span className="product-price"> মূল্য : {productPrice} </span>
                </div>
                <div className="book-details-category-name details-contents">ক্যাটাগরি:
                  {productDetail?.categories?.map(category => (
                    <Link to={`/category/${category?.id}`} style={{textDecoration: "none"}}>
                      <span>{category.category_name_bangla}</span>
                    </Link>
                  ))}
                </div>
                <div className="number-of-pages details-contents">পৃষ্ঠা সংখ্যা: {productDetail?.total_pages}</div>
                <div className="publisher-name details-contents">প্রকাশনী
                  : {productDetail?.publisher?.name_bangla}  </div>
                {/*<div className="country-name details-contents">দেশ: বাংলাদেশ </div>*/}
                <div className="app-download-info">
                  বই পড়তে BOOKFLIX মোবাইল এপ্লিকেশনটি
                  ডাউনলোড করুণ অথবা এই মোবাইল এপ্লিকেশনটি দিয়ে
                  ডান পাশের কোডটি স্কেন করুণ
                </div>
              </div>
            </div>
            {/* Upper div QR code section  */}
            <div className=" col-lg-3 col-md-3 col-sm-12 mobile-contents">
              <div className=" qr-section">
                <div className="QR-code">
                  <QRCode size="168" value={productDetail?.id?.toString() ?? ''}/>
                </div>
                <div className="scan-text">Scan with BOOKFLIX App</div>
                <div className="add-to-cart">
                  <Button onClick={() => {
                    AddToCart(product_id);
                  }}>
                <span>
                    <i style={{color: "#ffffff"}} className="fa fa-cart-plus"/>
                </span>
                    কার্টে যুক্ত করুন
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
          <br/>


          {/***********details/review/related books section**************/}
        <div className="main-book-details-template">
          <div className="row">
            <div className="col-12">
              <div className="book-author-details ">
                <Tabs>
                  <Tab eventKey={1} title="বই পরিচিতি">
                    <div style={{marginTop: 20, marginBottom: 20, textAlign: "justify"}}>
                      {productDetail?.description}
                    </div>
                  </Tab>
                  <Tab eventKey={2} title="লেখক পরিচিতি">
                    {productDetail?.authors?.map(author => (
                      <div style={{marginTop: 20, marginBottom: 20, textAlign: "justify"}}>
                        {author?.author_description}
                      </div>
                    ))}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <div className="related-books-details-main-div">
          <div className=" bg-white">
            <RelatedBookPreview key={productDetail?.categories[0]?.id} categoryId={productDetail?.categories[0]?.id}/>
          </div>
        </div>
        <div className="main-book-details-template">
          {/**********Review Section*************/}
          <div className="row review-div-sec">
            <div className="col-6">
              <div className="h4 font-weight-bold">
                রিভিউ
              </div>
              <div className="rating-price">
                <span className="rating-star">
                <StarRatingPreview
                  size={25}
                  value={productDetail?.average_rating}
                  activeStar={'/icons/rating-star-active.svg'}
                  inactiveStar={'/icons/rating-star-inactive.svg'}
                />
                 </span>
                <span>({productDetail?.review_count} রিভিউ)</span>
              </div>
            </div>

            <div className="col-6 write-review-button d-flex justify-content-end align-items-end">
              <Button onClick={handleTogglePostPanel}>
                রিভিউ লিখুন
              </Button>
            </div>
          </div>
        </div>
        <div className="main-book-details-template">
          {showReviewPostPanel && (
            <div className="row review-post-sec">
              <div className="col-12">
                <span style={{marginRight: 20}}>Rate this book</span>
                <span>
                <StarRatingPreview
                  size={25}
                  type={"dynamic"}
                  value={rating}
                  activeStar={'/icons/rating-star-active.svg'}
                  inactiveStar={'/icons/rating-star-inactive.svg'}
                  onChange={handleChange}
                />
              </span>
              </div>
              <div className="col-12">
              <TextareaAutosize
                placeholder={"Write review here"}
                style={{width: "100%", height: 100}}
                onChange={e => setReviewText(e.target.value)}
              />
              </div>
              <div className="col-12 review-post-button">
                <Button onClick={submitReview}>
                  বার্তা পাঠান
                </Button>
              </div>
            </div>
          )}
          {productDetail?.product_reviews?.map(review => (
            <div className="col-12 comment-section ">
              <div className="d-flex">
                <div className="commenter-image">
                  <img src="/icons/user-icon.svg" alt=""/>
                </div>
                <div className="commenter-name-and-rating">
                  <div className="commenter-name">
                    {review?.user_name}
                  </div>
                  <div className="commenter-rating">
                    <StarRatingPreview
                      size={25}
                      value={review?.rating}
                      activeStar={'/icons/rating-star-active.svg'}
                      inactiveStar={'/icons/rating-star-inactive.svg'}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 comments d-flex">
                {review?.review_text}
              </div>
            </div>
          ))}
        </div>
      </> : <>
        <div className="" style={{height: "38vh", marginTop: "6%"}} align="center">
          <div>
            <img src="/images/bookflix-small.png" alt="BookFlix.com" height={100} width={300}/>
            <br/><br/>
            <span style={{color: "#ff8300", fontSize: "30px"}}> {response?.data?.error} {response?.data?.message}</span>
          </div>
        </div>
      </>}
    </>
  );
};
