import React, {useEffect} from "react";
import "./userprofile.css";
import {Button} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {useFetchUserProfileDetail} from "../../../Hooks/useFetchUserDetail";


const UserProfile = () => {
  const history = useHistory();
  const [res, fetchUser] = useFetchUserProfileDetail();
  let userInfo = res?.data?.user;
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <>
      <div className={'user-profile-page-container'}>
        <div className="pb-3 border-bottom d-flex justify-content-between">
          <span className="h4">
            General Information
          </span>
          <Link to={'/change-password'} target= "_blank">
            Change Password
          </Link>
        </div>
        <div className="pb-3 pt-3 user-information">
          <span>First Name</span>
          <span>{userInfo?.first_name}</span>
        </div>

        <div className="pb-3 user-information">
          <span>Last Name</span>
          <span>{userInfo?.last_name}</span>
        </div>

        <div className="pb-3 user-information">
          <span>Phone no</span>
          <span>{userInfo?.phone_no}</span>
        </div>

        <div className="pb-4 user-information">
          <span>Email</span>
          <span>{userInfo?.email}</span>
        </div>

        {/*<div className="user-image pb-3 user-information">*/}
        {/*  <span>Image</span>*/}
        {/*  <span>*/}
        {/*    <img src="/icons/user-icon.svg" alt=""/>*/}
        {/*  </span>*/}
        {/*</div>*/}

        <Button onClick={() => {
          history.push(`/user-profile-edit`);
        }}
                variant="contained"
                color="secondary">
          Update info
        </Button>
      </div>
    </>
  );
};

export default UserProfile;