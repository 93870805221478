import React, {useEffect, useState} from 'react';
import {Button, TextField, Collapse} from "@material-ui/core";
import {useSaveUserDetail} from "../../../../../Hooks/Admin/Users/useSaveUserDetail";
import {useFetchUserRoles} from "../../../../../Hooks/Admin/Users/useFetchUserRoles";
import {
  validateEmail,
  validateMaxLength, validateMinLength,
  validatePhoneNo,
  validateRequired,
  validates
} from "../../../../FormValidator/Validator";
import {
  EMAIL_MAX_LEN,
  FIRST_NAME_MAX_LEN,
  FIRST_NAME_MIN_LEN,
  LAST_NAME_MAX_LEN,
  LAST_NAME_MIN_LEN, PASSWORD_MAX_LEN, PASSWORD_MIN_LEN, PHONE_NO_MAX_LEN, PHONE_NO_MIN_LEN
} from "../../../../../Constants/general";

const UserNewForm = (props) => {
  const {user, setUser} = props;
  const [, saveUser] = useSaveUserDetail();
  const [data, fetchUserRoles] = useFetchUserRoles();
  const userRoles = data?.data?.user_roles;
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchUserRoles();
  }, []);

  //////// enable/disable submit button ////////
  let buttonClickable = false;
  if (!user.first_name?.length > 0 || !user.last_name?.length > 0 || !user.phone_no?.length > 0 || !user.password?.length || errors.first_name?.length > 0 || errors.last_name?.length > 0 || errors.phone_no?.length > 0 || errors.phone_no?.length > 0 || errors.alternate_phone_no?.length > 0 || errors.password?.length || errors.email?.length) {
    buttonClickable = true;
  }

  useEffect(() => {
    fetchUserRoles();
  }, []);

  useEffect(() => {
    setErrors(
      validates(
        validateRequired('first_name'),
        validateRequired('last_name'),
        validateRequired('phone_no'),
        validateRequired('password'),
        validatePhoneNo('phone_no'),
        validatePhoneNo('alternate_phone_no'),
        validateEmail('email'),
        validateMaxLength('first_name', FIRST_NAME_MAX_LEN),
        validateMinLength("first_name", FIRST_NAME_MIN_LEN),
        validateMaxLength('last_name', LAST_NAME_MAX_LEN),
        validateMinLength("last_name", LAST_NAME_MIN_LEN),
        validateMaxLength('phone_no', PHONE_NO_MAX_LEN),
        validateMinLength("phone_no", PHONE_NO_MIN_LEN),
        validateMaxLength('alternate_phone_no', PHONE_NO_MAX_LEN),
        validateMinLength("alternate_phone_no", PHONE_NO_MIN_LEN),
        validateMaxLength("email", EMAIL_MAX_LEN),
        validateMaxLength('password', PASSWORD_MAX_LEN),
        validateMinLength("password", PASSWORD_MIN_LEN),
      )(user, {}),
    );
  }, [user]);
  const handleChange = (event) => {
    setUser((prevValue) => ({...prevValue, [event.target.name]: event.target.value}));
  };

  const inputFieldLabel = {
    margin: "16px 0",
  };

  const saveUserDetail = () => {
    saveUser(user);
  };

  return (
    <>
      <h3>Create New User</h3>
      <div className={'user-form-main-div'}>
        <br/>
        <label style={inputFieldLabel}>First Name: </label>
        <TextField
          name={'first_name'}
          id={'first-name'}
          value={user?.first_name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.first_name)}
          helperText={(errors?.first_name)}
        />
        <label style={inputFieldLabel}>Last Name: </label>
        <TextField
          name={'last_name'}
          id={'last-name'}
          value={user?.last_name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.last_name)}
          helperText={(errors?.last_name)}
        />
        <label style={inputFieldLabel}> Phone No.: </label>
        <TextField
          name={'phone_no'}
          id={'user-phone-no'}
          type="tel"
          placeholder="Ex:+8801[346789]******** or 01[346789]********"
          value={user?.phone_no}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.phone_no)}
          helperText={(errors?.phone_no)}
        />
        <label style={inputFieldLabel}>Alternate Phone No.: </label>
        <TextField
          name={'alternate_phone_no'}
          id={'alternate-phone-no'}
          type="tel"
          placeholder="Ex:+8801[346789]******** or 01[346789]********"
          value={user?.alternate_phone_no}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.alternate_phone_no)}
          helperText={(errors?.alternate_phone_no)}
        />
        <label style={inputFieldLabel}>Password: </label>
        <TextField
          name={'password'}
          id={'user-password'}
          type={'password'}
          value={user?.password}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.password)}
          helperText={(errors?.password)}
        />
        <label style={inputFieldLabel}>Email: </label>
        <TextField
          name={'email'}
          id={'user-email'}
          value={user?.email}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.email)}
          helperText={(errors?.email)}
        />
        <label style={inputFieldLabel}>User Role: </label>
        <select
          name={'user_role_id'}
          id={'user-role-id'}
          className="form-control"
          value={user?.user_role_id}
          onChange={handleChange}
        >
          {userRoles?.map(userRole => (
            <option value={userRole.id}>{userRole.name}</option>
          ))}
        </select>
        <br/><br/>
        <label>Is Verified?: </label>
        <input type={'checkbox'} id={'is-verified'} checked={user?.is_verified} className={'is-verified'}
               onChange={(event) => {
                 setUser((prevValue) => ({...prevValue, is_verified: event.target.checked}));
               }}/>
        <br/>
        {/*<label>Category Image Url: </label>*/}
        {/*<input name={'image'} type={'text'} id={'category-image-url'} value={user?.image}*/}
        {/*       className={'form-control'} onChange={handleChange}/>*/}

        <Button className={'mt-3 d-block'} onClick={saveUserDetail} variant="contained" disabled={buttonClickable}
                color="primary">Save</Button>
      </div>
    </>
  );
};

export default UserNewForm;
