import { useCallback, useState } from 'react';
import  axios  from "axios";
import {toast} from "react-toastify";
import {urls} from "../Constants/urls";
import {useErrorHandler} from "./useErrorHandler";
import {useHistory} from "react-router-dom";

export const useUpdateUserInfo = () => {
  const errorHandler = useErrorHandler();
  const history = useHistory();
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const updateUserInfo = useCallback((userDetail)=>{
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = `${urls.userDetailUpdate}`;
      const res = await axios.patch(url,userDetail,{params: {token: JSON.parse(localStorage.getItem("token"))}});
      setResponse(prevState => ({...prevState, data: res.data.data, isLoading: false}));
      toast.success(res.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
      if(res.data.status === 200) {
        setTimeout(()=>{
          history.push('/user-profile');
        }, 1000);
      }
    };

    asyncRequest().catch(error => {
      console.log(error);
      errorHandler(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, updateUserInfo];
};
