import React from "react";
import "./publisherpreview.css";
import {Link} from 'react-router-dom';

const PublisherPreview = (props) => {
  return (
    <>
      <div className="r-publisher-image">
        <div>
          <Link to={`/publisher/${props.id}`}>
            <img className="publisher-image" src={props.image_url ?? "/images/default-publisher-icon.png"} onError={(e)=>{e.target.onerror = null; e.target.src="/images/default-publisher-icon.png";}} width="200px" height="300px" alt=""/>
          </Link>
          <div className="publisher-title">{props.name_bangla}</div>
          {/*<div className="no-of-books">৳ {props.price}</div>*/}
        </div>
      </div>
    </>
  );
};

export default PublisherPreview;
