import React, {useContext, useRef, useState, useEffect, useCallback} from "react";
import {
  Navbar,
  Nav,
} from "react-bootstrap";
import "./header.css";
import {SearchPage} from "../Pages/Search";
import {NavLink, Link} from 'react-router-dom';
import {CartCountConsumer, CartCountContext} from "../../Contexts/CartCountContext";
import {ThemeContext} from "../../Contexts/ThemeContext";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LoginForm from "../LoginRegistration/LoginForm";
import OTPForm from "../LoginRegistration/OTPForm";
import {useFetchSearchContent} from "../../Hooks/useFetchSearchContent";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import debounce from 'lodash.debounce';

const Header = () => {
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [cartCount] = useContext(CartCountContext);
  const [searchText, setSearchtext] = useState('');
  const [response, fetchSearchContents] = useFetchSearchContent();
  const searchRef = useRef(null);
  const loginRef = useRef(null);
  const history = useHistory();
  const [showSearchBox, toggleSearchBox] = useState(false);
  const [theme] = useContext(ThemeContext);
  const [user, setUser] = useState({});
  const [navbar, setNavbar] = useState(false);
  const navBarStyle = {color: theme?.headerTheme === 'light' && !navbar ? '#FFFFFF' : '#545454'};



  const checkIfLoggedIn = () => {
    return !(localStorage.getItem("token") === null || typeof localStorage.getItem("token") === 'undefined');
  };

  const [isLoggedIn, setIsLoggedIn] = useState(checkIfLoggedIn());
  const [showLoginPanel, toggleLoginPanel] = useState(false);
  const [showLoginMenu, toggleLoginMenu] = useState(false);


  const homeAccountLinks = {
    textDecoration: "none",
    color: "rgb(84, 84, 84)"
  };
  let homeAccountDiv;
  JSON.parse(localStorage.getItem("user_type")) === 100 || JSON.parse(localStorage.getItem("user_type")) === 10 ? homeAccountDiv = {top: "176px"} :  homeAccountDiv = {top: "151px"};

  const handleClickLogOut = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    localStorage.removeItem("user_type");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    handleToggle();
    history.push('/');
    toast.success("Successfully Logged Out ", {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 4000
    });
  };
  const onLoginFailure = (error) => {
    if (error.response.status === 403) {
      //  Show OTP Verification Component
      setShowLoginForm(false);
      setShowOTPForm(true);
    } else {

    }
  };
  const onLoginSuccess = () => {
    setIsLoggedIn(true);
    handleToggle();
  };

  const debounceSearchTexts = useCallback(
    debounce(nextValue => fetchSearchContents(nextValue), 1000),
    [],
  );
  const handleSearch = (e) => {
    const {value: nextValue} = e.target;
    setSearchtext(nextValue);
    debounceSearchTexts(nextValue);
  };
  const onOTPVerificationSuccess = () => {
    handleToggle();
  };
  const onOTPVerificationFailure = (error) => {

  };
  const handleToggle = () => {
    if (isLoggedIn) {
      toggleLoginPanel(false);
      toggleLoginMenu(!showLoginMenu);
    } else {
      toggleLoginMenu(false);
      toggleLoginPanel(!showLoginPanel);
    }
  };

  ////// hide javascript event listener /////////
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  /////// click event funtion //////
  const handleClickOutside = event => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchtext('');
    }
    if (searchRef.current && searchRef.current.contains(event.target)) {
      setSearchtext('');
    }
    if (loginRef.current && !loginRef.current.contains(event.target)) {
      toggleLoginPanel(false);
      toggleLoginMenu(false);
    }
  };

  let searchToggleLogo;
  if (showSearchBox === true) {
    searchToggleLogo = "/icons/cross-icon.svg";
  } else {
    searchToggleLogo = theme?.headerTheme === 'light' && !navbar ? "/icons/search-icon-white.svg" : "/icons/search-icon.svg";
  }

  const changeBackground = () => {
    if(window.scrollY >= 70) {
      setNavbar(true);
    }else{
      setNavbar(false);
    }
  };
  window.addEventListener('scroll', changeBackground);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };


  return (
    <>
      <Navbar expand="md" variant="light" className= {navbar ? 'navigation-bar active' : "navigation-bar"}>
        <Navbar.Brand className="nav-logo mr-auto" href="/">
          <img
            className="nav-logo "
            src={theme?.headerTheme === 'light' && !navbar ? "/images/bookflix-small-white.png" : "/images/bookflix-small.png"}
            height="auto"
            width="90%"
            alt="BookFlix Logo"
            id="navbar-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto nav-items ">
            <span className="nav-links" onClick={scrollToTop} style={navBarStyle}>হোম</span>
            <NavLink className="nav-links" to="/categories" style={navBarStyle}>ক্যাটাগরি</NavLink>
            <NavLink className="nav-links" to="/authors" style={navBarStyle}>লেখক</NavLink>
            <NavLink className="nav-links" to="/publishers" style={navBarStyle}>প্রকাশনী</NavLink>
          </Nav>

          <Nav className="right-nav-items">
            <div>
              {showSearchBox && (
                <input onChange={handleSearch} type="text" className=" search-input" value={searchText}
                       placeholder="Search" aria-label="Search"/>
              )}
              <img src={searchToggleLogo}
                   onClick={() => toggleSearchBox(!showSearchBox)}
                   className="search-icon" style={{fill: '#000'}} alt={"search-logo"}/>
              {searchText && (
                <div className="search-card" ref={searchRef}>
                  <div className="search-div">
                      <SearchPage SearchResponse={response?.data}/>
                  </div>
                </div>
              )}
            </div>
            <Link to="/cart">
              <span className="cart-icon"> <img
                src={theme?.headerTheme === 'light' && !navbar ? "/icons/cart-icon-white.svg" : "/icons/cart-icon.svg"}
                alt=""/> </span>
              <CartCountConsumer>
                {(cart_products) => {
                  if (cartCount?.length == null) {
                    return <span className="quantity-in-cart">0</span>;
                  } else {
                    return <span className="quantity-in-cart">{cartCount?.length}</span>;
                  }
                }}
              </CartCountConsumer>
            </Link>

            <div className="account" ref={loginRef}>
              <AccountCircleIcon onClick={handleToggle}
                                 className={theme?.headerTheme === 'light' &&  !navbar ? "account-icon-white" : "account-icon"} style={{margin: "24px 30px"}}/>
              {/*{userName && (<span onClick={handleToggle} className="user-name">{userName}</span>)}*/}
              {showLoginPanel && (
                <div className="login-card">
                  <div className="triangle"/>
                  <div className="login-div">
                    {showLoginForm && <LoginForm onLoginSuccess={onLoginSuccess}
                                                 onLoginFailure={onLoginFailure}
                                                 user={user}
                                                 setUser={setUser}
                    />}
                    {showOTPForm && <OTPForm phoneNo={user.phone_no}
                                             onOTPVerificationSuccess={onOTPVerificationSuccess}
                                             onOTPVerificationFailure={onOTPVerificationFailure}/>}
                  </div>
                </div>
              )}
              {isLoggedIn && showLoginMenu && (
                <div className="home-account-card">
                  <div className="triangle"/>
                  <div className="home-account-div" style={homeAccountDiv}>
                    <Link to={"/user-profile"} style={homeAccountLinks}>
                      <div className="ml-4 mt-4 mb-4">
                        <img src="/icons/profile-icon.svg" className="mr-2" alt=""/>
                        <span>আমার প্রোফাইল</span>
                      </div>
                    </Link>

                    <Link to="favourite-books" style={homeAccountLinks}>
                      <div className="ml-4 mt-4 mb-4">
                        <img src="/icons/fav-list-icon.svg" className="mr-2" alt=""/>
                        <span>প্রিয় তালিকা</span>
                      </div>
                    </Link>

                    <Link style={homeAccountLinks}>
                      <div className="ml-4 mt-4 mb-4">
                        <img src="/icons/order-list-icon.svg" className="mr-2" alt=""/>
                        <span>অর্ডার লিস্ট</span>
                      </div>
                    </Link>

                    {JSON.parse(localStorage.getItem("user_type")) === 100 || JSON.parse(localStorage.getItem("user_type")) === 10 ? (
                      <Link to={"/admin/dashboard"} style={homeAccountLinks} target={"_blank"}>
                        <div className="ml-4 mt-4 mb-4">
                          <img src="/icons/profile-icon.svg" className="mr-2" alt=""/>
                          <span>এডমিন প্যানেল</span>
                        </div>
                      </Link>

                    ) : (
                      <></>
                    )}
                    <Link style={homeAccountLinks}>
                      <div onClick={handleClickLogOut} className="ml-4 mt-4 mb-4">
                        <img src="/icons/sign-out-icon.svg" className="mr-2" alt=""/>
                        <span>সাইন আউট</span>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
export default Header;
