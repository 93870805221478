import React from "react";
import "./aboutus.css";


const AboutUsPage = () => {

  return (
    <>
      <div className="container-fluid about-us-banner">
        <div className="about-us-banner-title ">ABOUT US </div>
      </div>

      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-6">
            <div className="about-us-details">
              <h6>Welcome To</h6>
              <h3>BOOKFLIX</h3>
              <p>BOOKFLIX is an online marketplace for books. Millions of brand modern books, uncommon books, and out-of-print books are advertised for deal through the BOOKFLIX App a from thousands of booksellers around the  entire nation. Readers can discover successes, collectors can discover uncommon books, understudies can discover modern reading material, and treasure seekers can discover long-lost books.</p>
              <p>BOOKFLIX remains a company with a passion for books. Booksellers adore BOOKFLIX for making a difference them to offer books to buyers around the globe – 24 hours a day, 365 days a year. Buyers cherish BOOKFLIX for making a difference them to discover and buy books to read from the endless online BOOKFLIX stock.</p>
            </div>
          </div>
          <div className="col-6 text-center">
            <img src="/images/about-us-image-1.png" height="auto" width="auto" alt=""/>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-6 text-center">
            <img src="/images/about-us-image-2.png" height="auto" width="auto" alt=""/>
          </div>
          <div className="about-us-details">
            <h6>Our</h6>
            <h3>Mission</h3>
            <p>Our mission is to help people find and buy any book to read from any bookseller and our business stretches around the county. The unique inventory of books for sale from booksellers includes the country’s and world’s finest antiquarian books dating back to the 15th century, countless out-of-print gems, millions of signed books, millions of used copies, a vast selection of college textbooks and new books too.</p>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-6">
            <div className="about-us-details">
              <h6>Excellent</h6>
              <h3>Online retailer in Bangladesh</h3>
              <p>
                This service is respected as the most excellent online retailer in Bangladesh for the taking after reasons: <br/><br/>

                (1)  Fast Read new books what are in market. <br/><br/>

                (2) Helpful online shopping puts you only a click faraway from your favorite books within the comfort of your home or office without fighting traffic and crowds. <br/><br/>

                (3) No hidden costs because unlike other online stores BOOKFLIX does not charge extra fees or taxes later after you order. All prices shown on our website include taxes. <br/><br/>

                (4) Secure online payments managed by reputable banks. BOOKFLIX does not receive or retain your credit card data as payments are handled via a secure link direct to the bank portal. <br/><br/>

                BOOKFLIX is the driving Bangladesh bookstore for all your perusing needs with neighborly staffs who are prepared to assist you anytime. In the event that you've got a address or issue you'll mail or call us and get an quick answer. That’s our guarantee! <br/><br/>
              </p>
            </div>
          </div>
          <div className="col-6 text-center">
            <img src="/images/about-us-image-3.png" height="auto" width="auto" alt=""/>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
