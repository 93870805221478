import {useCallback, useState} from 'react';
import {urls} from "../Constants/urls";
import axios from "axios";
import {useErrorHandler} from "./useErrorHandler";
import {toast} from "react-toastify";

export const useResetPassword = () => {
  const errorHandler = useErrorHandler();
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const fetchResetPassword = useCallback((phone_no, otp, new_password, onOTPVerificationSuccess, onOTPVerificationFailure) => {
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = urls.resetPasswordOTP;
      const res = await axios.patch(url, {phone_no, otp, new_password});
      setResponse(prevState => ({...prevState, data: res.data, isLoading: false}));
      toast.success(res.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
      localStorage.setItem('token', JSON.stringify(res.data.token));
      onOTPVerificationSuccess();
    };

    asyncRequest().catch(error => {
      console.log(error.response);
      onOTPVerificationFailure(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
      errorHandler(error, false);

    });
  }, [setResponse]);

  return [response, fetchResetPassword, errorHandler];
};
