import React, {useEffect, useState} from 'react';
import PublisherEditForm from "./publisher-edit-form";
import {Link, useParams} from "react-router-dom";
import {useFetchPublisherDetail} from "../../../../../Hooks/Admin/Publishers/useFetchPublisherDetail";
import './publisherDetails.css';

const PublisherDetailPage = () => {
  const {publisherId} = useParams();
  const [response, fetchPublisherDetail] = useFetchPublisherDetail();
  const [publisher, setPublisher] = useState({});

  useEffect(() => {
    fetchPublisherDetail(publisherId);
  }, [fetchPublisherDetail]);
  useEffect(() => {
    const publisherInfo = response?.data?.publisher;
    setPublisher({
      id: publisherInfo?.id,
      name: publisherInfo?.publisher_name,
      name_bangla: publisherInfo?.publisher_name_bangla,
      description: publisherInfo?.description,
      phone_no: publisherInfo?.phone_no,
      email: publisherInfo?.email,
      address: publisherInfo?.address,
      is_disabled: publisherInfo?.is_disabled
    });
  }, [response]);

  return (
    <>
      <div className={'publisher-info-form'}>
        <Link to={"/admin/publishers"}>
          <i className="fas fa-arrow-left "  style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <PublisherEditForm publisher={publisher} setPublisher={setPublisher}/>
      </div>
    </>

  );
};

export default PublisherDetailPage;
