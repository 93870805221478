import { useCallback, useState } from 'react';
import  axios  from "axios";
import {urls} from "../../../Constants/urls";
import {toast} from "react-toastify";
import {useErrorHandler} from "../../useErrorHandler";

export const useUpdatePublisherDetail = () => {
  const errorHandler = useErrorHandler();
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const fetchPublishersList = useCallback((publisherDetail)=>{
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = `${urls.adminPublisherDetail}/${publisherDetail.id}`;
      const res = await axios.patch(url,publisherDetail,{params: {token: JSON.parse(localStorage.getItem("token"))}});
      setResponse(prevState => ({...prevState, data: res.data.data, isLoading: false}));
      toast.success(res.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
    };

    asyncRequest().catch(error => {
      console.log(error);
      errorHandler(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, fetchPublishersList];
};
