import React, {useEffect, useState} from 'react';
import {Button, TextareaAutosize, TextField} from "@material-ui/core";
import {useSaveCategoryDetail} from "../../../../../Hooks/Admin/Categories/useSaveCategoryDetail";
import {validateMaxLength, validateMinLength, validateRequired, validates} from "../../../../FormValidator/Validator";
import {
  CATEGORY_NAME_BANGLA_MAX_LEN, CATEGORY_NAME_BANGLA_MIN_LEN,
  CATEGORY_NAME_MAX_LEN, CATEGORY_NAME_MIN_LEN,
} from "../../../../../Constants/general";

const CategoryNewForm = (props) => {
  const {category, setCategory} = props;
  const [, saveCategory] = useSaveCategoryDetail();
  const [errors, setErrors] = useState();

  const handleChange = (event) => {
    setCategory((prevValue) => ({...prevValue, [event.target.name]: event.target.value}));
  };

  const inputFieldLabel = {
    margin: "16px 0",
  };

  const saveCategoryDetail = () => {
    saveCategory(category);
  };

  //////// enable/disable submit button ////////
  let buttonClickable = false;
  if (!category.name?.length > 0 || !category.name_bangla?.length > 0 ||  errors.name?.length > 0 || errors.name_bangla?.length > 0) {
    buttonClickable = true;
  }

  useEffect(() => {
    setErrors(
      validates(
        validateRequired('name'),
        validateRequired('name_bangla'),
        validateMaxLength('name', CATEGORY_NAME_MAX_LEN),
        validateMinLength('name', CATEGORY_NAME_MIN_LEN),
        validateMaxLength('name_bangla', CATEGORY_NAME_BANGLA_MAX_LEN),
        validateMinLength('name_bangla', CATEGORY_NAME_BANGLA_MIN_LEN),
      )(category, {}),
    );
  }, [category]);
  return (
    <>
      <h3>Create New Category</h3>
      <div className={'category-form-main-div'}>
        <br/>
        <label style={inputFieldLabel}>Category Name: <span style={{color: "red"}}>*</span></label>
        <TextField
          name={'name'}
          id={'category-name'}
          value={category?.name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.name)}
          helperText={(errors?.name)}
        />
        <label style={inputFieldLabel}>Category Name Bangla: <span style={{color: "red"}}>*</span></label>
        <TextField
          name={'name_bangla'}
          id={'category-name-bangla'}
          value={category?.name_bangla}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.name_bangla)}
          helperText={(errors?.name_bangla)}
        />
        <label style={inputFieldLabel}>Category Description: </label>
        <TextareaAutosize
          style={{ height: 100 }}
          name={'description'}
          id={'category-description'}
          value={category?.description}
          className={'form-control'}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        <label style={inputFieldLabel}>Is Enabled?: </label>
        <input type={'checkbox'} id={'product-isbn'} checked={category?.is_enabled} className={''}
               onChange={(event) => {
                 setCategory((prevValue) => ({...prevValue, is_enabled: event.target.checked}));
               }}/>
        <br/>
        {JSON.parse(localStorage.getItem("user_type")) === 100 ? (
          <>
            <label style={inputFieldLabel}>Show on Public?: </label>
            <input type={'checkbox'} id={'product-isbn'} checked={category?.show_on_public} className={''}
                   onChange={(event) => {
                     setCategory((prevValue) => ({...prevValue, show_on_public: event.target.checked}));
                   }}/>
            <br/>
            <label style={inputFieldLabel}>Is Featured?: </label>
            <input type={'checkbox'} id={'product-isbn'} checked={category?.is_featured} className={''}
                   onChange={(event) => {
                     setCategory((prevValue) => ({...prevValue, is_featured: event.target.checked}));
                   }}/>
            <br/>
          </>
        ) : (
          <></>
        )}
        <label style={inputFieldLabel}>Category Image Url: </label>
        <TextField
          name={'image'}
          type={'text'}
          id={'category-image-url'}
          value={category?.image}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        <br/><br/>
        <Button className={'mt-3 d-block'} onClick={saveCategoryDetail} variant="contained"
                disabled={buttonClickable}
                color="primary">Save</Button>
      </div>
    </>
  );
};

export default CategoryNewForm;
