import React from "react";
import "./authorpreview.css";
import {Link} from 'react-router-dom';

const AuthorPreview = (props) => {
  return (
    <>
      {props.authorsShowType === 1 ? (
        <>
          <div className="r-author-image">
            <div>
              <Link to={`/author/${props.id}`}>
                <img className="authors-image" src={props.image_url ?? "/images/default-author-icon.png"}
                     onError={(e) => {
                       e.target.onerror = null;
                       e.target.src = "/images/default-author-icon.png";
                     }}
                     width="200px" height="300px" alt=""/>
              </Link>
              <div className="icon-like-author">
                <img src="/icons/like.svg" alt=""/>
              </div>
              <div className="authors-title">{props.name_bangla}</div>
              {/*<div className="no-of-books"> {props}</div>*/}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row author-rows">
              <div className="col-2">
                <img className="authors-image-list" src={props.image_url ?? "/images/default-author-icon.png"}
                     onError={(e) => {
                       e.target.onerror = null;
                       e.target.src = "/images/default-author-icon.png";
                     }} width="200px" height="300px" alt=""/>
              </div>
              <div className="col-9">
                <div className={'mb-3 title-authors'}>
                  {props.name_bangla}
                </div>
                {/*    /!*<div className="no-of-books"> {props}</div>*!/*/}
                <div className="details-authors text-justify">
                  <p>
                    {props.description}
                  </p>
                </div>
                <div className="details-button-author">
                  <Link to={`/author/${props.id}`}>
                    বিস্তারিত দেখুন...
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AuthorPreview;
