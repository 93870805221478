import React from "react";
import "./privacy-policy.css";


const PrivacyPolicyPage = () => {

    return (
        <>
            <div className="container-fluid privacy-policy-banner">
                <div className="privacy-policy-banner-title ">Privacy Policy</div>
            </div>
            <div className="container-fluid ml-5 mr-5">
                <div className="row ml-5 mr-5">
                    <div className="col-12">
                        <div className="privacy-policy-details">
                            <p>
                                We welcome you to <b>BOOKFLIX</b> and thank you for your support in making <b>BOOKFLIX</b> a leading story-telling platform.
                            </p>
                            <p>
                                We encourage you to read through our Privacy Policy to understand all the aspects of how <b>BOOKFLIX</b> collects and uses
                                data relating to users of the platform. It is our endeavor to be transparent about our practices and demonstrate the
                                respect we have for privacy of our users’ data. If you do not agree with these practices, you are advised not to use
                                the <b>BOOKFLIX</b> app and/or website.
                            </p>
                            <br />
                            {/**************Privacy Policy***********************/}
                            <h4>Privacy Policy</h4>
                            <br />
                            <p>
                                The Privacy Policy, along with the Terms of Use, governs the handling of the information of the users of BOOKFLIX
                                Ltd.’s. (“Company”), ‘BOOKFLIX’ website (https://bookflix.com.bd/) (“Website”), and the ‘BOOKFLIX’ mobile based
                                application available on Android and iOS platform (“Application”) by any.  The Privacy Policy and the Terms of
                                Use apply to all the visitors, customers, and users (hereinafter collectively referred to as "your" "you" or "User").
                            </p>
                            <p>
                                The Company facilitates a User to read and/or upload literary/audio works (such as books, poems, articles,
                                comics etc. including cover images) and audio (collectively referred to as “Published Work”), read/listen
                                to Published Work and literary/audio works published by Company (“Company Content”), in various languages
                                and upload comments, reviews on such literary works of others the same or and communicate with the Company
                                and/or other Users through chats (“Inputs”), on the Website/Application (“Services”). Published Work and Company
                                Content shall together be referred to as “Content”.
                            </p>
                            <p>
                                This Privacy Policy governs the manner in which BOOKFLIX collects, uses, maintains and discloses
                                information collected from users (each, a “User”) of the Website.
                            </p>
                            <p>
                                This Privacy Policy is a part of and is to be read with the Terms of Use. Please read this Privacy Policy carefully
                                before accessing or using the service to understand our views and practices regarding your identity information and
                                how we will settle it. Here it is clear that by accessing/using the Website or downloading the Application,
                                you confirm that you have read, understood and accepted the terms of this Policy set out here. You also consent
                                and permit to the collection, use, storage, processing and disclosure of your personal information in the manner
                                set out in this Policy to provide you with great User experience. If you do not agree with the same, please stop
                                using the Website/Application immediately.
                            </p>
                            <p>
                                Subject to the requirements of applicable law of the land, we strive to provide a consistent set of privacy practices.
                                Please note that this Policy may be amended from time to time to comply with any applicable laws, data privacy
                                requirements, coverage and technology security advancements and such modifications shall be effective immediately
                                upon posting of the modified copy.
                            </p>
                            <br />

                            <b>What information does Company collect?</b>

                            <p>
                                For Company to offer its Services to Users and to continuously improve the Users’ experience, Company collects
                                certain information which constitute personally identifiable information (information which can be used to
                                identify an individual) and non-personally identifiable information (information which cannot directly identify
                                an individual) (together ‘User Information’) as mentioned below:
                            </p>

                            <table className="privacy-policy-table">
                                <tr>
                                    <th>Type of Information</th>
                                    <th>Includes</th>
                                </tr>
                                <tr>
                                    <td><b>Registration/Log in Data</b></td>
                                    <td>Any external, biological or physical information or any other information which singly or jointly
                                        can identify a person or a system, such as name, photograph, address, date of birth, mother’s name,
                                        father’s name, signature, national identity card, birth and death registration number, finger print,
                                        passport number, bank account number, driving license, e-TIN number, electronic or digital signature,
                                        username, credit or debit card number, voice print, retina image, iris image, DNA profile, security
                                        related question, any other identification which are available for advance technology,, Email Address/
                                        Facebook or Google or Apple log-in details along with profile details which are public or can be shared
                                        as per User’s privacy settings on these platforms.
                                        This also applies to Users during submission of Published Work through contests declared by the Company.
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Usage Data</b></td>
                                    <td>Inputs by Users on the Website/Application
                                        Data regarding pages or profiles visited, time spent on a page, navigation through the portal,
                                        location, language preference, search actions, participation in contests, interaction with other
                                        Users, including time and date of all such actions.
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Device Data</b></td>
                                    <td>A device identifier token generated for each Android/iOS User, the make of the phone, browser
                                        version and type, IP Address</td>
                                </tr>
                                <tr>
                                    <td><b>Contact List/ Friends List</b></td>
                                    <td>Where Users agree to share the phone numbers of User’s contacts to refer the Website/Application,
                                        Company collects and uses them solely for referral and does not take any other action with respect
                                        to such information. The Users whose contacts have been referred will have an option to have their
                                        details removed from the database of the Company by writing in to info@bookflix.com.bd.
                                        If agreed by a User, Company may collect Facebook identities of a User’s friends on Facebook when
                                        logging in through Facebook to the Website/Application. Company may use such information for increasing
                                        engagement between its Users on the Website/Application.
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Payment Data</b></td>
                                    <td>Billing information, credit card details, payment or banking information</td>
                                </tr>
                                <tr>
                                    <td><b>Customer support</b></td>
                                    <td>Information provided during request for User support to the Company’s executives.</td>
                                </tr>

                            </table>

                            <br />

                            <b>Authorized collection, use, processing, storage of User Information</b>

                            <p>
                                Whenever you log in to our Website/Application, we automatically collect the following information:
                            </p>
                            <p>
                                <ol type="1">
                                    <li>Communication information: Contact lists, SMS logs, information stored on your device.</li>
                                    <li>Sensitive information: GPS location, IP address.</li>
                                    <li>Collect and monitor specific information about your device including your hardware model,
                                        operating system and version, unique device identifier, user profile information, Wi-Fi information,
                                        mobile network information to uniquely identify the devices and ensure that unauthorized devices
                                        are not able to act on your behalf helping us prevent fraud.</li>
                                    <li>Your uses of any other third-party application on the device; and</li>
                                    <li>Details of your use of any of our Website/Application; including, but not limited to location
                                        data and other communication data.</li>
                                </ol>
                            </p>
                            <p>
                                Upon registering as a User in our Website/Application and providing us with your User Information, you
                                consent to us collecting, using, processing and storing your User Information.
                            </p>
                            <br />

                            <b>Your Consent</b>
                            <p>
                                Before we collect your User Information, we will provide you with adequate information so you can make an informed decision whether to visit our Website/Application and whether during your registration, you wish to voluntarily provide any User Information to us.
                            </p>
                            <p>
                                Once you expressly accept the Privacy Policy along with the Terms of Use, you agree to our collection, storage, use and disclosure of your User Information as described in this Privacy Policy. Therefore, you hereby give your express acknowledgment and informed consent to us to use your User Information.
                            </p>
                            <br />

                            <b>What does Company use the collected User Information for?</b>
                            <p>
                                Company collects, uses, processes and stores the User Information to:
                            </p>
                            <p>
                                <ul>
                                    <li>Enable and facilitate usage of the Website/Application including enforcing the Company’s
                                        Terms of Use</li>
                                    <li>Enable and facilitate usage of the Website/Application including enforcing the Company’s
                                        Terms of Use</li>
                                    <li>To send mandatory and opted notifications to Users</li>
                                    <li>To communicate with the User</li>
                                    <li>For payment and billing when a user purchases virtual currency to access the Services and/or
                                        any other offerings by the Company which is not free</li>
                                    <li>Improve the functioning of the Website/Application and the Services (such as introducing new
                                        features and enhancing security measures to protect Users and Published Works)</li>
                                    <li>Improve the User experience by customization, personalization and optimization</li>
                                    <li>Managing the Website/Application which includes troubleshooting, analysis, conducting surveys,
                                        understanding the nature of Users, etc.</li>
                                    <li>Building communities amongst the Users </li>
                                    <li>We may use the email address to send User information and updates pertaining to their order.
                                        It may also be used to respond to their inquiries, questions, and/or other requests.</li>
                                    <li>Send you personalized emails or secure electronic messages pertaining to your interests,
                                        including news, announcements, reminders, and opportunities.</li>
                                    <li>Send you relevant offers and informational materials pertaining to your interests.</li>
                                </ul>
                            </p>
                            <br />

                            <h4>Children’s Privacy</h4>
                            <p>
                                These Services are not designed for and are not marketed to anyone under the age of 18. We do not knowingly collect
                                personally identifiable information from children under 18. In the case we discover that a child under 18 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian or relevant person having authority to make decisions on behalf of the child and you are aware that your child has provided us with personal information, please contact us so that we will be able to make necessary actions.
                            </p>
                            <br />

                            <b>Can any third party access the User Information?</b>
                            <p>
                                Company will never share, sell or rent any User Information to any third party. We do not sell, trade, or rent Users
                                personal identifiable information to others. We may share generic aggregated demographic information not linked to any
                                personal identifying information regarding visitors and users with our business partners, trusted affiliates and
                                advertisers for the purposes outlined above.
                            </p>
                            <p>
                                <ol type="1">
                                    <li><b>Special Circumstances</b>: Company will disclose personally identifiable information about a User
                                        <ol type="i">
                                            <li>As and when required by law or litigation</li>
                                            <li>If Company determines that such a step is essential for national security, law enforcement,
                                                or other issues of public importance</li>
                                            <li>For enforcement of its Terms of Use</li>
                                            <li>In case of fraud, security or technical issues</li>
                                            <li>with any member of our group, which may be our subsidiaries, affiliates.</li>
                                        </ol>
                                    </li>
                                    <li><b>Corporate Restructuring</b>: Company reserves the right to transfer User Information to another party
                                        as a result of merger, acquisition, or sale of all or a portion of Company's assets to any third
                                        party.</li>
                                    <li><b>User Published Material</b>: Company enables Users to interact with each other in its community
                                        building endeavors between authors and readers. Therefore, Users’ names, comments, likes, etc.
                                        are public and can be viewed by other Users. Users must ensure they do not put up any Inputs on
                                        the Website/Application which they do not intend to make public.</li>
                                </ol>
                            </p>
                            <br />

                            <b>How is the User Information collected and what are the opt-out options?</b>
                            <p>User Information is collected by Company primarily in the following manner:</p>
                            <p>
                                <ol type="1">
                                    <li><b>User-provided Information</b>: Details provided by a User while logging-in/registering on the Website/Application
                                        and while providing Inputs on the Website/Application.</li>
                                    <li><b>Collected through Cookies</b>: Cookies are small files placed locally on the browser through which the Website
                                        is being accessed. Our Site may use "cookies" to enhance User experience. The user's web browser places
                                        cookies on their hard drive for recordkeeping purposes and sometimes to track information about them.
                                        The user may choose to set their web browser to refuse cookies, or to alert you when cookies are
                                        being sent. If they do so, note that some parts of the Site may not function properly.</li>
                                    <li><b>API Calls</b>: API Calls consists of data generated when a User performs an activity on the
                                        Website/Application such as navigating to different pages, clicking on buttons, reading
                                        content, etc. This data is collected and used by Company and may be shared with authorized
                                        third parties as described in this Privacy Policy.</li>
                                </ol>
                            </p>
                            <br />

                            <b>What are the rights of Users with respect to their User Information?</b>
                            <p>
                                <ol type="1">
                                    <li><b>Registration</b>: Users have the option to not register on the Website/Application if they do not want
                                        to share the mandatory personally identifiable information required to do so. Their ability to use the
                                        Website/Application may be restricted as reasonably determined by Company.</li>
                                    <li><b>Modification or Deletion</b>: Users may modify or delete their profile details from their account
                                        settings on the Website/Application. Users are encouraged to keep their information up-to-date.</li>
                                    <li><b>Deletion of Profile</b>: Users may ask for deletion of their profile and their personally identifiable
                                        information will be deleted along with any content they may have published on the Website/Application.
                                        However, some fragments of the User Information may still be available on the internet. Further,
                                        all history of the User will remain with Company for as long as it is necessary.</li>
                                    <li><b>Notifications</b>: Company would like to engage with Users through notifications for suggested reading
                                        etc. via the Website/Application and email. A User can set the frequency of such notifications through
                                        their account settings or completely opt-out of the same. However, notifications regarding the User’s
                                        account and the Website/Application itself will continue to be sent.</li>
                                    <li><b>Opt-out</b>: If a User desires Company to cease usage of his User Information on the Website/Application
                                        for any of the purposes mentioned herein, User may write to info@bookflix.com.bd. Company will endeavor
                                        to assist Users with its requests and fulfil the requests. However, any such action may adversely
                                        affect the User experience on the Website/Application.</li>
                                </ol>
                            </p>
                            <br />

                            <h4>Changes to this Privacy Policy</h4>
                            <p>
                                Company may update and revise this Privacy Policy from time to time. Users are advised to periodically check this
                                page to stay informed about changes to this Privacy Policy. When we do, we will post a notification on the main
                                page of our Site and send you an email. We encourage Users to frequently check this page for any changes to stay
                                informed about how we are helping to protect the personal information we collect. You acknowledge and agree that
                                it is your responsibility to review this privacy policy periodically and become aware of modifications.
                            </p>
                            <p>
                                If a User disagrees to any of the changes to the Privacy Policy, User shall refrain from using or accessing the
                                Website/Application/Services. User’s continued use following the posting of the revised Policy shall indicate
                                their acceptance and acknowledgement of the changes and the User will be bound by it.
                            </p>
                            <br />

                            <h4>Protection of User Data</h4>
                            <p>
                                We respect your privacy. The User Information that we collect from you may be transferred to, and stored at,
                                a destination outside your country of origin or residence (as applicable). It may also be processed by our
                                staff operating outside your country of origin or residence (as applicable). These staff members may be engaged
                                in the fulfillment of your requests on the service Website/Application subject to your explicit consent which
                                you are duly authorizing by accepting this Privacy Policy.
                            </p>
                            <p>
                                By submitting your User Information, you agree to the collection, transfer, storing or processing of your
                                personal data and identity information in the manner set out above. We will take all steps reasonably
                                necessary to ensure that your data is treated, stored and processed securely and in accordance with this
                                Privacy Policy.
                            </p>
                            <p>
                                Furthermore, we only give you or your authorized representative access to your User Information.
                            </p>
                            <br />

                            <h4>Corrections, Updates, and removal of User Information</h4>
                            <p>
                                You will have a way to access or correct your User Information, unless there are practical, contractual
                                and legal reasons that would prevent us from doing so. We would need ample time to provide or correct
                                that information for you.
                            </p>
                            <br />

                            <h4>Disclaimer</h4>
                            <p>
                                It must be noted that no method of transmission over the internet, or method of electronic storage is
                                100% secure and reliable, and we cannot guarantee its absolute security. We will not be liable in the
                                event that your information is stolen through hacking or other illegal means.
                            </p>
                            <br />

                            <h4>Your Acceptance of These Terms</h4>
                            <p>
                                By using this Site, you signify your acceptance of this policy. If you do not agree to this policy,
                                please do not use our Site. Your continued use of the Site following the posting of changes to this
                                policy will be deemed your acceptance of those changes.
                            </p>
                            <br />

                            <h4>Contact</h4>
                            <p>
                                In case of any questions / doubts / legal query about this Privacy Policy, Users may contact BOOKFLIX at:
                                info@bookflix.com.bd
                            </p>
                            <br />

                            <h4>Conflict</h4>
                            <p>
                                In case of any conflict arising in the interpretation of the Privacy Policy in English and any other
                                language as it may be made available on the Website/Application, the terms of the English version shall
                                prevail.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicyPage;
