import React, {useEffect, useState} from 'react';
import {Button, TextField} from "@material-ui/core";
import {useFetchUsersList} from "../../../../Hooks/Admin/Users/useFetchUsersList";
import {Link, useHistory} from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import './users.css';



const UsersPage = () => {
  const PER_PAGE = 10;
  const history = useHistory();
  const [data, fetchUsers] = useFetchUsersList();
  const [page, setPage] = useState(1);
  const users = data?.data.users ?? [];
  const count = data?.data.total_pages ?? 10;
  const [userSearchText, setUserSearchText] = useState('');
  const [orderSorting, toggleOrderSorting] = useState(true);
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };
  const handleSorting = () => {
    toggleOrderSorting(!orderSorting);
  };

  let sortIcon;
  let direction;
  if(orderSorting){
     sortIcon = "fas fa-sort-numeric-up";
     direction = "desc";
  }else {
     sortIcon = "fas fa-sort-numeric-down";
     direction = "asc";
  }

  useEffect(() => {
    fetchUsers(page, PER_PAGE, userSearchText, direction);
  }, [fetchUsers, page, userSearchText, direction]);

  return (
    <>

      <div className={'user-page container'}>
        <Link to={"/admin/user/new"} style={{textDecoration: "none", fontSize: 14}}>
          <AddCircleOutlineIcon style={{margin: "20px 10px 20px 0"}}/>
          <span>Add New User</span>
        </Link>
        <div className="users-list-header">
          <h1>Users</h1>
          <span style= {{fontSize: 20}}>
            sort:
            <button onClick={handleSorting}  style={{marginLeft: 10}}>
              <i className={sortIcon}/>
            </button>
          </span>
        </div>
        <TextField
          className={'mb-3'}
          placeholder={'Search User'}
          name={''}
          id={''}
          variant="outlined"
          required
          value={userSearchText}
          onChange={(event) => {
            setUserSearchText(event.target.value);
          }}
          fullWidth
        />
        <table className={'table'}>
          <thead className={"thead-dark"}>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone No.</th>
            <th>Email</th>
            <th>Is Verified</th>
            <th></th>
          </tr>
          </thead>
          {users.length > 0 ? (
            <>
          {users.map((user) => {
            return (
              <tr>
                <td>{user.first_name}  </td>
                <td>{user.last_name}</td>
                <td>{user.phone_no}</td>
                <td>{user.email}</td>
                <td className={'text-center'}>{user.is_verified ? 'Yes' : 'No'}</td>
                <td><Button color={"primary"} variant="contained" onClick={()=>{
                  history.push(`/admin/users/${user.id}`);
                }}>Detail</Button></td>
              </tr>
            );
          })}
            </>
          ) : (<>
            <tr>
              <td colSpan={4}>
                <div className={'text-center h3'}>No User Found With Search Query '{userSearchText}'</div>
              </td>
            </tr>
          </>)}
        </table>
        <Pagination count={count} page={page} shape="rounded" onChange={handlePaginationChange}/>
      </div>
    </>
  );
};

export default UsersPage;