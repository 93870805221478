import {useCallback, useState} from 'react';
import {urls} from "../Constants/urls";
import axios from "axios";
import {useErrorHandler} from "./useErrorHandler";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

export const useChangePassword = () => {
  const errorHandler = useErrorHandler();
  const history = useHistory();
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const fetchChangePassword = useCallback(( old_password, new_password) => {
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = `${urls.changePassword}`;
      const res = await axios.patch(url, {old_password: old_password, new_password: new_password}, {params: {token: JSON.parse(localStorage.getItem("token"))}});
      setResponse(prevState => ({...prevState, data: res.data, isLoading: false}));
      if(res.data.status === 200){
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
        setTimeout(()=>{
          history.push('/user-profile');
        }, 1000);
      }else{
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }
    };

    asyncRequest().catch(error => {
      console.log(error.response);
      setResponse(prevState => ({...prevState, isLoading: false}));
      errorHandler(error, false);

    });
  }, [setResponse]);

  return [response, fetchChangePassword, errorHandler];
};
