import React, {useEffect, useRef, useState} from 'react';
import "./adminsidedrawer.css";
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CategoryIcon from '@material-ui/icons/Category';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import {List as CatelogIcon} from '@material-ui/icons';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {Link, useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#3387CB"

  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#3387CB",
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerIcon: {
    color: "#ffffff",
    marginRight: 0,
    marginLeft: 12,
    marginTop: 12,
    marginBottom: 12,
  },
  collapseDrawerIcon: {
    color: "#ffffff",
    marginTop: 12,
    marginBottom: 12,
    marginRight: 12,
    marginLeft: 70,
  },
  expandButtonStyle: {
    color: "#ffffff"
  },
}));

export default function ClippedDrawer(props) {
  const {user} = props;
  const classes = useStyles();
  const [showLoginMenu, toggleLoginMenu] = useState(false);
  const history = useHistory();
  const loginRef = useRef(null);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openConfirmationCollapse, setConfirmationOpenCollapse] = useState(false);
  const homeAccountLinks = {
    textDecoration: "none",
    color: "rgb(84, 84, 84)"
  };
  const handleToggle = () => {
    toggleLoginMenu(!showLoginMenu);
  };
 const handleCollapse = () => {
   setOpenCollapse(!openCollapse);
 };
 const handleConfirmationCollapse = () => {
   setConfirmationOpenCollapse(!openConfirmationCollapse);
 };

  const handleClickLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_type");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    handleToggle();
    history.push('/');
    toast.success("Successfully Logged Out ", {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 4000
    });
  };

  ////// hide javascript event listener /////////
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  /////// click event funtion //////
  const handleClickOutside = event => {
    if (loginRef.current && !loginRef.current.contains(event.target)) {
      toggleLoginMenu(false);
    }
  };
  const location = useLocation();
  let CustomListItem = ({ to, primary, iconName }) => (
    <ListItem
      button
      component={Link}
      to={to}
      selected={to === location.pathname}
    >
      <ListItemIcon>{iconName}</ListItemIcon>
      <ListItemText primary={primary} className= "drawer-list-links"/>
    </ListItem>
  );

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <img
            style={{margin: "0 50px"}}
            height=" 53px"
            width="133px"
            alt="BookFlix Logo"
            src="/images/bookflix-small-white.png"
            alt=""/>
          <Typography variant="h6" noWrap>
            Admin Dashboard
          </Typography>
          <div style={{margin: "0 100px 0 auto"}}>
            {/*<MailOutlineIcon style={{margin: "0"}}/>*/}
            {/*<NotificationsNoneIcon/>*/}
            <AccountCircleIcon ref={loginRef} style={{margin: "0", cursor: "pointer"}} onClick={handleToggle}/>
            <span style={{marginLeft: "12px"}}>{user.first_name} </span>
            {showLoginMenu && (
              <div className="admin-home-account-card">
                <div className="admin-home-triangle"/>
                <div className="admin-home-account-div">
                  <Link to={"/user-profile"} style={homeAccountLinks}>
                    <div className=" ml-4 mt-4 mb-4">
                      <img src="/icons/profile-icon.svg" className="mr-2" alt=""/>
                      <span>আমার প্রোফাইল</span>
                    </div>
                  </Link>
                  <Link to={"/"} style={homeAccountLinks} target={"_blank"}>
                    <div className="ml-4 mt-4 mb-4">
                      <img src="/icons/profile-icon.svg" className="mr-2" alt=""/>
                      <span>মূল সাইট</span>
                    </div>
                  </Link>
                  <Link style={homeAccountLinks}>
                    <div onClick={handleClickLogOut} className="ml-4 mt-4 mb-4">
                      <img src="/icons/sign-out-icon.svg" className="mr-2" alt=""/>
                      <span>সাইন আউট</span>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>

        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar/>
        <div className={classes.drawerContainer}>
          <List>
            <CustomListItem to="/admin/dashboard" primary="Dashboard" iconName={<ShowChartIcon className={classes.drawerIcon}/>} />
            <ListItem button onClick={handleCollapse}>
              <CatelogIcon className={classes.drawerIcon} />
              <ListItemText primary="Catelog" className="collapsable-drawer"  />
              {openCollapse ?  <ExpandLess  className={classes.expandButtonStyle}/> : <ExpandMore className={classes.expandButtonStyle}/>}
            </ListItem>
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <List component= "div" disablePadding>
                <CustomListItem to="/admin/products" primary="Products" iconName={<LibraryBooks className={classes.collapseDrawerIcon}/>} />
                <CustomListItem to="/admin/authors" primary="Authors" iconName={<PeopleAltIcon className={classes.collapseDrawerIcon}/>} />
                <CustomListItem to="/admin/categories" primary="Categories" iconName={<CategoryIcon className={classes.collapseDrawerIcon}/>} />
                <CustomListItem to="/admin/publishers" primary="Publishers" iconName={<LocalLibraryIcon className={classes.collapseDrawerIcon}/>} />
              </List>
            </Collapse>
          <Divider style={{height: 3}}/>
          {user.user_type === "admin" ? (
            <>
              <CustomListItem to="/admin/users" primary="Users" iconName={<PeopleAltIcon className={classes.drawerIcon}/>} />
              <ListItem button onClick={handleConfirmationCollapse}>
                <CatelogIcon className={classes.drawerIcon} />
                <ListItemText primary="Verification" className="collapsable-drawer"  />
                {openConfirmationCollapse ?  <ExpandLess  className={classes.expandButtonStyle}/> : <ExpandMore className={classes.expandButtonStyle}/>}
              </ListItem>
              <Collapse in={openConfirmationCollapse} timeout="auto" unmountOnExit>
                <List component= "div" disablePadding>
                  <CustomListItem to="/admin/products-to-confirm" primary="Confirm Product" iconName={<LibraryBooks className={classes.collapseDrawerIcon}/>} />
                  <CustomListItem to="/admin/review-products-pending" primary="Confirm Reviews" iconName={<LibraryBooks className={classes.collapseDrawerIcon}/>} />
                </List>
              </Collapse>
            </>
          ) : (<></>)}
            <CustomListItem to="/admin/orders" primary="Orders" iconName={<LibraryBooks className={classes.drawerIcon}/>} />
          </List>
        </div>
      </Drawer>
      <main className={classes.content} style={{marginTop: '50px'}}>
        <div className={'container-fluid'}>{props.children}</div>
      </main>
    </div>
  );
}
