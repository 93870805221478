import React, {useState} from "react";


const StarRatingPreview = (props) => {
  const {
    value,
    type,
    inactiveStar,
    size,
    activeStar,
    onChange
  } = props;

  const handleChange = (value) => {
    if(type === "dynamic"){
      onChange(value + 1);
    }
  };


  return (
    <>
      {[...Array(5)].map((star, index) => {
        let src = inactiveStar;
        if (index <  Math.round(value)) {
          src = activeStar;
        }
        return (
          <span key={index}
                onClick={()=>handleChange(index)}
                style={{ fontSize: size, cursor: "pointer"}}>
            <img style={{margin: 3}} src={src} alt=""/>
          </span>

        );
      })}
    </>
  );
};

export default StarRatingPreview;
