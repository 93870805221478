import React, {useEffect, useState} from 'react';
import {Button, TextField, Grid, makeStyles} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import {useFetchOrdersList} from "../../../../Hooks/Admin/Orders/useFetchOrderList";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Pagination from "@material-ui/lab/Pagination/Pagination";
import './order.css';
import Modal from "@material-ui/core/Modal";
import OrderDetail from "./OrderDetail";


const getModalStyle = () => {
  const top = 65;
  const left = 65;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: "70%",
    height: "884px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
}));


const OrderPage = () => {
  const PER_PAGE = 10;
  const history = useHistory();
  const [data, fetchOrders] = useFetchOrdersList();
  const [page, setPage] = useState(1);
  const orders = data?.data?.orders ?? [];
  const count = data?.data.total_pages ?? 10;
  const [orderNoSearchText, setOrderNoSearchText] = useState('');
  const [orderNameSearchText, setOrderNameSearchText] = useState('');
  const [orderPhoneNoSearchText, setOrderPhoneNoSearchText] = useState('');
  const [orderFromDate, setOrderFromDate] = useState('');
  const [orderToDate, setOrderToDate] = useState('');
  const [orderSorting, toggleOrderSorting] = useState(true);
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [orderID, setOrderID] = useState();
  const handleOpen = (orderId) => {
    setOpen(true);
    setOrderID(orderId);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handleSorting = () => {
    toggleOrderSorting(!orderSorting);
  };

  let sortIcon;
  let direction;
  if(orderSorting){
     sortIcon = "fas fa-sort-numeric-down";
     direction = "asc";
  }else {
     sortIcon = "fas fa-sort-numeric-up";
     direction = "desc";
  }
  useEffect(() => {
    fetchOrders(page, PER_PAGE, orderNoSearchText, orderNameSearchText, orderPhoneNoSearchText, orderFromDate,orderToDate, direction);
  }, [fetchOrders, page, orderNoSearchText, orderNameSearchText, orderPhoneNoSearchText, orderFromDate, orderToDate, direction]);

  return (
    <>
      <div className="order-page container">
        <Link to={"/admin/order/new"} style={{textDecoration: "none", fontSize: 14}}>
          <AddCircleOutlineIcon style={{margin: "20px 10px 20px 0"}}/>
          <span>Add New Order</span>
        </Link>
        <div className="order-list-header">
          <h1>Orders</h1>
          <span style= {{fontSize: 20}}>
            sort:
            <button onClick={handleSorting}  style={{marginLeft: 10}}>
              <i className={sortIcon}/>
            </button>
          </span>
        </div>
        <div className="mb-3 search-section">
          <Grid container spacing={1}>
            <Grid item >
              <TextField
                className={'search-fields'}
                placeholder={'Order No'}
                type = {'number'}
                name={''}
                id={''}
                variant="outlined"
                required
                value={orderNoSearchText}
                onChange={(event) => {
                  setOrderNoSearchText(event.target.value);
                }}
              />
            </Grid>
            <Grid item >
              <TextField
                className={'search-fields'}
                placeholder={'Customer Name'}
                name={''}
                id={''}
                variant="outlined"
                required
                value={orderNameSearchText}
                onChange={(event) => {
                  setOrderNameSearchText(event.target.value);
                }}
              />
            </Grid>
            <Grid item >
              <TextField
                className={'search-fields'}
                placeholder={'Customer Phone No'}
                name={''}
                id={''}
                variant="outlined"
                required
                value={orderPhoneNoSearchText}
                onChange={(event) => {
                  setOrderPhoneNoSearchText(event.target.value);
                }}
              />
            </Grid>
            <Grid item >
              <TextField
                className={'search-fields'}
                type = {'date'}
                name={''}
                id={''}
                variant="outlined"
                required
                value={orderFromDate}
                onChange={(event) => {
                  setOrderFromDate(event.target.value);
                }}
              />
            </Grid>
            <Grid item >
              <TextField
                className={'search-fields'}
                type = {'date'}
                name={''}
                id={''}
                variant="outlined"
                required
                value={orderToDate}
                onChange={(event) => {
                  setOrderToDate(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <table className={'table'}>
          <thead className={"thead-dark"}>
          <tr>
            <th className="text-center">Order No</th>
            <th className="text-center">User Name</th>
            <th className="text-center">User Phone No</th>
            <th></th>
          </tr>
          </thead>
          {orders.length > 0 ? (
            <>
              {orders.map((order) => {
                return (
                  <tr key={order.id} onDoubleClick={() => handleOpen(order.id)} style={{cursor: "pointer"}}>
                    <td className="text-center">{order.id}</td>
                    <td className="text-center">{order.user_name}</td>
                    <td className="text-center">{order.user_phone_no}</td>
                    <td className={"text-center"}><Button color={"primary"} variant="contained" onClick={() => handleOpen(order.id)}>Detail</Button></td>
                  </tr>
                );
              })}

            </>
          ) : (<>
            <tr>
              <td colSpan={4}>
                <div className={'text-center h3'}>No Order Found With Search Query '{}'</div>
              </td>
            </tr>
          </>)}
        </table>
        <Pagination count={count} page={page} shape="rounded" onChange={handlePaginationChange}/>
      </div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div  style={modalStyle} className={classes.paper}>
          <div className="order-modal-cross-button">
            <i className="fa fa-times" aria-hidden="true" onClick={handleClose} />
          </div>
            <OrderDetail orderId={orderID}/>
        </div>
      </Modal>
    </>
  );
};

export default OrderPage;