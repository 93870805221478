import { useCallback, useState } from 'react';
import  axios  from "axios";
import {urls} from "../../../Constants/urls";
import {toast} from "react-toastify";
import { useHistory } from "react-router-dom";

export const useSaveCategoryDetail = () => {
  let history = useHistory();
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const fetchCategoryDetail = useCallback((categoryDetail)=>{
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = urls.adminCategoryDetail;

      const res = await axios.post(url,categoryDetail,{params: {token: JSON.parse(localStorage.getItem("token"))}});
      setResponse(prevState => ({...prevState, data: res.data.data, isLoading: false}));
      toast.success(res.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
      await history.push(`/admin/categories/${res.data.data.category.id}`);
    };

    asyncRequest().catch(error => {
      console.log(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, fetchCategoryDetail];
};
