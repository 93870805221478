import React from "react";
import "./multiselectaddeddata.css";
import { Button} from "@material-ui/core";

const MultiSelectAddedData = (props) => {
  const {selectedData, selectedDataLabel, removeData} = props;
  const contentButton = {
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "10px",
    color: "#FFFFFF",
    backgroundColor: "#1780D7",
    padding: "3px 0px 3px 10px"
  };
  const handleRemove = () => {
      removeData(selectedData);
  };

  return (
    <>
      <Button role="checkbox" aria-checked="false" aria-label=""
              style={contentButton}
              className="multiselect-added-data-button"
              type="button"><span className="">{selectedData[selectedDataLabel]} </span>
        <i onClick={handleRemove} className="fas fa-times"/>
      </Button>
    </>
  );
};

export default MultiSelectAddedData;