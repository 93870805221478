import React, {useEffect, useState} from 'react';
import './authorDetails.css';
import AuthorNewForm from "./author-new-form";
import {Link} from "react-router-dom";


const AuthorNewPage = () => {
  const [author, setAuthor] = useState({});


  useEffect(() => {
    setAuthor({});
  }, []);

  return (
    <>
      <div className={'author-info-form'}>
        <Link to={"/admin/authors"}>
          <i className="fas fa-arrow-left "  style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <AuthorNewForm author={author} setAuthor={setAuthor}/>
      </div>
    </>

  );
};

export default AuthorNewPage;
