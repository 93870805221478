export const FIRST_NAME_MAX_LEN = 50;
export const FIRST_NAME_MIN_LEN = 1;
export const LAST_NAME_MAX_LEN = 50;
export const LAST_NAME_MIN_LEN = 1;
export const NAME_MAX_LEN = 120;
export const NAME_MIN_LEN = 1;
export const NAME_BANGLA_MAX_LEN = 120;
export const NAME_BANGLA_MIN_LEN = 2;
export const PRODUCT_NAME_MAX_LEN = 50;
export const PRODUCT_NAME_MIN_LEN = 2;
export const PRODUCT_NAME_BANGLA_MAX_LEN = 50;
export const PRODUCT_NAME_BANGLA_MIN_LEN = 2;
export const CATEGORY_NAME_MAX_LEN = 250;
export const CATEGORY_NAME_MIN_LEN = 1;
export const CATEGORY_NAME_BANGLA_MAX_LEN = 250;
export const CATEGORY_NAME_BANGLA_MIN_LEN = 1;
export const PUBLISHER_NAME_MAX_LEN = 50;
export const PUBLISHER_NAME_MIN_LEN = 2;
export const PUBLISHER_NAME_BANGLA_MAX_LEN = 250;
export const PUBLISHER_NAME_BANGLA_MIN_LEN = 1;
export const EMAIL_MAX_LEN = 120;
export const PASSWORD_MAX_LEN = 50;
export const PASSWORD_MIN_LEN = 6;
export const MIN_PRICE = 0;
export const PHONE_NO_MAX_LEN = 14;
export const PHONE_NO_MIN_LEN = 11;


