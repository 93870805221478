import React, {useEffect, useState} from "react";
import "./authorspage.css";
import AuthorPreview from "../../DataDisplay/AuthorPreview";
import {useFetchAuthors} from "../../../Hooks/useFetchAuthors";
import {IconButton, TextField} from "@material-ui/core";
import {SearchOutlined} from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import PageLoader from "../../Loader";


const AuthorsPage = (props) => {
  const PER_PAGE = 21;
  const [response, fetchAuthors] = useFetchAuthors();
  let authors = response?.data?.authors ?? [];
  const isLoading = response?.isLoading;
  const [page, setPage] = useState(1);
  const count = response?.data.total_pages ?? 10;
  const [authorSearchText, setAuthorSearchText] = useState('');
  const [authorsShowType, setAuthorsShowType] = useState(1);
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  let authorGridIcon;
  let authorListIcon;
  if(authorsShowType === 1){
    authorGridIcon = "/icons/authorGridTypeSelected.svg";
    authorListIcon = "/icons/authorListTypeUnselected.svg";
  }else{
    authorGridIcon = "/icons/authorGridTypeUnselected.svg";
    authorListIcon = "/icons/authorListTypeSelected.svg";
  }


  useEffect(() => {
    fetchAuthors(page, PER_PAGE, authorSearchText);
  }, [fetchAuthors, page, authorSearchText]);
  return (
    <>
      <PageLoader
        isLoading={isLoading}
        width={180}
        height={180}
        position={"fixed"}
        top={"50%"}
        left={"50%"}
        padding={"100%"}
      />
      <div className="author-header-and-search-sec">
        <div className="author-title-header">
          লেখক
        </div>
        <div className="authors-search-input">
          <span className="list-show-type-icon">
            <img onClick={() => setAuthorsShowType(1)} src={authorGridIcon} alt=""/>
            <img onClick={() => setAuthorsShowType(0)} src={authorListIcon} alt=""/>
          </span>
          <TextField
            className="authors-search-input-field"
            id="standard-bare"
            variant="outlined"
            placeholder={"খুজুন"}
            value = {authorSearchText}
            onChange={(event) => {
              setAuthorSearchText(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchOutlined style={{margin: 0}} />
                </IconButton>
              ),
            }}
          />
        </div>
      </div>
      <div className="container-fluid"  style={{minHeight: "27vh"}}>
        <div className="row">
          <div className="all-authors">
            {authors.length > 0 ? (
              <>
                {authors?.map(author =>(
                  <AuthorPreview
                    key={author.id}
                    id = {author.id}
                    name={author.author_name}
                    name_bangla={author.author_name_bangla}
                    description = {author.author_description}
                    image_url={author.author_image}
                    born_at = {author.born_at}
                    died_at = {author.died_at}
                    margin={50}
                    authorsShowType={authorsShowType}
                  />
                ))}
              </>
            ) : (<>
              <div className={' text-center h3'}>No Author Found With Search Query '{authorSearchText}'</div>
            </>)}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row author-pagination-row">
          <div className="author-paginator">
            <Pagination  color="primary" count={count} page={page}  onChange={handlePaginationChange}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorsPage;
