import {useCallback, useState} from 'react';
import axios from "axios";
import {urls} from "../Constants/urls";

export const useFetchSearchContent = () => {
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const fetchSearchContent = useCallback((searchText) => {
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = encodeURI(`${urls.searchUrl}/${searchText}`);
      const res = await axios.get(url);
      setResponse(prevState => ({...prevState, data: res.data, isLoading: false}));
    };

    return asyncRequest().catch(error => {
      console.log(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, fetchSearchContent];
};
