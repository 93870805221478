import React, {useEffect, useState} from 'react';
import CategoryEditForm from "./category-edit-form";
import {Link, useParams} from "react-router-dom";
import {useFetchCategoryDetail} from "../../../../../Hooks/Admin/Categories/useFetchCategoryDetail";
import './categoryDetails.css';

const CategoryDetailPage = () => {
  const {categoryId} = useParams();
  const [response, fetchCategoryDetail] = useFetchCategoryDetail();
  const [category, setCategory] = useState({});

  useEffect(() => {
    fetchCategoryDetail(categoryId);
  }, [fetchCategoryDetail]);
  useEffect(() => {
    setCategory({
      id: categoryInfo?.id,
      name: categoryInfo?.category_name,
      name_bangla: categoryInfo?.category_name_bangla,
      parent_id: categoryInfo?.parent_id,
      description: categoryInfo?.description,
      image: categoryInfo?.image,
      show_on_public: categoryInfo?.show_on_public,
      is_enabled: categoryInfo?.is_enabled,
      is_featured: categoryInfo?.is_featured,
    });
  }, [response]);

  return (
    <>
      <div className={'category-info-form'}>
        <Link to={"/admin/categories"}>
          <i className="fas fa-arrow-left "  style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <CategoryEditForm category={category} setCategory={setCategory}/>
      </div>
    </>

  );
};

export default CategoryDetailPage;
