import React, {useEffect, useState} from 'react';
import './publisherDetails.css';
import PublisherNewForm from "./publisher-new-form";
import {Link} from "react-router-dom";

const PublisherNewPage = () => {
  const [publisher, setPublisher] = useState({});


  useEffect(() => {
    setPublisher({});
  }, []);

  return (
    <>
      <div className={'publisher-info-form'}>
        <Link to={"/admin/publishers"}>
          <i className="fas fa-arrow-left "  style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <PublisherNewForm publisher={publisher} setPublisher={setPublisher}/>
      </div>
    </>

  );
};

export default PublisherNewPage;
