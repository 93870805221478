import React, {useEffect, useState} from "react";
import "./publisherspage.css";
import PublisherPreview from "../../DataDisplay/PublisherPreview";
import {useFetchPublishers} from "../../../Hooks/useFetchPublihsers";
import {IconButton, TextField} from "@material-ui/core";
import {SearchOutlined} from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import PageLoader from "../../Loader";


const PublishersPage = (props) => {
  const PER_PAGE = 21;
  const [response, fetchAuthors] = useFetchPublishers();
  const [page, setPage] = useState(1);
  const count = response?.data.total_pages ?? 10;
  let publishers = response?.data?.publishers ?? [];
  const isLoading = response?.isLoading;
  const [publisherSearchText, setPublisherSearchText] = useState('');
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    fetchAuthors(page, PER_PAGE, publisherSearchText);
  }, [fetchAuthors, page, publisherSearchText]);
  return (
    <>
      <PageLoader
        isLoading={isLoading}
        width={180}
        height={180}
        position={"fixed"}
        top={"50%"}
        left={"50%"}
        padding={"100%"}
      />
      <div className="publisher-header-and-search-sec">
        <div className="category-title-header">
          প্রকাশনী
        </div>
        <div className="publishers-search-input">
          <TextField
            className="publishers-search-input-field"
            id="standard-bare"
            variant="outlined"
            placeholder={"খুজুন"}
            value = {publisherSearchText}
            onChange={(event) => {
              setPublisherSearchText(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchOutlined style={{margin: 0}}/>
                </IconButton>
              ),
            }}
          />
        </div>
      </div>
      <div className="container-fluid" style={{minHeight: "27vh"}}>
        <div className="row">
          <div className="all-publishers">
            {publishers.length > 0 ? (
              <>
                {publishers?.map(publisher => (
                  <PublisherPreview
                    key={publisher.id}
                    id={publisher.id}
                    name={publisher.publisher_name}
                    name_bangla={publisher.publisher_name_bangla}
                    description={publisher.publisher_description}
                    image_url={publisher.image}
                    phone_no={publisher.phone_no}
                    email={publisher.email}
                    address={publisher.address}
                  />
                ))}
              </>
            ) : (<>
              <div className={' text-center h3'}>No Publisher Found With Search Query '{publisherSearchText}'</div>
            </>)}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row publisher-pagination-row">
          <div className="publisher-paginator">
            <Pagination  color="primary" count={count} page={page}  onChange={handlePaginationChange}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishersPage;
