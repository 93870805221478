import React, {useEffect, useState} from 'react';
import {Button, TextField} from "@material-ui/core";
import {useFetchPublishersList} from "../../../../Hooks/Admin/Publishers/useFetchPublishersList";
import {Link, useHistory} from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';


const PublishersPage = () => {
  const PER_PAGE = 10;
  const history = useHistory();
  const [data, fetchPublishers] = useFetchPublishersList();
  const [page, setPage] = useState(1);
  const publishers = data?.data.publishers ?? [];
  const count = data?.data.total_pages ?? 10;
  const [publisherSearchText, setPublisherSearchText] = useState('');
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    fetchPublishers(page, PER_PAGE, publisherSearchText);
  }, [fetchPublishers, page, publisherSearchText]);
  return (
    <>

      <div className={'publisher-page container'}>
        <Link to={"/admin/publisher/new"} style={{textDecoration: "none", fontSize: 14}}>
          <AddCircleOutlineIcon style={{margin: "20px 10px 20px 0"}}/>
          <span>Add New Publisher</span>
        </Link>
        <h1>Publishers</h1>
        <TextField
          className={'mb-3'}
          placeholder={'Search Publisher'}
          name={''}
          id={''}
          variant="outlined"
          required
          value={publisherSearchText}
          onChange={(event) => {
            setPublisherSearchText(event.target.value);
          }}
          fullWidth
        />
        <table className={'table'}>
          <thead className={"thead-dark"}>
          <tr>
            <th>Name</th>
            <th>Name Bangla</th>
            <th>Description</th>
            <th>Phone No.</th>
            <th>Email</th>
            <th>Address</th>
            <th></th>
          </tr>
          </thead>
          {publishers.length > 0 ? (
            <>
              {publishers.map((publisher) => {
                return (
                  <tr>
                    <td>{publisher.name}</td>
                    <td>{publisher.name_bangla}</td>
                    <td style={{textAlign: "justify"}}>{publisher.description}</td>
                    <td>{publisher.phone_no}</td>
                    <td>{publisher.email}</td>
                    <td>{publisher.address}</td>
                    <td><Button color={"primary"} variant="contained" onClick={() => {
                      history.push(`/admin/publishers/${publisher.id}`);
                    }}>Detail</Button></td>
                  </tr>
                );
              })}
            </>
          ) : (<>
            <tr>
              <td colSpan={4}>
                <div className={'text-center h3'}>No Product Found With Search Query '{publisherSearchText}'</div>
              </td>
            </tr>
          </>)}
        </table>
        <Pagination count={count} page={page} shape="rounded" onChange={handlePaginationChange}/>
      </div>
    </>
  );
};

export default PublishersPage;