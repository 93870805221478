import React, {useEffect, useState} from 'react';
import ProductEditForm from "./product-edit-form";
import {useParams, Link} from "react-router-dom";
import {useFetchProductDetail} from "../../../../../Hooks/Admin/Products/useFetchProductDetail";
import {useUpdateProductFile} from "../../../../../Hooks/Admin/Products/useUpdateProductFile";
import './productDetails.css';
import {Button} from "@material-ui/core";
import MultiSelectComponent from "../../../../Input/MultiSelectComponent";
import {useUpdateProductAuthors} from "../../../../../Hooks/Admin/Products/useUpdateProductAuthors";
import {useUpdateProductCategories} from "../../../../../Hooks/Admin/Products/useUpdateProductCategories";
import {useFetchMultiSelectAuthorSearchList} from "../../../../../Hooks/Admin/Authors/useFetchMultiselectAuthorSearchList";
import {useFetchMultiSelectCategorySearchList} from "../../../../../Hooks/Admin/Categories/useFetchMultiselectCategorySearchList";


const ProductDetailPage = () => {
  const {productId} = useParams();
  const [response, fetchProductDetail] = useFetchProductDetail();
  const [, uploadFile] = useUpdateProductFile();
  const [product, setProduct] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [, updateProductAuthors] = useUpdateProductAuthors();
  const [, updateProductCategories] = useUpdateProductCategories();
  let productDetail = response?.data?.product;

  useEffect(() => {
    fetchProductDetail(productId);
  }, [fetchProductDetail]);
  useEffect(() => {
    const productInfo = response?.data?.product;
    setProduct({
      id: productInfo?.id,
      name: productInfo?.name,
      name_bangla: productInfo?.name_bangla,
      description: productInfo?.description,
      isbn: productInfo?.isbn,
      publisher_id: productInfo?.publisher_id,
      publishing_year: productInfo?.publishing_year,
      total_pages: productInfo?.total_pages,
      language: productInfo?.language,
      is_featured: productInfo?.is_featured,
      is_disabled: productInfo?.is_disabled,
      regular_price: productInfo?.regular_price,
      discounted_price: productInfo?.discounted_price,
      image: productInfo?.image,
    });
  }, [response]);

  return (
    <>

      <div className={'product-info-form'}>
        <Link to={"/admin/products"}>
          <i className="fas fa-arrow-left "  style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <ProductEditForm product={product} setProduct={setProduct}/>
      </div>
      <div className={'product-authors-form product-info-form '}>
        <MultiSelectComponent contentName={'authors'}
                              selectedData={productDetail?.authors ?? []}
                              selectedDataLabel={'name'}
                              parentId={productDetail?.id}
                              useSearchHook={useFetchMultiSelectAuthorSearchList}
                              onSave={(authors)=>{
                                updateProductAuthors(product.id, authors);
                              }}
        />
      </div>
      <div className={'product-category-form product-info-form '}>
        <MultiSelectComponent contentName={'categories'}
                              selectedData={productDetail?.categories ?? []}
                              selectedDataLabel={'name'}
                              parentId={productDetail?.id}
                              useSearchHook={useFetchMultiSelectCategorySearchList}
                              onSave={(categories)=>{
                                updateProductCategories(product.id, categories);
                              }}
        />
      </div>
      <div className={'product-info-form'}>
        <h3>Update Product File</h3>
        <input name={'file'} type={'file'} className={'form-control'} onChange={
          (e) => {
            setSelectedFile(e.target.files[0]);
          }
        }/>
        <Button className={'mt-3 d-block'}
                variant="contained" onClick={() => {
          let formData = new FormData();
          formData.append('file', selectedFile);
          uploadFile(product, formData);
        }}
                color="primary">Upload File</Button>
      </div>
    </>

  );
};

export default ProductDetailPage;
