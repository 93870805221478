import React from "react";
import "./categorypreview.css";
import BookPreview from "../../DataDisplay/BookPreview";
import { useHistory } from "react-router-dom";
import { Button} from "@material-ui/core";
import Carousel from "react-elastic-carousel";




const CategoryPreview = (props) => {
  let history = useHistory();
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 5 },
    { width: 1200, itemsToShow: 7 },
  ];

  const handleClick = () => {
    history.push(`/category/${props.categories.id}`);
  };
  const seeMore = {
    width: "142px",
    height: "48px",
    margin: "0px 45% 4rem",
    borderRadius: "30px",
    background: "linear-gradient(to right, #166fb7, #1c85df)",
    fontFamily: "SFPro",
    fontSize: "12px",
    fontWeight: "bold",
    textAlign: "center",
    color:" #ffffff"
  };
  return (
    <>
      <div className="category-main-div">
        <div  className="py-3 category-name">{props?.categories?.category_name_bangla}</div>
        <div className="category-books">
          <Carousel breakPoints={breakPoints} pagination = {false} enableAutoPlay={true} autoPlaySpeed={5000} className="category-carousel">
            {props.categories?.products?.map(product =>(
              <BookPreview
                key={product.product_id}
                id = {product.product_id}
                name={product.product_name_bangla}
                author={product.authors.map(author =>(author.author_name_bangla))}
                price={product.discounted_price}
                image_url={product.product_image}
              />
            ))}
          </Carousel>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Button  onClick={handleClick} style={seeMore}>
                আরও দেখুন
              </Button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default CategoryPreview;
