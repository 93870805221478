import React, {useEffect, useState} from 'react';
import {Button, TextField} from "@material-ui/core";
import {useFetchAuthorsList} from "../../../../Hooks/Admin/Authors/useFetchAuthorsList";
import {Link, useHistory} from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const AuthorsPage = () => {
  const PER_PAGE = 10;
  const history = useHistory();
  const [data, fetchAuthors] = useFetchAuthorsList();
  const [page, setPage] = useState(1);
  const authors = data?.data?.authors ?? [];
  const count = data?.data.total_pages ?? 10;
  const [authorSearchText, setAuthorSearchText] = useState('');
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    fetchAuthors(page, PER_PAGE, authorSearchText);
  }, [fetchAuthors, page, authorSearchText]);
  return (
    <>

      <div className={'author-page container'}>
        <Link to={"/admin/author/new"} style={{textDecoration: "none", fontSize: 14}}>
          <AddCircleOutlineIcon style={{margin: "20px 10px 20px 0"}}/>
          <span>Add New Author</span>
        </Link>
        <h1>Authors</h1>
        <TextField
          className={'mb-3'}
          placeholder={'Search Author'}
          name={''}
          id={''}
          variant="outlined"
          required
          value={authorSearchText}
          onChange={(event) => {
            setAuthorSearchText(event.target.value);
          }}
          fullWidth
        />
        <table className={'table'}>
          <thead className={"thead-dark"}>
          <tr>
            <th>Name</th>
            <th>Name Bangla</th>
            <th>Description</th>
            <th>View Detail</th>
          </tr>
          </thead>
          {authors.length > 0 ? (
            <>
              {authors.map((author) => {
                return (
                  <tr>
                    <td>{author.name}</td>
                    <td>{author.name_bangla}</td>
                    <td style={{textAlign: "justify"}}>{author.description}</td>
                    <td><Button color="primary" variant="contained" onClick={() => {
                      history.push(`/admin/authors/${author.id}`);
                    }}>Detail</Button></td>
                  </tr>
                );
              })}
            </>
          ) : (<>
            <tr>
              <td colSpan={4}>
                <div className={'text-center h3'}>No Author Found With Search Query '{authorSearchText}'</div>
              </td>
            </tr>
          </>)}
        </table>
        <Pagination count={count} page={page} shape="rounded" onChange={handlePaginationChange}/>
      </div>
    </>
  );
};

export default AuthorsPage;