import React, {useEffect, useState} from 'react';
import './registration.css';
import {Container, Grid, TextField, Button, Typography, Link} from "@material-ui/core";
import {useFetchRegistrationToken} from "../../../Hooks/useFetchRegistrationToken";
import {toast} from "react-toastify";
import {
  validateMaxLength,
  validateMinLength,
  validatePhoneNo,
  validateRequired,
  validates
} from "../../FormValidator/Validator";
import {PASSWORD_MAX_LEN, PASSWORD_MIN_LEN, PHONE_NO_MAX_LEN, PHONE_NO_MIN_LEN} from "../../../Constants/general";

const RegistrationForm = (props) => {
  const {
    onRegistrationSuccess,
    onRegistrationFailure,
    user,
    setUser
  } = props;
  const mainBody = {height: "auto", width: "auto", margin: "auto", padding: "20px"};
  const textField = {height: "auto",};
  const button = {
    marginTop: '25px',
    height: "50px",
    borderRadius: "30px",
    background: "linear-gradient(to right, #0067b1, #1c85df 96%)"
  };
  const links = {color: "#0067B1"};
  const checkBox = {marginTop: "30px"};
  const [errors, setErrors] = useState({});
  const [, fetchRegistration] = useFetchRegistrationToken();
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChange = (event) => {
    setUser((prevValue) => ({...prevValue, [event.target.name]: event.target.value}));
  };

useEffect(() => {
  setErrors(
    validates(
      validateRequired('first_name'),
      validateRequired('last_name'),
      validateRequired('phone_no'),
      validateRequired('password'),
      validatePhoneNo('phone_no'),
      validateMaxLength('phone_no', PHONE_NO_MAX_LEN),
      validateMinLength('phone_no', PHONE_NO_MIN_LEN),
      validateMaxLength('password', PASSWORD_MAX_LEN),
      validateMinLength('password', PASSWORD_MIN_LEN)
    )(user, {}),
  );
}, [user]);

  const handleSubmit = () => {
   if(user.first_name !== "" && user.last_name !== "" && user.phone_no !== "" && user.password !== "" && confirmPassword !== ""){
     if(user.password === confirmPassword){
       fetchRegistration(user.first_name, user.last_name, user.phone_no, user.password, onRegistrationSuccess, onRegistrationFailure );
     }else{
       toast.error("Password do not match", {
         position: toast.POSITION.BOTTOM_LEFT,
         autoClose: 4000
       });
     }
   }else{
     toast.error("All fields must be filled", {
       position: toast.POSITION.BOTTOM_LEFT,
       autoClose: 4000
     });
   }
  };
  return (
    <>
      <Container style={mainBody} component="main" maxWidth="sm">
          <Grid align="center" style={{paddingBottom: 20}}>
            <h5 className="h5">রেজিস্ট্রেশন করুণ</h5>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                style={textField}
                onChange={handleChange}
                name={'first_name'}
                variant="outlined"
                required
                fullWidth
                label="First Name"
                error={Boolean(errors?.first_name)}
                helperText={(errors?.first_name)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                style={textField}
                onChange={handleChange}
                name={'last_name'}
                variant="outlined"
                required
                fullWidth
                label="Last Name"
                error={Boolean(errors?.last_name)}
                helperText={(errors?.last_name)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={textField}
                onChange={handleChange}
                name={'phone_no'}
                variant="outlined"
                required
                fullWidth
                id="phoneNo"
                label="মোবাইল নাম্বার"
                placeholder="Ex:+8801[346789]********"
                error={Boolean(errors?.phone_no)}
                helperText={(errors?.phone_no)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={textField}
                onChange={handleChange}
                name={"password"}
                type="password"
                variant="outlined"
                required
                fullWidth
                label="পাসওয়ার্ড"
                placeholder="পাসওয়ার্ড দিন"
                error={Boolean(errors?.password)}
                helperText={(errors?.password)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={textField}
                onChange={e => setConfirmPassword(e.target.value)}
                name={"confirm_password"}
                type="password"
                variant="outlined"
                required
                fullWidth
                id="password"
                label="পাসওয়ার্ড নিশ্চিত"
                placeholder="পাসওয়ার্ড নিশ্চিত করুণ"
              />
            </Grid>
          </Grid>
        <div style={checkBox}>
          <input  style={{marginLeft: "10px"}} type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
          <label style={{marginLeft: "10px"}} htmlFor="terms"> <span>আপনি আমাদের  <Link style={links} href="#">নিয়ম ও শর্তসমূহে</Link> সম্মত আছেন</span> </label>
        </div>
          <Button onClick={handleSubmit} style={button} variant="contained" color="secondary" fullWidth>
            <span style={{ color: "#ffffff", fontWeight: "bold"}}>রেজিস্ট্রেশন করুণ</span>
          </Button>
          <Typography style={{marginTop: "20px"}} className="text-center">
            <p>অথবা <Link style={links} href="/login">লগইন</Link> করুণ</p>
          </Typography>
      </Container>
    </>
  );
};

export default RegistrationForm;
