import React, {useEffect, useState} from 'react';
import './productreviews.css';
import {Link, useParams} from "react-router-dom";
import {Button, Switch, TextareaAutosize} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import {useFetchProductReviewsList} from "../../../../Hooks/Admin/ProductReview/useFetchProductReviewsList";
import {useUpdateProductReview} from "../../../../Hooks/Admin/ProductReview/useUpdateProductReviewDetail";
import StarRatingPreview from "../../../DataDisplay/StarRatingPreview";


const ProductReviewsListPage = () => {
  let {productId} = useParams();
  const PER_PAGE = 4;
  const [data, fetchProductReviews] = useFetchProductReviewsList();
  const [page, setPage] = useState(1);
  const productReviews = data?.data?.product_reviews ?? [];
  const count = data?.data.total_pages ?? 10;
  const [productReviewsSearchText, setProductReviewsSearchText] = useState('');
  const [reviewDetail ,updateProductReviewDetail] = useUpdateProductReview();

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    fetchProductReviews(page, PER_PAGE, productReviewsSearchText, productId);
  }, [fetchProductReviews, page, productReviewsSearchText, productId, reviewDetail]);


  return (
    <>
      <div className={'product-review-info'}>
        <Link to={`/admin/products/${productId}`}>
          <i className="fas fa-arrow-left " style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <span className={'h4 ml-5'}>Product Reviews</span>
        {productReviews?.map((productReview) => {
          return (
            <div className="row review-posts">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="reviewer-name-image h5">
                    <img src="/icons/user-icon.svg" alt=""/>
                    <span>{productReview.user_name}</span>
                  </div>
                  <div>
                <span style={{marginRight: 20}}>Rating</span>
                <span>
                <StarRatingPreview
                  size={25}
                  value={productReview?.rating}
                  activeStar={'/icons/rating-star-active.svg'}
                  inactiveStar={'/icons/rating-star-inactive.svg'}
                />
              </span>
                  </div>
                  <div className={'show-on-public'}>
                    <span>Show On Public</span>
                    <Switch
                      checked={productReview?.show_on_public}
                      onChange={(event) => {
                        updateProductReviewDetail(productReview?.id, event.target.checked);
                      }}
                    />
                  </div>
                </div>

              </div>
              <div className="col-12">
                <TextareaAutosize
                  value={productReview?.review_text}
                  className={'form-control'}
                  readOnly
                  style={{width: "100%", height: 100}}
                />
              </div>
            </div>
          );
        })}
        <Pagination count={count} page={page} shape="rounded" onChange={handlePaginationChange}/>
      </div>
    </>

  );
};

export default ProductReviewsListPage;
