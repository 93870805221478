import React, {useEffect, useState} from 'react';
import {
  Button,
  TextField,
  TextareaAutosize,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import {useSaveProductDetail} from "../../../../../Hooks/Admin/Products/useSaveProductDetail";
import {useFetchPublishersList} from "../../../../../Hooks/Admin/Publishers/useFetchPublishersList";
import {
  validateMaxLength, validateMinLength, validateMinVal,
  validatePhoneNo,
  validateRequired,
  validates,
  validateSelectOption
} from "../../../../FormValidator/Validator";
import {
  MIN_PRICE,
  PRODUCT_NAME_BANGLA_MAX_LEN, PRODUCT_NAME_BANGLA_MIN_LEN,
  PRODUCT_NAME_MAX_LEN,
  PRODUCT_NAME_MIN_LEN
} from "../../../../../Constants/general";

const ProductNewForm = (props) => {
  const {product, setProduct} = props;
  const [, saveProduct] = useSaveProductDetail();
  const [data, fetchPublishersList] = useFetchPublishersList();
  const publisherList = data?.data?.publishers;
  const [errors, setErrors] = useState({});
  const inputFieldLabel = {
    margin: "16px 0",
  };

  useEffect(() => {
    fetchPublishersList();
  }, []);

  //////// enable/disable submit button ////////
  let buttonClickable = false;
  if (!product.name?.length > 0 || !product.name_bangla?.length > 0 || product?.product_id > 0  || errors.name?.length > 0 || errors.name_bangla?.length > 0 || errors.publisher_id?.length > 0 || errors.regular_price?.length > 0 || errors.discounted_price?.length > 0) {
    buttonClickable = true;
  }

  const handleChange = (event) => {
    setProduct((prevValue) => ({...prevValue, [event.target.name]: event.target.value}));
  };

  const saveProductDetail = () => {
    saveProduct(product);
  };

  useEffect(() => {
    setErrors(
      validates(
        validateRequired('name'),
        validateRequired('name_bangla'),
        validateRequired('regular_price'),
        validateRequired('discounted_price'),
        validateSelectOption('publisher_id'),
        validatePhoneNo('phone_no'),
        validateMaxLength('name', PRODUCT_NAME_MAX_LEN),
        validateMinLength('name', PRODUCT_NAME_MIN_LEN),
        validateMaxLength('name_bangla', PRODUCT_NAME_BANGLA_MAX_LEN),
        validateMinLength('name_bangla', PRODUCT_NAME_BANGLA_MIN_LEN),
        validateMinVal('regular_price', MIN_PRICE),
        validateMinVal('discounted_price', MIN_PRICE)
      )(product, {}),
    );
  }, [product]);
  return (
    <>
      <h3>Create New Product</h3>
      <div className={'product-form-main-div'}>
        <br/>
        <label style={inputFieldLabel}>Product Name: <span style={{color: "red"}}>*</span></label>
        <TextField
          name={'name'}
          id={'product-name'}
          value={product?.name}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.name)}
          helperText={(errors?.name)}
        />

        <label style={inputFieldLabel}>Product Name Bangla: <span style={{color: "red"}}>*</span> </label>
        <TextField
          name={'name_bangla'}
          id={'product-name-bangla'}
          value={product?.name_bangla}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.name_bangla)}
          helperText={(errors?.name_bangla)}
        />

        <label style={inputFieldLabel}>Description: </label>
        <TextareaAutosize
          style={{height: 100}}
          name={'description'}
          id={'product-isbn'}
          value={product?.description}
          className={'form-control'}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />

        <label style={inputFieldLabel}>Publisher Name: <span style={{color: "red"}}>*</span> </label>
        <TextField
          id="demo-simple-select"
          value={product.publisher_id}
          variant="outlined"
          name={"publisher_id"}
          onChange={handleChange}
          select
          defaultValue={0}
          fullWidth
          error={Boolean(errors?.publisher_id)}
          helperText={(errors?.publisher_id)}
        >
          <MenuItem value={0}>Select Publisher</MenuItem>
          {publisherList?.map(publisher => (
            <MenuItem value={publisher.id}>{publisher.name}</MenuItem>
          ))}
        </TextField>

        <label style={inputFieldLabel}>Total Pages: </label>
        <TextField
          name={'total_pages'}
          id={'product-total-pages'}
          value={product?.total_pages}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />

        <label style={inputFieldLabel}>ISBN: </label>
        <TextField
          name={'isbn'}
          id={'product-isbn'}
          value={product?.isbn}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />

        <label style={inputFieldLabel}>Publishing Year: </label>
        <TextField
          name={'publishing_year'}
          type={'number'}
          id={'product-isbn'}
          value={product?.publishing_year}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />

        <label style={inputFieldLabel}>Regular Price: </label>
        <TextField
          name={'regular_price'}
          type={'number'}
          id={'product-isbn'}
          value={product?.regular_price}
          defaultValue={0}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.regular_price)}
          helperText={(errors?.regular_price)}
        />

        <label style={inputFieldLabel}>Discounted Price: </label>
        <TextField
          name={'discounted_price'}
          type={'number'}
          id={'product-isbn'}
          value={product?.discounted_price}
          defaultValue={0}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          error={Boolean(errors?.discounted_price)}
          helperText={(errors?.discounted_price)}
        />

        <label style={inputFieldLabel}>Image URL: </label>
        <TextField
          name={'image'}
          type={'text'}
          id={'product-isbn'}
          value={product?.image}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
        <br/>
        {JSON.parse(localStorage.getItem("user_type")) === 100 ? (
          <>
            <label style={inputFieldLabel}>Is Featured?: </label>
            <Checkbox
              checked={product?.is_featured}
              onChange={(event) => {
                setProduct((prevValue) => ({...prevValue, is_featured: event.target.checked}));
              }}/>
            <br/>
          </>
        ) : (
          <></>
        )}
        <Button className={'mt-3 d-block'} onClick={saveProductDetail} variant="contained"
                disabled={buttonClickable}
                color="primary">Save</Button>
      </div>
    </>
  );
};

export default ProductNewForm;
