import { useCallback, useState } from 'react';
import  axios  from "axios";
import {toast} from "react-toastify";
import {urls} from "../Constants/urls";
import {useErrorHandler} from "./useErrorHandler";

export const useSaveProductReview = () => {
  const errorHandler = useErrorHandler();
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const fetchProductReview = useCallback((rating, reviewText, productId)=>{
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = urls.productReview;
      const res = await axios.post(url, {rating: rating, review_text: reviewText, product_id: productId}, {params: {token: JSON.parse(localStorage.getItem("token"))}});
      setResponse(prevState => ({...prevState, data: res.data.data, isLoading: false}));
      if(res.data.status === 200){
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
        location.reload();
      }else if(res.data.status === 401){
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }else{
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }
    };

    asyncRequest().catch(error => {
      console.log(error);
      errorHandler(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, fetchProductReview];
};
