import React, {useEffect, useState} from "react";
import "./userprofileupdate.css";
import {Button, TextField} from "@material-ui/core";
import {useFetchUserProfileDetail} from "../../../../Hooks/useFetchUserDetail";
import {useUpdateUserInfo} from "../../../../Hooks/useUpdateUserInfo";


const UserProfileEdit = () => {
  const [res, fetchUser] = useFetchUserProfileDetail();
  const [,updateUser] = useUpdateUserInfo();
  const [user, setUser] = useState({});

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    const userInfo = res?.data?.user;
    setUser({
      id: userInfo?.id,
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      phone_no: userInfo?.phone_no,
      email: userInfo?.email,
    });
  }, [res]);
  const handleChange = (event) => {
    setUser((prevValue) => ({...prevValue, [event.target.name]: event.target.value}));
  };

  const saveUserDetail = () => {
    updateUser(user);
  };

  return (
    <>
      <div className={'user-profile-edit-page-container'}>
        <div className="h4 pb-3 border-bottom">
         Update Information
        </div>
        <div className="pb-3 user-information-edit">
          <span>First Name</span>
          <TextField
            value={user?.first_name}
            name={'first_name'}
            variant="outlined"
            size={'small'}
            onChange={handleChange}
          />
        </div>
        <div className="pb-3 user-information-edit">
          <span>Last Name</span>
          <TextField
            value={user?.last_name}
            name={'last_name'}
            variant="outlined"
            size={'small'}
            onChange={handleChange}
          />
        </div>
        <div className="pb-3 user-information-edit">
          <span>Email</span>
          <TextField
            value={user?.email}
            name={'email'}
            variant="outlined"
            size={'small'}
            onChange={handleChange}
          />
        </div>
        {/*<div className="pb-3 user-information-edit">*/}
        {/*  <span>Image</span>*/}
        {/*  <span>*/}
        {/*    <input style={{maxWidth: 220}} name={'file'} type={'file'} className={'form-control'}/>*/}
        {/*  </span>*/}
        {/*</div>*/}
        <Button onClick={saveUserDetail} variant="contained" color="primary">
          SAVE
        </Button>
      </div>
    </>
  );
};

export default UserProfileEdit;