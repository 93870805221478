import React, {useContext} from "react";
import "./cart.css";
import {useEffect} from "react";
import PageLoader from "../../Loader";
import {CartCountContext} from "../../../Contexts/CartCountContext";
import {useFetchCartContent} from "../../../Hooks/useFetchCartContent";
import {useFetchOrderContent} from "../../../Hooks/useFetchOrderContent";


export const CartPage = () => {
  let cart_product_ids = JSON.parse(localStorage.getItem("cart_products"));
  const [cart_details, fetchCartProductDetails] = useFetchCartContent();
  const cart_product_details = cart_details.data.cart_products;
  const isLoading = cart_details.isLoading;
  const [, setCartProducts] = useContext(CartCountContext);
  const [, fectchGetProduct] = useFetchOrderContent();

  useEffect(() => {
    fetchCartProductDetails(cart_product_ids);
  }, [fetchCartProductDetails]);

  const remove_product = (product_id) => {
    let cart_products = JSON.parse(localStorage.getItem("cart_products"));
    const index = cart_products.indexOf(product_id);
    if (index > -1) {
      cart_products.splice(index, 1);
    }
    setCartProducts(cart_products);
    localStorage.setItem("cart_products", JSON.stringify(cart_products));
    window.location.reload();
  };
  const payNow = () => {
    fectchGetProduct(cart_product_ids);
  };
  return (
    <>
      <PageLoader isLoading={isLoading}/>
      <div className="main-div">
        {/************************BANNER section************************************/}
        <div className="banner">
          <div className=" banner-title ">শপিং কার্ট</div>
        </div>
        {/**************************cart items section******************************/}
        <div className="main-cart-div ">
          <div className="row cart-items-section">
            <div className="col-3 cart-item-sec-title text-center">আইটেমস</div>
            <div className="col-2 cart-item-sec-title">মূল্য</div>
            <div className="col-2 cart-item-sec-title">ছাড়</div>
            <div className="col-2 cart-item-sec-title">মোট টাকা</div>
            <div className="col-3 cart-item-sec-title"></div>
          </div>
          {cart_product_details?.map(product_details =>(
            <div className="row cart-items-section">
              <div className="col-1 cart-item-details text-right ">
                <img className="cart-book-image" src={product_details?.image} alt=""/>
              </div>
              <div className="col-2 cart-item-details text-left ">
                <div className="book-details">{product_details.name_bangla}</div>
                {product_details.authors.map(author =>(
                  <div className="book-details">{author.author_name_bangla}</div>
                ))}
              </div>
              <div className="col-2 cart-item-details cart-item-price">৳ {product_details.price}</div>
              <div className="col-2 cart-item-details cart-item-price text-center">0%</div>
              <div className="col-2 cart-item-details cart-item-price">৳ ২০</div>
              <div className="col-2 cart-item-details cart-delete-button">
                <img onClick={() => {remove_product(product_details.id);}} src="/icons/cart-delete-button.png" alt="Remove"/>
              </div>
            </div>
          ))}
        </div>

        {/********************payment and checkout section***************************/}
        <div className="container-fluid  payment-and-checkout">
          <div className="row">
            <div className="col-6">
              <img className="payment-methods" src="/images/payment-method-images.png" alt=""/>
            </div>
            <div className="col-6 ">
              <div className="payment">
                <div className="payment-header">
                  <span className="">মোট </span>
                  <span >৳ ৮০</span>
                </div>
                <div className="input-phone">
                  <label style={{fontSize: "13px"}} htmlFor="">মোবাইল নাম্বার</label>
                  <input name="phone" placeholder="+8801...." type="text" value=""/>
                </div>
                <div className="promo-apply">
                  <input
                    className="input-promo"
                    placeholder="Promo Code?"
                    type="text"
                    value=""
                  />
                  <button type="button" className="btn-coupon">
                    Apply
                  </button>
                </div>
                <button onClick={payNow} className="btn-payment">
                  <span> চেকআউট করুন</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
