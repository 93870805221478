import React, {useEffect, useState} from 'react';
import {Container, Grid, TextField, Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useGetForgetPasswordOTP} from "../../../Hooks/useGetForgetPasswordOTP";
import {toast} from "react-toastify";
import {useResetPassword} from "../../../Hooks/useResetPassword";

const ForgetPasswordPage = (props) => {
  const history = useHistory();

  const onOTPVerificationSuccess = () => {
    history.push('/');
  };
  const onOTPVerificationFailure = (error) => {

  };
  const mainBody = {height: "auto", width: "auto", margin: "70px auto", padding: "20px"};
  const textField = {height: "1em", marginBottom: "40px",};
  const sendOTP = {
    marginTop: '0px',
    height: "50px",
    borderRadius: "30px",
    background: "linear-gradient(to right, #0067b1, #1c85df 96%)"
  };
  const button = {
    marginTop: '25px',
    height: "50px",
    borderRadius: "30px",
    background: "linear-gradient(to right, #0067b1, #1c85df 96%)"
  };

  const [otpNo, setOtpNumber] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showChangePassDiv, setShowChangePassDiv] = useState(false);
  const [data, fetchGetForgetPasswordOTP] = useGetForgetPasswordOTP();
  const [, fetchResetPassword] = useResetPassword();
  useEffect(() => {
    if (data.data.status) {
      setShowChangePassDiv(true); // Show the div
    }
  }, [data]);
  const handleGetOTP = () => {
    if (phoneNo !== "") {
      fetchGetForgetPasswordOTP(phoneNo);
    } else {
      toast.error("Phone number required", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
    }
    setTimeout(() => {
      setShowChangePassDiv(showChangePassDiv);
    }, 300000);
  };
  const handleSubmitPassword = () => {
    if (phoneNo !== "" && otpNo !== "" && newPassword !== "" && confirmPassword !== "") {
      if (newPassword === confirmPassword) {
        fetchResetPassword(phoneNo, otpNo, newPassword, onOTPVerificationSuccess, onOTPVerificationFailure);
      } else {
        toast.error("Password do not match", {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }
    } else {
      toast.error("All fields must be filled", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
    }
  };
  return (
    <>
      <Container style={mainBody} component="main" maxWidth="sm">
        <Grid align="center" style={{paddingBottom: 20}}>
          <h5>পাসওয়ার্ড পরিবর্তন করতে ইউজার নাম্বার প্রদান করুণ</h5>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              style={textField}
              onChange={e => setPhoneNo(e.target.value)}
              Complete="phoneNo"
              name="phoneNo"
              variant="outlined"
              required
              fullWidth
              id="phoneNo"
              label="Phone No"
            />
          </Grid>
          {!showChangePassDiv && <>
            <Grid item xs={12}>
              <Button onClick={handleGetOTP} style={sendOTP} variant="contained" color="secondary" fullWidth>
                <span style={{color: "#ffffff", fontWeight: "bold", fontSize: "12px"}}>ওটিপি পাঠান</span>
              </Button>
            </Grid>
          </>}
          {showChangePassDiv && <>
            < Grid item xs={12}>
              <TextField
                style={textField}
                onChange={e => setOtpNumber(e.target.value)}
                autoComplete="OTP"
                name="OTP"
                variant="outlined"
                required
                fullWidth
                id="OTP"
                label="OTP"
                placeholder="ওটিপি দিন"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={textField}
                onChange={e => setNewPassword(e.target.value)}
                autoComplete="password"
                name="password"
                type="password"
                variant="outlined"
                required
                fullWidth
                id="password"
                label="পাসওয়ার্ড"
                placeholder="নতুন পাসওয়ার্ড দিন"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={textField}
                onChange={e => setConfirmPassword(e.target.value)}
                autoComplete="password"
                name="password"
                type="password"
                variant="outlined"
                required
                fullWidth
                id="password"
                label="পাসওয়ার্ড নিশ্চিত"
                placeholder="পাসওয়ার্ড নিশ্চিত করুণ"
              />
            </Grid>
            <Button onClick={handleSubmitPassword} style={button} variant="contained" color="secondary" fullWidth>
              <span style={{color: "#ffffff", fontWeight: "bold"}}>পাসওয়ার্ড পরিবর্তন করুন</span>
            </Button>
          </>}
        </Grid>
      </Container>
    </>
  );
};

export default ForgetPasswordPage;
