import { useEffect, useState} from 'react';
import {toast} from "react-toastify";
export const useAddToFav = () => {
  const [favProducts, setFavProducts] = useState(JSON.parse(localStorage.getItem("fav_products")) ?? []);
  const check_if_in_fav = (fav_products, product_id) => {
    let found = false;
    fav_products.forEach((fav_product => {
      if (fav_product === product_id) {
        // break;
        found = true;
      }
    }));
    return found;
  };
  useEffect(()=>{
    localStorage.setItem("fav_products", JSON.stringify(favProducts));
  },[favProducts]);

  const AddToFav = (product_id) => {
    if (check_if_in_fav(favProducts, product_id)) {
      toast.warning("Already in Favourite", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
    } else {
      let prevFav = JSON.parse(localStorage.getItem("fav_products"));
      prevFav.push(product_id);
      setFavProducts(prevFav);
      toast.success("Added to Favourite", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
    }
  };
  const RemoveFromFav = (product_id) => {
    const index = favProducts.indexOf(product_id);
    let prevFav = JSON.parse(localStorage.getItem("fav_products"));
    if (index > -1) {
      prevFav.splice(index, 1);
    }
    setFavProducts(prevFav);
    localStorage.setItem("fav_products", JSON.stringify(prevFav));
    toast.warning("Removed from Favourite", {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 4000
    });
  };
  return [favProducts, AddToFav, RemoveFromFav];
};




