import React, {useState} from 'react';
import RegistrationForm from "../../LoginRegistration/RegistrationForm";
import OTPForm from "../../LoginRegistration/OTPForm";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import UserNewForm from "../Admin/Users/UserDetail/user-new-form";

const RegistrationPage = () => {
  const history = useHistory();
  const [showRegistrationForm, setShowRegistrationForm] = useState(true);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [user, setUser] = useState({});
  const onRegistrationSuccess = () => {
    setShowRegistrationForm(false);
    setShowOTPForm(true);
  };
  const onRegistrationFailure = (error) => {
    //   if(error.response.status === 409){
    //     toast.error("User Already Exist", {
    //       position: toast.POSITION.BOTTOM_LEFT,
    //       autoClose: 3000
    //     });
    //   }
    //   else{
    //
    // }
  };
  const onOTPVerificationSuccess = () => {
    history.push('/');
  };
  const onOTPVerificationFailure = (error) => {

  };

  return (
    <>
      {showRegistrationForm && <RegistrationForm onRegistrationSuccess={onRegistrationSuccess}
                                   onRegistrationFailure={onRegistrationFailure}
                                   user={user}
                                   setUser={setUser}
      />}
      {showOTPForm && <OTPForm phoneNo={user.phone_no}
                               onOTPVerificationSuccess={onOTPVerificationSuccess}
                               onOTPVerificationFailure={onOTPVerificationFailure}/>}
    </>
  );
};

export default RegistrationPage;
