import { useCallback, useState } from 'react';
import  axios  from "axios";
import {urls} from "../../../Constants/urls";

export const useFetchAuthorsList = () => {
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const fetchAuthorsList = useCallback((page, per_page = 10, search='')=>{
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const res = await axios.get(urls.adminAuthorsList(page, per_page, search), {params: {token: JSON.parse(localStorage.getItem("token"))}});
      setResponse(prevState => ({...prevState, data: res.data.data, isLoading: false}));
    };

    asyncRequest().catch(error => {
      console.log(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, fetchAuthorsList];
};
