import { useContext} from 'react';
import {toast} from "react-toastify";
import {CartCountContext} from "../Contexts/CartCountContext";
export const useAddToCart = () => {
  const [, setCartProducts] = useContext(CartCountContext);
  const check_if_in_cart = (cart_products, product_id) => {
    let found = false;
    cart_products.forEach((cart_product => {
      if (cart_product === product_id) {
        // break;
        found = true;
      }
    }));
    return found;
  };

  const AddToCart = (product_id) => {
    let cart_products = JSON.parse(localStorage.getItem("cart_products"));
    cart_products = cart_products ?? [];
    if (check_if_in_cart(cart_products, product_id)) {
      toast.warning("Already in cart", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
    } else {
      cart_products.push(product_id);
      toast.success("Added to Cart", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
    }
    setCartProducts(cart_products);
    localStorage.setItem("cart_products", JSON.stringify(cart_products));
  };
  return [AddToCart];
};




