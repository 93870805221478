import React, {useEffect, useState} from "react";
import "./categoriespage.css";
import BookCategoryPreview from "../../DataDisplay/BookCategoryPreview";
import {useFetchCategories} from "../../../Hooks/useFetchCategories";
import CategoryCarousel from "../../CategoryCarousel";
import { useHistory } from "react-router-dom";
import {  TextField, IconButton } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import Pagination from "@material-ui/lab/Pagination/Pagination";
import PageLoader from "../../Loader";


const CategoriesPage = (props) => {
  const PER_PAGE = 21;
  const [response, fetchCategories] = useFetchCategories();
  let categories = response?.data?.categories ?? [];
  const [page, setPage] = useState(1);
  const count = response?.data.total_pages ?? 10;
  const [categorySearchText, setCategorySearchText] = useState('');
  const isLoading = response?.isLoading;
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handleClickCategory = (clickOption, categoryId) => {
    if(clickOption === 1){
      // history.push(`/category/${categoryId}`);
      const win = window.open(`/category/${categoryId}`, "_blank");
      win.focus();
    }else{

    }
  };
  useEffect(() => {
    fetchCategories(page, PER_PAGE, categorySearchText);
  }, [fetchCategories, page, categorySearchText]);
  return (
    <>
      <PageLoader
        isLoading={isLoading}
        width={180}
        height={180}
        position={"fixed"}
        top={"50%"}
        left={"50%"}
        padding={"100%"}
      />
      <CategoryCarousel clickAction = {handleClickCategory}/>
      <div className="category-header-and-search-sec">
        <div className="category-title-header">
            সকল বিষয়ভিত্তিক বই
        </div>
        <div className="category-search-input">
          <TextField
            className="category-search-input-field"
            id="standard-bare"
            variant="outlined"
            placeholder={"খুজুন"}
            value = {categorySearchText}
            onChange={(event) => {
              setCategorySearchText(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchOutlined style={{margin: 0}} />
                </IconButton>
              ),
            }}
          />
        </div>
      </div>
      <div className="container-fluid" style={{minHeight: "25vh"}}>
        <div className="row">
          <div className="all-categories">
            {categories.length > 0 ? (
              <>
                {categories?.map(category =>(
                  <BookCategoryPreview
                    key={category.category_id}
                    id = {category.category_id}
                    name={category.category_name}
                    name_bangla={category.category_name_bangla}
                    image_url={category.category_image}
                  />
                ))}
              </>
            ) : (<>
                  <div className={'no-search-result text-center h3'}>No Category Found With Search Query '{categorySearchText}'</div>
            </>)}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row category-pagination-row">
          <div className="category-paginator">
            <Pagination  color="primary" count={count} page={page}  onChange={handlePaginationChange}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriesPage;
