import {useCallback, useState} from 'react';
import axios from "axios";
import {urls} from "../../../Constants/urls";
import {toast} from "react-toastify";
import {useErrorHandler} from "../../useErrorHandler";

export const useUpdateProductReview = () => {
  const errorHandler = useErrorHandler();
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const updateProductReviewDetail = useCallback((id, show_on_public) => {
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = urls.productReviewDetail;
      const res = await axios.patch(url, {review_id: id, show_on_public: show_on_public}, {params: {token: JSON.parse(localStorage.getItem("token"))}});
      setResponse(prevState => ({...prevState, data: res.data.data, isLoading: false}));
    };

    asyncRequest().catch(error => {
      console.log(error);
      errorHandler(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, updateProductReviewDetail];
};
