import { useCallback, useState } from 'react';
import  axios  from "axios";
import {urls} from "../Constants/urls";
import {toast} from "react-toastify";

export const useGetForgetPasswordOTP = () => {
  const [response, setResponse] = useState({
    data: [],
    isLoading: false,
    error: null,
  });
  const fetchGetForgetPasswordOTP = useCallback((phone_no)=>{
    setResponse(prevState => ({...prevState, isLoading: true}));
    const asyncRequest = async () => {
      const url = urls.getForgetPasswordOTP;
      const res = await axios.get(url,{params:{phone_no}});
      setResponse(prevState => ({...prevState, data: res.data, isLoading: false}));
      console.log(res);
      if(res.data.status === 200){
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }else{
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }
    };

    asyncRequest().catch(error => {
      console.log(error);
      setResponse(prevState => ({...prevState, isLoading: false}));
    });
  }, [setResponse]);

  return [response, fetchGetForgetPasswordOTP];
};
