import React, {useEffect, useState} from 'react';
import "./publisherdetail.css";
import {useParams} from "react-router-dom";
import BookPreview from "../../../DataDisplay/BookPreview";
import axios from "axios";
import {useFetchPublisherDetail} from "../../../../Hooks/useFetchPublisherDetail";
import PageLoader from "../../../Loader";

const PublisherDetailPage = (props) => {
  let {publisherId} = useParams();
  const [response, fetchPublisherDetail] = useFetchPublisherDetail();
  const publisherDetail = response?.data?.publisher;
  const publisherError = response?.data?.status;
  const isLoading = response?.isLoading;
  useEffect(() => {
    fetchPublisherDetail(publisherId);
  }, []);

  return (
    <>
      <PageLoader
        isLoading={isLoading}
        width={180}
        height={180}
        position={"fixed"}
        top={"50%"}
        left={"50%"}
        padding={"100%"}
      />
      {publisherError === 200 ? <>
        <div className="container-fluid ml-5 mr-5 mt-5">
          <div className="row">
            <div className="col-2 text-right">
              <img className="publisher-image" src={publisherDetail?.image ?? "/images/default-publisher-icon.png"} onError={(e)=>{e.target.onerror = null; e.target.src="/images/default-publisher-icon.png";}} width="200px" height="300px" alt=""/>
            </div>
            <div className="col-8">
              <div className="publisher-name">
                {publisherDetail?.publisher_name_bangla}
              </div>
              <div className="no-of-books-like-button mb-3">
                <div className="no-of-books">
                  <span className="mr-2" style={{fontSize: "12px",fontWeight: 500, color: "#545454"}}>BOOKFLIX</span>
                  <span style={{fontSize: "14px",fontWeight: 500}}>এ বইয়ের সংখ্যা: {publisherDetail?.no_of_books}</span>
                </div>
                <div className="like-logo">
                  <img src="/icons/like-black.svg" alt=""/>
                </div>
              </div>
              <div className="publisher-description">
                {publisherDetail?.description}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid= mt-5">
          <div className="row">
            <div className="col-12">
              <div className="publisher-all-books-header">
                <div style={{fontSize: "24px", fontWeight:"bold", color: "#545454"}}>সকল বই</div>
                <div style={{fontSize: "14px", color: "#000000"}}>বই সংখ্যাঃ {publisherDetail?.no_of_books}</div>
              </div>
            </div>
          </div>
          {publisherDetail?.products?.length > 0 ? (<>
            <div className="row">
              <div className="col-12">
                <div className="all-publisher-books">
                  {publisherDetail?.products?.map(product =>(
                    <BookPreview
                      key={product.id}
                      id = {product.product_id}
                      name={product.product_name_bangla}
                      author={product.authors.map(author =>(author.author_name_bangla))}
                      price={product.discounted_price}
                      image_url={product.product_image}
                      margin = {50}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>) : (
            <>
              <div style={{minHeight: "14vh"}}>
                <h2 className= "text-center ml-5 " >
                  No books available with this publisher
                </h2>
              </div>
            </>
          )}
        </div>
      </> : <>
        <div className="" style={{height: "38vh", marginTop:"6%"}} align="center">
          <div>
            <img src="/images/bookflix-small.png" alt="BookFlix.com"  height={100} width={300} />
            <br/><br/>
            <span style={{color: "#ff8300", fontSize: "30px"}}> Sorry! {response?.data?.message}</span>
          </div>
        </div>
      </>}
    </>
  );
};

export default PublisherDetailPage;
