import React from "react";


const PageNotFound = () => {


  return (
    <>
        <div className="" style={{height: "35vh", marginTop:"6%"}} align="center">
          <div>
            <img src="/images/bookflix-small.png" alt="BookFlix.com"  height={100} width={300} />
            <br/><br/>
            <span style={{color: "#ff8300", fontSize: "30px"}}>APOLOGIES, NO PAGE FOUND WITH THE URL</span>
          </div>
        </div>
      </>
 );
};
 export default PageNotFound;