import React, {useState} from 'react';
import {Container, Grid, TextField, Button} from "@material-ui/core";
import {toast} from "react-toastify";
import {useChangePassword} from "../../../Hooks/useChangePassword";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const mainBody = {height: "auto", width: "auto", marginTop: "40px", padding: "20px"};
  const textField = {height: "1em", marginBottom: "50px",};
  const [,fetchChangePassword] = useChangePassword();
  const button = {
    marginTop: '25px',
    height: "50px",
    borderRadius: "30px",
    background: "linear-gradient(to right, #0067b1, #1c85df 96%)"
  };

  const handleSubmit = () =>{
      if(password === confirmPassword){
        fetchChangePassword(oldPassword, password);
      }else{
        toast.error("Password do not match", {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
      }
  };

  return (
    <>
      <Container style={mainBody} component="main" maxWidth="sm">
        <Grid align="center" style={{marginBottom: 30}}>
          <h5 className="h5">পাসওয়ার্ড পরিবর্তন</h5>
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={textField}
            onChange={e => setOldPassword(e.target.value)}
            autoComplete="password"
            name="old_password"
            type="password"
            variant="outlined"
            required
            fullWidth
            id="password"
            label='পুরনো পাসওয়ার্ড'
            placeholder="'পুরনো পাসওয়ার্ড দিন"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={textField}
            onChange={e => setPassword(e.target.value)}
            autoComplete="password"
            name="password"
            type="password"
            variant="outlined"
            required
            fullWidth
            id="password"
            label="নতুন পাসওয়ার্ড"
            placeholder="নতুন পাসওয়ার্ড দিন"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            style={textField}
            onChange={e => setConfirmPassword(e.target.value)}
            autoComplete="password"
            name="password"
            type="password"
            variant="outlined"
            required
            fullWidth
            id="password"
            label="পাসওয়ার্ড নিশ্চিত"
            placeholder="পাসওয়ার্ড নিশ্চিত করুণ"
          />
        </Grid>
        <Button onClick={handleSubmit}  style={button} variant="contained" color="primary" fullWidth>
          <span style={{ color: "#ffffff", fontWeight: "bold"}}>পাসওয়ার্ড পরিবর্তন করুণ</span>
        </Button>
      </Container>
    </>
  );
};

export default ChangePassword;
