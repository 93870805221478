import React, {useEffect, useState} from 'react';
import './login.css';
import {Container, Grid, TextField, Button, Typography} from "@material-ui/core";
import {toast} from 'react-toastify';
import {useFetchLoginToken} from "../../../Hooks/useFetchLoginToken";
import {Link} from "react-router-dom";
import {
  validateMaxLength,
  validateMinLength,
  validatePhoneNo,
  validateRequired,
  validates
} from "../../FormValidator/Validator";
import {PHONE_NO_MAX_LEN, PHONE_NO_MIN_LEN} from "../../../Constants/general";

const LoginForm = (props) => {
  const {
    onLoginSuccess,
    onLoginFailure,
    user,
    setUser
  } = props;
  toast.configure();
  const mainBody = {height: "auto", width: "auto", margin: "auto", padding: "20px", overflow: "hidden"};
  const textField = {height: "auto"};
  const button = {
    marginTop: '15px',
    height: "50px",
    borderRadius: "30px",
    background: "linear-gradient(to right, #0067b1, #1c85df 96%)"
  };
  const links = {color: "#0067B1"};
  const checkBox = {marginTop: "15px"};
  const [errors, setErrors] = useState({});
  const [, fetchLogin] = useFetchLoginToken();

  const handleChange = (event) => {
    setUser((prevValue) => ({...prevValue, [event.target.name]: event.target.value}));
  };
  useEffect(() => {
    setErrors(
      validates(
        validateRequired('phone_no'),
        validateRequired('password'),
        validatePhoneNo('phone_no'),
        validateMaxLength('phone_no', PHONE_NO_MAX_LEN),
        validateMinLength('phone_no', PHONE_NO_MIN_LEN),
      )(user, {}),
    );
  }, [user]);

  const handleSubmit = async () => {
    if (user.phone_no !== "" && user.password !== "") {
      fetchLogin(user.phone_no, user.password, onLoginSuccess, onLoginFailure);
    } else {
      toast.error("All fields must be filled", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
    }
  };

  return (
    <>
      <Container style={mainBody} component="main" maxWidth="sm">
        <Grid align="center" style={{paddingBottom: 20}}>
          <h5 className="h5">লগইন করুণ</h5>
        </Grid>
        <TextField style={textField}
                   onChange={handleChange}
                   variant="outlined"
                   label='মোবাইল নাম্বার'
                   placeholder="মোবাইল নাম্বার দিন"
                   fullWidth
                   name={'phone_no'}
                   required
                   error={Boolean(errors?.phone_no)}
                   helperText={(errors?.phone_no)}
                   />
        <br/><br/>
        <TextField style={textField} onChange={handleChange}
                   variant="outlined"
                   label='পাসওয়ার্ড'
                   placeholder="পাসওয়ার্ড দিন"
                   name={"password"}
                   type='password'
                   fullWidth
                   required
                   error={Boolean(errors?.password)}
                   helperText={(errors?.password)}
        />
        <div style={checkBox}>
          <input style={{marginLeft: "10px"}} type="checkbox" id="" name=""  value="" checked/>
          <label style={{marginLeft: "10px"}} htmlFor="terms"> <span style={{color: "#545454", fontSize: "14px"}}>আপনি আমাদের  <Link
            style={links} href="#">নিয়ম ও শর্তসমূহে</Link> সম্মত আছেন</span> </label>
        </div>
        <Button onClick={handleSubmit} style={button} variant="contained" color="primary" fullWidth>
          <span style={{color: "#ffffff", fontWeight: "bold"}}>লগইন করুন</span>
        </Button>


        <Typography style={{marginTop: "15px"}} className="text-center typography">

          <p style={{fontSize: 14}}>কোন আকাউন্ট নেই? তাহলে <Link style={links} to="/registration">রেজিস্ট্রেশন</Link> করুন</p>
          <p style={{fontSize: 14}}><Link style={links} to="/forget-password">আকাউন্ট নাম্বার মনে নেই</Link></p>
        </Typography>
      </Container>
    </>
  );
};

export default LoginForm;
