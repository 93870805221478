import React from 'react';
import './search.css';
import { Link} from 'react-router-dom';
import {Scrollbars} from 'react-custom-scrollbars';

//This Component needs to be moved as this is a simple component not a page.
export const SearchPage = (props) => {
  const {SearchResponse} = props;
  const {authors, categories, products, publishers} = SearchResponse?.data?.search_results ?? [];
  const resultTexts = {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    marginRight: "10px"
  };
// console.log(SearchResponse?.data?.search_results?.author?.length);
  const resultLinks = {
    fontSize: "12px",
    color: "#0067b1"
  };
  return (
    <>
      <div className="search-results">
        {authors?.length > 0 || typeof authors === 'undefined' || categories?.length > 0 || typeof categories === 'undefined'|| publishers?.length > 0 || typeof publishers === 'undefined' ? <>
        <Scrollbars style={{minHeight: 200}}>
        {authors?.map(author => (
            <div>
              <Link style={{textDecoration: "none"}}  key={author.id} to={`/author/${author.id}`}>
                <p style={resultTexts}>{author.name} <span style={resultLinks}> In Authors</span></p>
              </Link>
            </div>
          ))}
          {categories?.map(category => (
            <div>
              <Link style={{textDecoration: "none"}}  key={category.id} to={`/category-book-details/${category.id}`}>
                <p style={resultTexts}>{category.name} <span style={resultLinks}> In Categories</span></p>
              </Link>
            </div>
          ))}
          {publishers?.map(publisher => (
            <div>
              <Link style={{textDecoration: "none"}}  key={publisher.id} to={`/publisher/${publisher.id}`}>
                <p style={resultTexts}>{publisher.name} <span style={resultLinks}> In Publishers</span></p>
              </Link>
            </div>
          ))}
          {products?.map(product => (
            <div>
              <Link key={product.id} to={`/book/${product.id}`} style={{textDecoration: 'none' , color: "#333333"}}><p style={resultTexts}> {product.name}</p></Link>
            </div>
          ))}
        </Scrollbars>
        </> : <>
          <div className='d-flex justify-content-center  py-3'>কোনো ফলাফল পাওয়া যায়নি।</div>
        </>}
      </div>
    </>
  );
};
