import React, {useEffect} from "react";
import "./relatedbookpreview.css";
import BookPreview from "../../DataDisplay/BookPreview";
import Carousel from "react-elastic-carousel";
import {useFetchCategoryBookDetails} from "../../../Hooks/useFetchCategoryBookDetails";

const RelatedBookPreview = (props) => {
  const {categoryId} = props;
  const [response, fetchCategoryBookDetails] = useFetchCategoryBookDetails();
  let categories = response?.data ?? [];
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 5 },
    { width: 1200, itemsToShow: 7 },
  ];
  useEffect(() => {
    fetchCategoryBookDetails(categoryId);
  }, [fetchCategoryBookDetails]);

  return (
    <>
      <div className="related-books-main-div">
        <div  className="py-3 related-book-name">এ রকম আরও বই</div>
        <div className="related-books">
          <Carousel breakPoints={breakPoints} pagination = {false} enableAutoPlay={true} autoPlaySpeed={5000} className="related-books-carousel">
            {categories?.products?.map(product =>(
              <BookPreview
                key={product.product_id}
                id = {product.product_id}
                name={product.product_name_bangla}
                author={product.authors.map(author =>(author.author_name_bangla))}
                price={product.discounted_price}
                image_url={product.product_image}
                target={"_blank"}
              />
            ))}
          </Carousel>
        </div>
      </div>

    </>
  );
};

export default RelatedBookPreview;
