import React,{useState} from 'react';
import BootLoader from './Components/Bootstrap';
import { CartCountProvider  } from "./Contexts/CartCountContext";
import { ThemeContextProvider  } from "./Contexts/ThemeContext";


function App() {
    const [cart_products, setCartProducts] = useState(JSON.parse(localStorage.getItem("cart_products")));
    const [theme, setTheme] = useState({headerTheme: 'dark'});

    return (
    <div className="App">
        <ThemeContextProvider value={[theme, setTheme]}>
        <CartCountProvider value={[cart_products, setCartProducts]}>
            <BootLoader/>
        </CartCountProvider>
        </ThemeContextProvider>
    </div>
  );
}

export default App;
