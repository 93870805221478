import React, {useEffect, useState} from 'react';
import {useParams, Link} from "react-router-dom";
import {useFetchProductDetail} from "../../../../../Hooks/Admin/Products/useFetchProductDetail";
import './productverificationdetail.css';
import {Button} from "@material-ui/core";
import EpubReader from "../../../../EpubReader";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {useConfirmProduct} from "../../../../../Hooks/Admin/ProductVerification/useConfirmProduct";
import {toast} from "react-toastify";

const getModalStyle = () => {
  const top = 65;
  const left = 65;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: "70%",
    height: "884px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
}));

const ProductVerificationDetailPage = () => {
  const {productId} = useParams();
  const [response, fetchProductDetail] = useFetchProductDetail();
  const [confirmResponse, confirmProduct] = useConfirmProduct();
  let productDetail = response?.data?.product;
  let responseMessage = confirmResponse.message;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const inputFieldLabel = {
    margin: "16px 20px 10px 0",
    fontWeight: "bold",
    fontSize: "16px"
  };

  const authorCategoryStyleDiv = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    margin: "10px 0"
  };
  const authorCategoryStyle = {
    fontWeight: "bold",
    fontSize: "14px",
    padding: "10px 10px",
    color: "#ffffff",
    borderRadius: "10px",
    marginRight: 10,
    backgroundImage: "linear-gradient(to right, #1670b9, #1b84de)"
  };

  const handleAccept = () =>{
    confirmProduct(productDetail.id);
    if(confirmResponse === 200){
      toast.success(responseMessage, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
    }else{
      toast.success(responseMessage, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 4000
      });
    }
  };

  useEffect(() => {
    fetchProductDetail(productId);
  }, [fetchProductDetail]);
  return (
    <>

      <div className={'product-verification-info-form'}>
        <Link to={"/admin/products-to-confirm"}>
          <i className="fas fa-arrow-left " style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <h3>Product Details</h3>
        <label style={inputFieldLabel}> Product Name </label>
        <input id={'product-name'} value={productDetail?.name} className={'form-control'} readOnly/>

        <label style={inputFieldLabel}> Product Name Bangla</label>
        <input id={'product-name-bangla'} value={productDetail?.name_bangla} className={'form-control'} readOnly/>

        <label style={inputFieldLabel}> Product Cover Image</label>
        <br/>
        <img src={productDetail?.image} height={"auto"} width={"20%"} alt=""/>
        <br/>

        <label style={inputFieldLabel}> Product Authors</label>
        <div style={authorCategoryStyleDiv}>
          {productDetail?.authors.map((author => (
            <span style={authorCategoryStyle}>{author.name_bangla}</span>
          )))}
        </div>

        <label style={inputFieldLabel}> Publisher Name </label>
        <input id={'product-publisher-name'} value={productDetail?.publisher_name_bangla} className={'form-control'} readOnly/>

        <label style={inputFieldLabel}> Product Description</label>
        <textarea id={'product-description'} value={productDetail?.description} className={'form-control'} style={{height: 150}} readOnly/>

        <label style={inputFieldLabel}> Product Categories</label>
        <div style={authorCategoryStyleDiv}>
          {productDetail?.categories.map((category => (
            <span style={authorCategoryStyle}>{category.name_bangla}</span>
          )))}
        </div>

        <label style={inputFieldLabel}> ISBN </label>
        <input id={'product-isbn'} value={productDetail?.isbn} className={'form-control'} readOnly/>

        <label style={inputFieldLabel}> Language </label>
        <input id={'product-language'} value={productDetail?.language} className={'form-control'} readOnly/>

        <label style={inputFieldLabel}> Publishing Year </label>
        <input id={'product-publishing-year'} value={productDetail?.publishing_year} className={'form-control'}
               readOnly/>

        <label style={inputFieldLabel}> Regular Price </label>
        <input id={'product-regular-price'} value={productDetail?.regular_price} className={'form-control'} readOnly/>

        <label style={inputFieldLabel}> Discounted Price </label>
        <input id={'product-discounted-price'} value={productDetail?.discounted_price} className={'form-control'}
               readOnly/>

        <label style={inputFieldLabel}> Total Pages </label>
        <input id={'product-total-page'} value={productDetail?.total_pages} className={'form-control'} readOnly/>

        <label style={inputFieldLabel}> Edition </label>
        <input id={'product-edition'} value={productDetail?.edition} className={'form-control'} readOnly/>
        <br/>
        <label style={inputFieldLabel}> Book File </label>
        <Button variant="contained" color="secondary" onClick={handleOpen}>
          Show Preview
        </Button>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div style={modalStyle} className={classes.paper}>
            <EpubReader epubUrl={'/epub/2.epub'}/>
          </div>
        </Modal>
        <div className={'text-right'}>
          <Button onClick = {handleAccept} variant="contained" color="primary">
            Accept
          </Button>
        </div>
      </div>
    </>

  );
};

export default ProductVerificationDetailPage;
