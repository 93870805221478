import React, {useEffect, useRef, useState} from "react";
import "./appiconcontainer.css";
import {Link} from "react-router-dom";

const AppIconContainer = (props) => {
  const {
    position,
    right,
    top,
    imageURL,
    storeName,
    appStoreUrl
  } = props;

  const appContainerRef = useRef(null);
  const appIconContainerInitial = {
    position: position,
    right: right,
    top: top,
  };
  const appIconContainer = {
    position: position,
    right: "-150px",
    top: top,
  };
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  /////// click event funtion //////
  const handleClickOutside = event => {
    if (appContainerRef.current && !appContainerRef.current.contains(event.target)) {
      setActive(false);
    }
  };
  return (
    <>
      <div className="app-icon-container" style={isActive ? appIconContainerInitial : appIconContainer} ref={appContainerRef}>
        <div onClick={toggleClass}  className="app-logos-sec">
          <img src={imageURL} alt=""/>
        </div>
          <div className="app-store-name">
            <a href={appStoreUrl} target="_blank" style={{textDecoration: "none"}}>
            {storeName}
            </a>
          </div>
      </div>
    </>
  );
};

export default AppIconContainer;
