import React, {useEffect} from "react";
import "./favouritebooks.css";
import BookPreview from "../../DataDisplay/BookPreview";
import {useFetchFavouriteBooks} from "../../../Hooks/useFetchFavouriteBooks";


const FavouriteBooksPage = (props) => {
  let fav_product_ids = JSON.parse(localStorage.getItem("fav_products"));

  const [response, fetchFavouriteBooks] = useFetchFavouriteBooks();
  let favProducts = response?.data.fav_products ?? [];
  useEffect(() => {
    fetchFavouriteBooks(fav_product_ids);
  }, [fetchFavouriteBooks]);

  console.log(favProducts);
  return (
    <>
      <div className="container-fluid favourite-books-details-banner">
        <div className="favourite-banner-title ">আমার প্রিয় তালিকা</div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="all-favourite-books">
            {favProducts?.map(product =>(
              <BookPreview
                key={product.id}
                id = {product.id}
                name={product.name_bangla}
                author={product.authors.map(author =>(author.author_name_bangla))}
                price={product.regular_price}
                image_url={product.image}
                margin={50}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FavouriteBooksPage;
