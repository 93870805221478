import React, {useEffect, useState} from 'react';
import {Button, TextField} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';
import {useFetchProductList} from "../../../../Hooks/Admin/ProductReviewVerification/useFetchProductList";

const ReviewProductListPage = () => {
  const PER_PAGE = 10;
  const history = useHistory();
  const [data, fetchProducts] = useFetchProductList();
  const [page, setPage] = useState(1);
  const products = data?.data.products ?? [];
  const count = data?.data.total_pages ?? 10;
  const [productSearchText, setProductSearchText] = useState('');
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    fetchProducts(page, PER_PAGE, productSearchText);
  }, [fetchProducts, page, productSearchText]);
  return (
    <>
      <div className={'product-verification-page container'}>
        <h1>Product Review verification</h1>
        <TextField
          className={'mb-3'}
          placeholder={'Search Product'}
          name={''}
          id={''}
          variant="outlined"
          required
          value={productSearchText}
          onChange={(event) => {
            setProductSearchText(event.target.value);
          }}
          fullWidth
        />
        <table className={'table'}>
          <thead className={"thead-dark"}>
          <tr>
            <th>Name</th>
            <th>Name Bangla</th>
            <th>Pending Reviews</th>
            <th>Total Reviews</th>
            <th></th>
          </tr>
          </thead>
          {products.length > 0 ? (
            <>
              {products?.map((product) => {
                return (
                  <tr key={product.id}>
                    <td>{product?.name}</td>
                    <td>{product?.name_bangla}</td>
                    <td style={{textAlign: "justify"}}>{product?.pending_reviews}</td>
                    <td style={{textAlign: "justify"}}>{product?.total_reviews}</td>
                    <td><Button color={"primary"} variant="contained" onClick={() => {
                      history.push(`/admin/product-reviews-pending-list/${product.id}`);
                    }}>Detail</Button></td>
                  </tr>
                );
              })}

            </>
          ) : (<>
            <tbody>
            <tr>
              <td colSpan={4}>
                <div className={'text-center h3'}>No Product Found With Search Query '{productSearchText}'</div>
              </td>
            </tr>
            </tbody>
          </>)}
        </table>
        <Pagination count={count} page={page} shape="rounded" onChange={handlePaginationChange}/>
      </div>
    </>
  );
};

export default ReviewProductListPage;
