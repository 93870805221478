import React, {useEffect, useState} from 'react';
import './categoryDetails.css';
import CategoryNewForm from "./category-new-form";
import {Link} from "react-router-dom";

const CategoryNewPage = () => {
  const [category, setCategory] = useState({});

  useEffect(() => {
    setCategory({});
  }, []);

  return (
    <>
      <div className={'category-info-form'}>
        <Link to={"/admin/categories"}>
          <i className="fas fa-arrow-left "  style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <CategoryNewForm category={category} setCategory={setCategory}/>
      </div>
    </>

  );
};

export default CategoryNewPage;
