import React, {useEffect, useState} from 'react';
import AuthorEditForm from "./author-edit-form";
import {Link, useParams} from "react-router-dom";
import {useFetchAuthorDetail} from "../../../../../Hooks/Admin/Authors/useFetchAuthorDetail";
import './authorDetails.css';

const AuthorDetailPage = () => {
  const {authorId} = useParams();
  const [response, fetchAuthorDetail] = useFetchAuthorDetail();
  const [author, setAuthor] = useState({});

  useEffect(() => {
    fetchAuthorDetail(authorId);
  }, [fetchAuthorDetail]);
  useEffect(() => {
    const authorInfo = response?.data?.author;
    setAuthor({
      id: authorInfo?.id,
      name: authorInfo?.author_name,
      name_bangla: authorInfo?.author_name_bangla,
      image: authorInfo?.image,
      description: authorInfo?.description,
      born_at: authorInfo?.born_at,
      died_at: authorInfo?.died_at,
      is_disabled: authorInfo?.is_disabled,
    });
  }, [response]);

  return (
    <>
      <div className={'author-info-form'}>
        <Link to={"/admin/authors"}>
          <i className="fas fa-arrow-left "  style={{margin: "0 0 30px 0", fontSize: "20px", color: "black"}}/>
        </Link>
        <AuthorEditForm author={author} setAuthor={setAuthor}/>
      </div>
    </>

  );
};

export default AuthorDetailPage;
