export const useGetUserInformation = () => {
  const GetUserInformation = () => {
    switch(JSON.parse(localStorage.getItem("user_type"))) {
      case 100:
        var user_type = "admin";
        break;
      case 10:
        var user_type = "moderator";
        break;
      default:
        var user_type = "user";
    }
    const user = {
      first_name: JSON.parse(localStorage.getItem("first_name")),
      last_name: JSON.parse(localStorage.getItem("last_name")),
      user_type: user_type,
    };
    return user;
  };
  return GetUserInformation();

};
