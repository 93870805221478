import React from 'react';
import {BrowserRouter, Link} from 'react-router-dom';
import './footer.css';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';


const Footer = () => {

    const footerLinks = {
      textDecoration: "none",
      color: "#545454",
    };
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };

  return (
    <>
      <BrowserRouter>
        <div className="main-footer-div" style={{overflowX: "hidden"}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-5">
                <div className="address-logo ">
                  <img
                    className="bookflix-logo"
                    src="/images/bookflix-small.png"
                    height=" 53px"
                    width="133px"
                    alt="BookFlix Logo"
                    id="navbar-logo"
                  />
                  <div className="contents">
                    <PhoneIcon style={{margin: 0}}/> <span>16705 (Hotline)</span>
                  </div>

                  <div className="contents">
                    <EmailOutlinedIcon style={{margin: 0}}/> <span>bookflix@belivit.com</span>
                  </div>
                  <div className="contents">
                    <ImportContactsIcon style={{margin: 0}}/> <span>Level: 14, Sattara Center 30/A VIP Road, Naya Paltan, Dhaka.</span>
                  </div>
                </div>
              </div>

              <div className="col-2 text-center">
                <div className="contents-div ">
                  <Link to={'/categories'}  style={footerLinks} >
                    <div className="contents">
                      <span>Category</span>
                    </div>
                  </Link>

                  <Link to={'/authors'} style={footerLinks}>
                    <div className="contents">
                      <span>Writer</span>
                    </div>
                  </Link>

                  <Link to={'/publishers'} style={footerLinks}>
                    <div className="contents">
                      <span>Publisher</span>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-2 text-center">
                <div className="contents-div ">
                  <a href={'/about-us'} target='_blank' style={footerLinks}>
                    <div className="contents">
                      <span>About Us</span>
                    </div>
                  </a>

                  <Link to={'/'} style={footerLinks}>
                    <div className="contents">
                      <span>Contact Us</span>
                    </div>
                  </Link>

                  <Link to={'/'} style={footerLinks}>
                    <div className="contents">
                      <span>Blog</span>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-2 text-center">
                <div className="contents-div ">
                  <a href={'/terms-and-conditions'} target='_blank' style={footerLinks}>
                    <div className="contents">
                      <span>Terms & Conditions</span>
                    </div>
                  </a>
                  <a href={'/privacy-policy'} target='_blank' style={footerLinks}>
                    <div className="contents">
                      <span>Privacy Policy</span>
                    </div>
                  </a>
                </div>
              </div>
              <img onClick={scrollToTop} className="footer-arrow-logo" src="/icons/footer-arrow-button.png" alt=""/>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="social-media-logo d-flex justify-content-center">
                  <Link to="#">
                    <span className="media-logos"><LinkedInIcon style={{margin: 0, fontSize: "40px"}}/></span>
                  </Link>
                  <Link to="#">
                    <span className="media-logos"><TwitterIcon style={{margin: 0, fontSize: "40px"}}/></span>
                  </Link>
                  <Link to="#">
                    <span className="media-logos"><FacebookIcon style={{margin: 0, fontSize: "40px"}}/></span>
                  </Link>
                  <img className="footer-book-image" src="/images/footer-book-image.png" alt=""/>
                </div>
              </div>
            </div>
          </div>
          <div className="copywrite d-flex justify-content-center ">
            <span>Copyright 2021 - All right Recived</span>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
};

export default Footer;