import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route {...rest} render={
      props => {
        if (user?.user_type === 'admin' || user?.user_type === "moderator") {
          return <Component {...rest} {...props} />;
        } else {
          return <Redirect to={
            {
              pathname: '/unauthorized',
              state: {
                from: props.location
              }
            }
          } />;
        }
      }
    } />
  );
};

export default ProtectedRoute;