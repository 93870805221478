import {useCallback} from 'react';
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

export const useErrorHandler = () => {
  const history = useHistory();
  const handleAuthenticationError = useCallback(()=>{
    if(!window.location.pathname.startsWith('/login')) {
      setTimeout(()=>{
        history.push('/login');
      }, 4000);
    }
    toast.error('You are not authenticated', {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 4000
    });
  },[toast]);
  const handleRequestError = useCallback((error)=>{
    const code = error.response.code;
    const message = error.response.data.message ?? 'Sorry, something went wrong.';
    switch (code) {
      case 400:
        toast.error(message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
        break;
      case 403:
        toast.warn(message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
        break;
      case 409:
        toast.error(message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
        break;
      default:
        toast.error(message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 4000
        });
    }
  },[]);
  const getLoginResponse = () => {
    return localStorage.getItem('token') ?? '';
  };
  return useCallback((error, checkLogin = true) => {
    if (checkLogin && getLoginResponse()){
      handleAuthenticationError();
    }
    else if('response' in error){
      handleRequestError(error);
    }
  },[handleAuthenticationError,handleRequestError]);
};
