import React from 'react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./loader.css";

const PageLoader = (props) => {
  const {isLoading, height, width, position,top, left, padding} = props;
  // let isLoading = true;
  let loader;
  if (isLoading) {
    loader = <Loader type="Rings" color="#3387CB" height={height} width={width}/>;
  } else {
    return "";
  }
  return (
    <>
        <div style={{position: position, top: top, left: left, padding: padding}} className="page-loader">
          {loader}
        </div>

    </>
  );
};

export default PageLoader;
