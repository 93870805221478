import React, { useState } from "react";
import { ReactReader } from "react-reader";

const EpubReader = (props) => {
  const {epubUrl} = props;
  const [location, setLocation] = useState(null);
  const locationChanged = (epubcifi) => {
    setLocation(epubcifi);
  };
  return (
    <div style={{ height: "100vh" }}>
      <ReactReader
        location={location}
        locationChanged={locationChanged}
        url={epubUrl}
      />
    </div>
  );
};

export default EpubReader;